import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { NavLink } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Drawer from "@material-ui/core/Drawer";
import Counter from "components/Cart/Counter";
import Slider from "react-slick";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost } from "variables/common.js";

import comparatorStyle from "assets/jss/material-dashboard-react/components/comparatorStyle.jsx";
import "assets/css/slick-theme.css";
import "assets/css/slick.css";


class Comparator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      commonFeaturesToggle: false
    };
  }

  removeItemFromComparator = (event, item) => {
    item.targetPortalItemId = item.id;
    this.props.toggleComparableItem(event, item);
  };

  handleCommonFeaturesToggle = () => {
    this.setState({
      commonFeaturesToggle: !this.state.commonFeaturesToggle
    })
  }

  render() {
    const { i18n, items, classes } = this.props;
    const { } = this.state;

    let features = {};

    items.map((item, i) => {
      item.filters.map(filter => {
        if (features[filter.name] === undefined) {
          features[filter.name] = {};
        }
        if (features[filter.name][item.id] === undefined) {
          features[filter.name][item.id] = [];
        }
        let values = [];
        filter.values.map(value => {
          values.push(value.name + (filter.unit !== undefined ?  " " + filter.unit : ""));
        });
        features[filter.name][item.id].push(values);
      });
    });

    console.log(features);

    return (

      <div className={classes.comparator}>
        <NavLink
          to={'/pricelist'}
          style={{color: "inherit"}}
        >
          <Button
            className={classes.backButton}
            style={{marginTop: "5px"}}
            onClick={this.props.handleComparatorClose}>
            {i18n.t('back')}
          </Button>
        </NavLink>

        {items.length > 0 &&
          <div>
            <Paper className={classes.commonFeaturesToggleWrapper}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.commonFeaturesToggle}
                    onChange={event => this.handleCommonFeaturesToggle()}
                    color="primary"
                  />
                }
                label={i18n.t('showOnlyCommon')}
              />
            </Paper>
            <div className={classes.compareTableWrapper}>
              <Paper className={classes.root}>
                <table className={classes.compareTable}>
                  <tbody>
                    <tr>
                      <td className={classNames(classes.notLastColumn)}></td>
                      {items.map((item, i) => {
                        return(
                          <td className={classNames(i !== items.length - 1 && classes.notLastColumn)}><Button className={classes.removeButton} onClick={event => this.removeItemFromComparator(event, item)}>×</Button></td>
                        )
                      })}
                    </tr>
                    <tr>
                      <td className={classNames(classes.notLastColumn)}></td>
                      {items.map((item, i) => {
                        return(
                          <td className={ classNames(i !== items.length - 1 && classes.notLastColumn, classes.imageCell, classes.cell)}>
                            <Slider> 
                              {item.images.map(image => {
                                return(
                                  <div>
                                    <img style={{margin: "auto"}} src={image + "/resize/100x100"}/>
                                  </div>
                                )
                              })}
                            </Slider>
                          </td>
                        )
                      })}
                    </tr>
                    <tr>
                      <td className={classNames(classes.notLastColumn)}></td>
                      {items.map((item, i) => {
                        return(
                          <td className={ classNames(i !== items.length - 1 && classes.notLastColumn, classes.modelCell, classes.cell)}>{item.typePrefix + " " + item.vendor.name + " " + item.model}</td>
                        )
                      })}
                    </tr>
                    
                    {(localStorage.getItem('showPrices') == null || localStorage.getItem('showPrices') == 'true') &&
                    <tr>
                      <td className={classNames(classes.notLastColumn, classes.featureNameCell, classes.priceCell, classes.cell)}></td>
                      {items.map((item, i) => {
                        return(
                          <td className={ classNames(i !== items.length - 1 && classes.notLastColumn, classes.priceCell, classes.cell)}>
                          	{item.splr.price ? (item.splr.currencyEnum === 'BYN' ? item.splr.price + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: item.splr.currencyEnum}).format(item.splr.price)) : ""}
                          </td>
                        )
                      })}
                    </tr>
                    }
                    { 
                      Object.keys(features).map((featureName, j) => {
                        if (this.state.commonFeaturesToggle && Object.keys(features[featureName]).length !== items.length) {
                          return;
                        }
                        return(
                          <tr>
                            <td className={classNames(classes.notLastColumn, classes.notLastRow, classes.cell, classes.featureNameCell)}>{ featureName }</td>
                            {items.map((item, i) => {
                              console.log(features[featureName])
                              console.log(item.id)
                              return(
                                <td className={classNames(i !== items.length - 1 && classes.notLastColumn, classes.notLastRow, classes.cell)}>{features[featureName][item.id] !== undefined && features[featureName][item.id].join(", ")}</td>
                              )
                            })}
                          </tr>
                        )
                      })
                    }
                    <tr>
                      <td className={classNames(classes.notLastColumn)}></td>
                      {items.map((item, i) => {
                        if (item.splr.price === null || item.splr.price === undefined || item.splr.price === 0 || item.splr.status === 0 || item.splr.quantity === 0) {
                          return;
                        }
                        return(
                          <td className={classNames(i !== items.length - 1 && classes.notLastColumn, classes.cartCell)}>
                            <Counter
                              className={classes.lbCounter}
                              productQuantity={this.props.productQuantity}
                              maxQuantity={item.splr.quantity}
                              updateQuantity={this.props.updateQuantity}
                              resetQuantity={this.props.resetQuantity}
                              initQuantity={1}
                              id={item.splr.id}
                            />
                            <Button
                              className={classes.buyButton}
                              onClick={event => this.props.addToCart(item.splr)}>
                              {i18n.t('buy')}
                            </Button>
                          </td>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              </Paper>
            </div>
          </div>
        }
      </div>
    );
  }
}

Comparator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(comparatorStyle)(Comparator);