import React, {Component, useState, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Mail from "@material-ui/icons/Mail";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
import Modal from '@material-ui/core/Modal';
import Cancel from "@material-ui/icons/Cancel";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// core components
import articlesStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import { doAjax, doAjaxPost, getSettingValue, getSettingValueAsBoolean } from "variables/common.js";
import * as constants from "variables/constants.jsx";

import JoditEditor from "jodit-react";
  const styles = theme => ({
  
  editLightbox: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  
  });


	function getEditModalStyle() {
	  const vertical = 5;
	  const horizontal = 20;
	
	  return {
	    top: `${vertical}%`,
	//    bottom: `${vertical}%`,
	    left: `${horizontal}%`,
	    flexGrow: 1,
	    maxHeight: "80%",
	    minHeight: "60%",
    position: "relative"
	  };
	}
	
	function getSendModalStyle() {
	  const vertical = 5;
	  const horizontal = 20;
	
	  return {
	    top: `${vertical}%`,
	    left: `${horizontal}%`,
	    flexGrow: 1,
	    maxHeight: "60%",
	    minHeight: "50%",
    position: "relative"
	  };
	}
		
function Editor(props) {
  const [content, setContent] = useState(props.value != "" ? props.value : "" );

  const config = {
    readonly: false,        
    uploader: {
          "insertImageAsBase64URI": true
    },
    removeButtons: ['source', 'font', 'about', 'find', 'fullsize', 'print'],
    height: "400",
    toolbarAdaptive: false,
    
  };

  const handleSetContext = value => {
    setContent(value);
    props.setValue(value);
  };

  return useMemo(
    () => (
      <JoditEditor
        value={content}
        config={config}
        tabIndex={1}
        onBlur={handleSetContext}
        onChange={handleSetContext}
      />
    ),
    []
  );
}	 
 
class EditArticleLB extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      saveSuccess: false,
      emptyText: false,
      item: null,
      editorState: "",
    };
  };
  
  handleOpen = item => {
  	this.state.open = true;
  	this.state.item = item;
  	this.setState({ state: this.state });
  	this.setState({ editorState: item && item.text ? item.text : "" });

  }

  save = () => {

    var text = this.state.editorState;

    if (text === "") {
      if (text === "") {
        this.setFieldState("emptyText");
      }
      return;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/article/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = (this.state.item ? "&supplierArticleId=" + this.state.item.id : "") + "&supplierId=" + supplierUser.supplierId + 
    				"&principalId=" + user.id + "&text=" + this.state.editorState.replace(/\+/g, "%2B").replace(/&/g, "%26") + "&type=";
       body += this.state.item != null ? this.state.item.type : this.props.type;
     
    		
    var that = this;
    var cb = function(data) {
      if (data) {
        that.setFieldState("saveSuccess");
        that.handleClose();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  componentDidMount = () => {
    var user = JSON.parse(localStorage.getItem('user')).value;
  }

  handleClose = event => {
    this.setState({ open: false });
    this.setState({ item: null }); 
    this.props.onClose();
  }

  removeCard = () => {
    this.setState({ cardsInputOpen: false }); 
    this.props.removeCard();
  }

  setCard = id => {
    this.setState({ cardsInputOpen: false }); 
    this.props.setCard(id);
  }
	
  render() {
    const { i18n, classes, open, card, isAdmin, isManager } = this.props;
    const modalStyle = getEditModalStyle();

     
    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox} tabIndex={'null'}>

            <Typography variant="h6" id="tableTitle">
              { !this.state.item ? i18n.t('adding') : i18n.t('edition')}
              {" "}
              { (this.state.item != null ? this.state.item.type : this.props.type) == 1 ? i18n.t('ofNew') : "" }
              { (this.state.item != null ? this.state.item.type : this.props.type) == 2 ? i18n.t('ofAction') : "" }
            </Typography>
            <IconButton 
              onClick={event => this.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            <div style={{margin: "auto"}}>
               <Editor
                setValue={(value) => {this.setState({ editorState: value })}}
                value={this.state.editorState}
               />
                
                <GridContainer style={{position: "absolute", bottom: "25px"}}>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{marginTop: "25px"}}>
                      <CustomButton 
                        color="primary"
                        onClick={this.save}
                      >
                        {this.state.item === null ? i18n.t('add') : i18n.t('save')}
                      </CustomButton>
                      {this.state.saveSuccess && <Check style={{color: "green", marginLeft: "25px", verticalAlign: "middle"}} />}
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

class SendArticleLB extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      saveSuccess: false,
	  sendSuccess: false,
      emptyText: false,
	  editLBOpen: false,
      editLBItem: null,
      card: null,
      item: null,
      editorState: "",
    };
  };
  
  handleOpen = item => {
  	this.state.open = true;
  	this.state.item = item;
  	this.setState({ state: this.state });
  	this.setState({ editorState: item && item.text ? item.text : "" });

  }

  send = (event) => {

    var subject = document.getElementById('send-lb-subject').value;

    if (subject === "") {
      if (subject === "") {
        this.setFieldState("emptySubject");
      }
      return;
    }
    
    if (!window.confirm("Отправить письмо ВСЕМ пользователям?")) {
    	return;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/article/send?key=' + constants.apiKey + '&upd=' + new Date().getTime();
     
	var body = (this.state.item !== null ? "&supplierArticleId=" + this.state.item.id : "") + "&supplierId="  + supplierUser.supplierId + "&principalId=" + user.id + "&subject=" + document.getElementById('send-lb-subject').value;
	
    var that = this;
    var cb = function(data) {
      if (data) {
        that.setFieldState("sendSuccess");
        that.handleClose();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);

  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  componentDidMount = () => {
    var user = JSON.parse(localStorage.getItem('user')).value;
  }

  handleClose = event => {
    this.setState({ open: false });
    this.setState({ item: null }); 
    this.props.onClose();
  }

  removeCard = () => {
    this.setState({ cardsInputOpen: false }); 
    this.props.removeCard();
  }

  setCard = id => {
    this.setState({ cardsInputOpen: false }); 
    this.props.setCard(id);
  }
	
  render() {
    const { i18n, classes, item, open, card, isAdmin, isManager, isSent } = this.props;
    const modalStyle = getSendModalStyle();

     
    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox} tabIndex={'null'}>

            <Typography variant="h6" id="tableTitle">
              {i18n.t('sending')}
            </Typography>
            <IconButton 
              onClick={event => this.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            <div style={{margin: "auto"}}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={i18n.t('subject') + '*'}
                      id="send-lb-subject"
                      formControlProps={{
                        fullWidth: true
                      }}
					  			                    error={this.state.emptySubject}
                    />
                  </GridItem>
                </GridContainer>
                
                <GridContainer style={{position: "absolute", bottom: "25px"}}>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{marginTop: "25px"}}>
                      <CustomButton 
                        color="primary"
						                        id="send-btn"
                        onClick={this.send}
						                        disabled={this.state.item && this.state.item.isSent == 1}
                      >
                        {i18n.t('send')}
                      </CustomButton>
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const editArticleLBStyles = theme => ({
	editLightbox: {
	    position: 'absolute',
	    width: "60%",
	    backgroundColor: theme.palette.background.paper,
	    boxShadow: theme.shadows[5],
	    padding: theme.spacing.unit * 4,
	    outline: 'none',
    },
    cancelIconWrapper: {
	    right: "10px",
	    top: "10px",
	    position: "absolute",
	  },
	  cancelIcon: {
	    width: "15px",
	    height: "15px",
	    fontSize: "15px"
	  },
})

const sendArticleLBStyles = theme => ({
	editLightbox: {
	    position: 'absolute',
	    width: "60%",
	    backgroundColor: theme.palette.background.paper,
	    boxShadow: theme.shadows[5],
	    padding: theme.spacing.unit * 4,
	    outline: 'none',
    },
    cancelIconWrapper: {
	    right: "10px",
	    top: "10px",
	    position: "absolute",
	  },
	  cancelIcon: {
	    width: "15px",
	    height: "15px",
	    fontSize: "15px"
	  },
})

const EditArticleLightbox = withStyles(editArticleLBStyles)(EditArticleLB);
const SendArticleLightbox = withStyles(sendArticleLBStyles)(SendArticleLB);

export { EditArticleLightbox };
export { SendArticleLightbox };

class Articles extends React.Component {
  state = {
    checked: this.props.checkedIndexes,
    editLBOpen: false,
    editLBItem: null,
  };
  
  
  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };

  handleDeleteClick = item => {
  
    const conf = window.confirm('Ð£Ð´Ð°Ð»Ð¸Ñ‚ÑŒ Ð·Ð°Ð¿Ð¸Ñ�ÑŒ?'); //{i18n.t('deleteUser?')}
    if (!conf) {
    	return;
    }
    
	    var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/article/delete?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierId=" + supplierUser.supplierId + "&principalId=" + user.id + "&supplierArticleId=" + item.id + "&type=" + item.type + "&isSent=" + supplierUser.isSent;

    var that = this;
    var cb = function(data) {
      if (data) {
        that.props.onDelete();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);    
    
  }
  
  render() {
    const { classes, articles, rtlActive, isAdmin, isManager, isSent, i18n, editClick, sendClick, handleIsSend, type } = this.props;
    const tableCellClasses = classnames(classes.tableCell, {
      [classes.tableCellRTL]: rtlActive
    })
    
    var colsCount = 1;
	  switch (type) {
        case 1:
             var colsCount = getSettingValue("B2B_NEWS_COLS_COUNT", "1");
             break;
        case 2:
             var colsCount = getSettingValue("B2B_ACTIONS_COLS_COUNT", "2")
             break;
      }

    return (
      <div className={classes.table} id="Articletable">
        <div style={{columnCount: colsCount}}>
          {articles && articles.map(article => (
	           <div key={article.id} className={classes.tableRow} style={{display: "flex", pageBreakInside: "avoid", breakInside: "avoid"}}> 
              <div style={{display: "flex",width: "100%", justifyContent: "space-between"}}>
              <div className={tableCellClasses} style={{flexGrow: "20"}}>
              	<Typography className={classes.title} color="textSecondary" style={{color: "#ccc"}} gutterBottom>
              		<Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">{article.dateAdd}</Moment>
              	</Typography>
              	
              	<Typography variant="body" component="p">
                {article.text.split("\n").map(function(item) {
				  return (
				    <><div dangerouslySetInnerHTML={{ __html: item }} />
				    </>
				    )
				})}
				</Typography>
				
              </div>
              {isAdmin && 
              <div className={classes.tableActions}>
				<Tooltip
                  id="tooltip-top"
                  title={i18n.t('send')}
                >
                  <IconButton
                    aria-label="Send"
                    className={classes.tableActionButton}
                    onClick={event => sendClick(article)}
					                    disabled={article.isSent == 1}
                  >
                    <Mail
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
					                      style={{
	                      			color: article.isSent == 1 ? "gray" : " "
	      					                }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title={i18n.t('edit')}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={event => editClick(article)}
                  >
                    <Edit
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id="tooltip-top-start"
                  title={i18n.t('remove')}
                >
                  <IconButton
                    aria-label="Close"
                    className={classes.tableActionButton}
                    onClick={event => this.handleDeleteClick(article)}
                  >
                    <Close
                      className={
                        classes.tableActionButtonIcon + " " + classes.close
                      }
                    />
                  </IconButton>
                </Tooltip>
              </div>
              }
              </div>
           </div> 
           
          ))}
        </div>
      </div>
    );
  }
}

Articles.propTypes = {
  classes: PropTypes.object.isRequired,
  articlesIndexes: PropTypes.arrayOf(PropTypes.number),
  articles: PropTypes.arrayOf(PropTypes.node),
  rtlActive: PropTypes.bool
};

export default withStyles(articlesStyle)(Articles);

