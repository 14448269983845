import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import * as moment from 'moment';
import Moment from "react-moment";

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Drawer from "@material-ui/core/Drawer";
import Counter from "components/Cart/Counter";
import Modal from '@material-ui/core/Modal';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';

import { NavLink } from "react-router-dom";

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost, getSettingValue, getSettingValueAsBoolean } from "variables/common.js";
import { lighten } from '@material-ui/core/styles/colorManipulator';

import cartStyle from "assets/jss/material-dashboard-react/components/cartStyle.jsx";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getModalStyle() {
  const vertical = 42;
  const horizontal = 40;

  return {
    top: `${vertical}%`,
    left: `${horizontal}%`,
    right: `${horizontal}%`,
    bottom: `${vertical}%`,
    overflow: "auto",
  };
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes, onSelectAllClick, order, orderBy,  numSelected, rowCount, i18n, items} = this.props;

    const rows = [
      { id: 'article', numeric: false, disablePadding: true, center: false, label: i18n.t('article') },
      { id: 'producer', numeric: false, disablePadding: true, center: false, label: i18n.t('producer') },
      { id: 'model', numeric: false, disablePadding: true, center: false, label: i18n.t('model'), className:classes.modelHeader},
      { id: 'availabilityDate', numeric: true, disablePadding: true, label: i18n.t('date'), className:classes.dateHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) },
      { id: 'quantity', numeric: true, disablePadding: true, center: false, label: i18n.t('warehouseQuantity') },
      { id: 'multiplicity', numeric: true, disablePadding: true, center: false, label: i18n.t('multiplicity'), className:classes.multiplicityHeader, hidden: !(items.some(i => i.multiplicity)) },
      { id: 'price', numeric: true, disablePadding: false, center: false, label: i18n.t('priceWithVAT') },
      { id: 'cartQuantity', numeric: true, disablePadding: true, center: false, label: i18n.t('cartQuantity'), className:classes.cartQuantityHeader },
      { id: 'total', numeric: true, disablePadding: true, center: false, label: i18n.t('total') },
      { id: 'remove', numeric: false, disablePadding: true, center: false, label: '' },
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.checkBoxCell}>
            <Checkbox
              className={classes.checkBox}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              (row.hidden === undefined || !row.hidden ? [
              <TableCell
                key={row.id}
                align={row.center ? 'center' : row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classNames(classes.headerCell, row.className)}
              >
                {row.label !== '' ?
                <Tooltip
                  title={i18n.t('sort')}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                </Tooltip>
                : null}
              </TableCell>
              ] : ""
              )
            ),
            this,
          )}
          <TableCell 
            className={classes.cartCell}
            key='cart'
            align='left'
            padding='none'>
            {null}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { i18n, numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} { i18n.t('selected') }
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {i18n.t('cart')}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};


EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class Cart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      order: props.order,
      orderBy: props.orderBy,
      selected: [],
      selectedProduct: {},
      openLB: false,
      clientAddresses: null,
      currentAddressId: null,
      buttonDisabled: true,
      time: ''
    };
  }
  
  componentDidMount = () => {
  
//  	this.props.items.map(n => {
//  		this.state.selected.push(n.id);
//  	});
	
	this.setState(state => ({ selected: (this.props.items ? this.props.items.map(n => n.id) : []) }));
    this.getClientAddresses();
    this.getDelayTime();
  }

  componentWillUnmount() {
    clearInterval(this.Timer);
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.props.items.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  checkProduct = id => {
    return this.props.items.some(function(item) {
      return item.id === id;
    });
  }

  getSelectedPrice() {
    var sum = 0;
    this.state.selected.map(n => {
      if (this.checkProduct(n)) {
        var index = this.props.items.findIndex(x => x.id == n);
        sum += this.props.items[index].price * this.props.items[index].cartQuantity;
      }
    });
    return sum;
  }

  handleCheckoutButton = () => {

    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/cart/checkout?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    var orderItems = [];
    var itemNonMultiple = this.props.items.find(i => i.multiplicity && i.cartQuantity % i.multiplicity !== 0);
    
    this.props.items.map(item => {
      if (this.state.selected.indexOf(item.id) !== -1) {
        orderItems.push({id: item.id, itemId: item.targetPortalItemId, userId: user.id, supplierUserId: (localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.id), 
        				 supplierClientId: (localStorage.getItem('supplierUser') === null ? null : JSON.parse(localStorage.getItem('supplierUser')).value.supplierClientId), quantity: Number(item.cartQuantity), 
        				 price: item.price, supplierId: this.props.supplierId, availabilityDate: item.availabilityDate ? moment(item.availabilityDate, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YY HH:mm') : "", 
        				 multiplicity: item.multiplicity ? item.multiplicity : "", warehousesText: item.warehousesText ? item.warehousesText : "", warehouses: item.warehouses ? item.warehouses : "",
        				 cartDateAdd: item.cartDateAdd ? moment(item.cartDateAdd).format('DD.MM.YYYY HH:mm:ss') : ""});
      }
    });
    
    var body = {};
    
    body['orderItems'] = orderItems;
    var comment = document.getElementById('order-comment').value;
    if (comment) {
    	body['comment'] = comment;
    }
    
    var shippingDate = document.getElementById('shipping-date').value;
    if (shippingDate) {
    	body['shippingDate'] = moment(shippingDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
    }
    
    if (this.state.currentAddressId) {
    	body['supplierClientAddressId'] = this.state.currentAddressId;
    }

    var that = this;

    var cb = function(data) { 
      if (data.id) {
        that.setState({ openLB: true });
        that.props.resetCart();
      }
    };
    
    var errorCB = function(err) {
    //	alert(err);
    	if (err.indexOf('изменилась цена с ') > 0) {
    		var externalId = err.substring(err.indexOf('[') + 1, err.indexOf(']'));
    		that.props.items.map(item => {
		      if (item.externalId == externalId) {
		      	// alert(externalId);
		      	var newPriceInd = err.indexOf(' на ') + 4;
		      	var newPrice = err.substring(newPriceInd, err.indexOf('. ', newPriceInd));
		      	item.price = parseFloat(newPrice);
		      	// break;
		      }
		    });
		    that.setState({ state: that.state });
    	}
    	if (err.indexOf('изменилась дата поставки с ') > 0) {
    		var externalId = err.substring(err.indexOf('[') + 1, err.indexOf(']'));
    		that.props.items.map(item => {
		      if (item.externalId == externalId) {
		      	// alert(externalId);
		      	var newAvailabilityDateInd = err.indexOf(' на ') + 4;
		      	var newAvailabilityDate = err.substring(newAvailabilityDateInd, err.indexOf('. ', newAvailabilityDateInd));
		      	item.availabilityDate = moment(newAvailabilityDate, 'DD.MM.YY HH:mm');
		      	// break;
		      }
		    });
		    that.setState({ state: that.state });
    	}
    }
   
    if (itemNonMultiple) {
        this.props.handleShowSnackbar('Количество '+ itemNonMultiple.model +' должно быть кратно ' + itemNonMultiple.multiplicity, 'error');
        return;
    }
     
    this.props.handleProgressBarOpen();
    
    try {
	    doAjaxPost(url, "json=" + encodeURIComponent(JSON.stringify(body)), cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, errorCB) ; 
    } catch (ex) {
    	console.error(ex);
    }
  }

  getClientAddresses() {

  	var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
	// if (this.props.item) 
	if (supplierUser.supplierClientId) {
	  	var url = constants.apiBaseUrl + '/b2b/supplier/client/addresses?key=' + constants.apiKey + '&upd=' + new Date().getTime() +
                 '&supplierClientId='  + supplierUser.supplierClientId; 

	    var that = this;
	
	    var cb = function(data) {
	      that.setState({ clientAddresses: data});
	    };
	    this.props.handleProgressBarOpen();
	    
	    doAjax(url, cb, this.props.handleProgressBarClose);
	} 
  }

  getDelayTime() {
	 
    var user = JSON.parse(localStorage.getItem('user')).value;

    var that = this;
    var cb = function(data) {
    
    	if (data.length == 0) {
    		that.setState({buttonDisabled : false});
    		return;
    	}

	    var dateAdd = new Date(moment(data[data.length-1].dateAdd, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'))
	
	    if (getSettingValue("NEW_ORDER_DELAY_MIN") && new Date().getTime() - dateAdd.getTime() < getSettingValue("NEW_ORDER_DELAY_MIN") * 60000) {
	       
	        var time = getSettingValue("NEW_ORDER_DELAY_MIN") * 60000 - (new Date().getTime() - dateAdd.getTime()) ;
	        that.setState({time: Math.ceil(time/60000)})
	 
	        var timerCounter = setInterval(() => {
		           that.setState({time: that.state.time - 1})
	        }, 60000);
	       
	        this.Timer = setTimeout(() => { 
	           clearInterval(timerCounter);
	           that.setState({buttonDisabled : false}); }, time);
	
	    } else {
		     that.setState({buttonDisabled : false});
	    }
    };

	if (getSettingValue("NEW_ORDER_DELAY_MIN") > 0) {
	
		var url = constants.apiBaseUrl + '/b2b/supplier/orders?key=' + constants.apiKey + "&principalId=" + user.id + 
						'&dateFrom=' + moment(new Date(new Date().getTime() - getSettingValue("NEW_ORDER_DELAY_MIN") * 60000)).format('YYYY.MM.DD HH:mm:ss') + '&upd=' + new Date().getTime();
		
	    this.props.handleProgressBarOpen();
	    doAjax(url, cb, this.props.handleProgressBarClose);
	} else {
		that.setState({buttonDisabled : false});
	}
  }
  handleInputSelected = (event) => {
    this.setState({ currentAddressId: event.target.value });
  }
  render() {
    const { i18n, items, totalAmount, classes } = this.props;
    const { selected, order, orderBy, openLB } = this.state;
    return (
      <div className={classes.cartTable}>
        <Modal open={this.state.openLB}>
          <div style={getModalStyle()} className={classes.lightbox}>
            <div style={{verticalAlign: "middle"}}>
              <p className={classes.checkoutSuccessLabel}>{i18n.t('checkoutSuccess')}</p>
              <NavLink
                to={'/orders'}
                style={{color: "inherit"}}
              >
                <Button className={classes.returnButton}>
                  {i18n.t('openOrders')}
                </Button>
              </NavLink> 
            </div>
          </div>
        </Modal>

        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.summaryWrapper)
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <table className={classes.summaryTable}>
            <tr className={classes.summaryTableHeaderRow} cellpadding="2">
              <td>{i18n.t('allItems')}</td>
            </tr>
            <tr className={classes.summaryTableRow}>
              <td align="left">{i18n.t('priceWithVAT')}</td>
              <td align="right">{items && items.length > 0 && (items[0].currencyEnum === 'BYN' ? Math.round(totalAmount * 100) / 100 + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: items[0].currencyEnum, maximumFractionDigits: 2 }).format(totalAmount))}</td>
            </tr>
            <tr className={classes.summaryTableHeaderRow} cellpadding="2">
              <td>{i18n.t('selectedItems')}</td>
            </tr>
            <tr className={classes.summaryTableRow}>
              <td align="left">{i18n.t('priceWithVAT')}</td>
              <td align="right">{items && items.length > 0 && (items[0].currencyEnum === 'BYN' ? Math.round(this.getSelectedPrice() * 100) / 100 + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: items[0].currencyEnum, maximumFractionDigits: 2 }).format(this.getSelectedPrice()))}</td>
            </tr>
          </table>

          <Button
            className={classes.checkoutButton}
            onClick={this.handleCheckoutButton}
            disabled={selected.length === 0 && getSettingValue("NEW_ORDER_DELAY_MIN") > 0 || this.state.buttonDisabled}>
            {i18n.t('checkout')}
            {this.state.buttonDisabled && getSettingValue("NEW_ORDER_DELAY_MIN") > 0 && this.state.time > 0 ? ' через '+ this.state.time + ' мин.' : '' }
          </Button>
        </Drawer>
        <NavLink
          to={'/pricelist'}
          style={{color: "inherit"}}
        >
          <Button
            style={{marginTop: "5px"}}
            onClick={this.props.handleCheckoutClose}>
            {i18n.t('back')}
          </Button>
          </NavLink>

        {items && items.length > 0 &&
          <Paper className={classes.root}>
            <EnhancedTableToolbar numSelected={selected.length} i18n={i18n} handleFilterClick={this.handleFilterClick}/>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={items.length}
                    i18n={i18n}
                    classes={classes}
                    items={items}
                  />
                  <TableBody>
                    {items
                      .map(n => {
                        const isSelected = this.isSelected(n.id);
                        return (
                          <TableRow
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                            className={classes.tableRow}
                            selected={isSelected}
                          >
                            <TableCell align="center" className={classNames(classes.checkBoxCell, classes.smallCell)}>
                              <Checkbox 
                                checked={isSelected} 
                                className={classes.checkBox}
                                onClick={event => this.handleClick(event, n.id)}
                              />
                            </TableCell>
                            <TableCell 
                              component="th" 
                              scope="row" 
                              padding="none"
                            >
                              { n.externalId }
                            </TableCell>
                            <TableCell 
                              component="th" 
                              scope="row" 
                              padding="none"
                            >
                              { n.producer }
                            </TableCell>
                            <TableCell 
                              component="th" 
                              scope="row" 
                              padding="none"
                              style={{ paddingLeft: '10px'}}
                            >
                              { n.model }
                            </TableCell>


							{getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) &&
                               <TableCell
                                   align="right"
                                   padding="none"
                                   className={ classNames(classes.dateCell) }
                                   style={{whiteSpace: 'nowrap'}}
                               >
                               { n.availabilityDate &&
                                     <Moment parse="DD.MM.YYYY HH:mm:ss" format={getSettingValue("PRICELIST_DATE_FORMAT", "DD.MM.YY")}>
                                      {n.availabilityDate}
                                     </Moment>
                                }
                               </TableCell>
                             }


                            <TableCell 
                              component="th" 
                              scope="row" 
                              padding="none"
                              align="right"
                              className={ classNames(getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) ? classes.smallCell : null) }
                            >
                              { (n.quantity && n.quantity.replace(/[ \u00A0]/, '') > 10 ? "> 10" : n.quantity) }
                            </TableCell>

                            {this.props.items.some(i => i.multiplicity) && <TableCell 
                              component="th" 
                              scope="row" 
                              padding="none"
                              align="right"
                              className={classes.smallCells}
                            >
                              { n.multiplicity }
                            </TableCell>}

                            <TableCell 
                              component="th" 
                              scope="row"
                              align="right"
                              className={classes.smallCell}
                            >
                              { n.price }
                            </TableCell>
                           
                            <TableCell 
                              component="th" 
                              scope="row" 
                              padding="none"
                              align="right"
                              className={classes.smallCell}
                              style={{whiteSpace: "nowrap"}} 
                            > 
                              <Counter
                                productQuantity={n.cartQuantity}
                                maxQuantity={n.quantity}
                                updateQuantity={this.props.updateQuantity}
                                updateItemQuantity={this.props.updateItemQuantity}
                                initQuantity={n.cartQuantity}
                                id={n.id}
                                isCart={true}
                              />
                            </TableCell>
                            <TableCell 
                              component="th" 
                              padding="none"
                              scope="row"
                              align="right"
                              className={classes.smallCell}
                              style={{whiteSpace: "nowrap"}}
                            >
                              {n.currencyEnum === 'BYN' ? Math.round(n.cartQuantity * n.price * 100) / 100 + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: n.currencyEnum, maximumFractionDigits: 2 }).format(n.cartQuantity * n.price)}
                            </TableCell>
                            <TableCell 
                              component="th" 
                              scope="row"
                              padding="none"
                              align="center"
                              className={classes.smallCell}
                            >
                              <Button className={classes.removeButton} onClick={event => this.props.removeProduct(n.id, event)}>×</Button>
                            </TableCell>
                    
                          </TableRow>
                        );
                      })}
                      <TableRow>
                      </TableRow>

                  </TableBody>
                </Table>
                
              </div>
			  <div style={{marginTop: "-40px", paddingBottom: "40px", paddingLeft: "22px", paddingRight: "22px"}}>
			  		<div style={{display: "flex", width: "100%"}}>
                	<CustomInput 
			                      labelText={i18n.t('comment')}
			                      id="order-comment"
			                      formControlProps={{
			                        fullWidth: true
			                      }}
			                      inputProps={{
			                        multiline: true,
			                        style: {color: "rgb(84, 84, 84)"}
			                      }}
			                    />
                    <TextField style={{marginTop: "27px", marginLeft: "20px"}}
                               id="shipping-date"
                               label={i18n.t('shippingDate')}
                               type="date"
                               className={classes.textField}
                               InputLabelProps={{
                                 shrink: true,
                               }}

                    />
                    </div>
                    <div style={{display: "flex", width: "100%"}}>
                    {this.state.clientAddresses && this.state.clientAddresses.length > 0 && <FormControl  style={{width: "100%"}}>                
                      <InputLabel shrink htmlFor="address" >
                        {i18n.t('address')}
                      </InputLabel>
                      <Select
                        onChange={event => this.handleInputSelected(event)}
                        value={this.state.currentAddressId}
                        input={ <Input 
                                  id="address" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                />}
                      >
                      	<option  value=''></option>
                        { this.state.clientAddresses !== null && this.state.clientAddresses !== undefined &&
                          this.state.clientAddresses.map(address => {
                            {if(address.isActive==true) {return(
                              <option disabled={(address.minOrderAmount >= totalAmount ? "disabled" : "")} value={address.id} style={{cursor: "default"}}>
                                {address.type == 1 ? "ПВЗ" : "Доставка"}{" - "} 
                                {address.city ? "г. " + address.city.name + ", " : ""}
                                {address.address}
                                {address.minOrderAmount > 0 ? ", от " + address.minOrderAmount + " р." : ""}
                              </option>
                            )}}
                          })
                        }
                      </Select>
                  </FormControl>}
                  </div>
			  </div>

            </Paper>
          }
      </div>
    );
  }
}

Cart.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(cartStyle)(Cart);