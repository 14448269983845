import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from '@material-ui/icons/Search';
import SvgIcon from '@material-ui/core/SvgIcon';

import { fade } from '@material-ui/core/styles/colorManipulator';
import Fade from '@material-ui/core/Fade';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Add from '@material-ui/icons/Add';
import Create from '@material-ui/icons/Create';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Close from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import CustomButton from "components/CustomButtons/Button.jsx";
import Check from "@material-ui/icons/Check";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TablePagination from '@material-ui/core/TablePagination';

import Cancel from "@material-ui/icons/Cancel";
import GetApp from '@material-ui/icons/GetApp';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import avatar from "assets/img/faces/marc.jpg";

import Moment from 'react-moment';
import CustomInput from "components/CustomInput/CustomInput.jsx";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FilterSidebar from "components/Sidebar/FilterSidebar.jsx";
import FilterListIcon from '@material-ui/icons/FilterList';

import Downshift from 'downshift';

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost, getSettingValue, getSettingValueAsBoolean, addParameter, removeParameter, getParams, checkboxToggle } from "variables/common.js";

const styles = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  lightbox: {
    position: 'absolute',
    width: "90%",
//    maxWidth: "1300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  cancelIconWrapper: {
    right: "10px",
    top: "10px",
    position: "absolute",
  },
  cancelIcon: {
    width: "15px",
    height: "15px",
    fontSize: "15px"
  },
  modalTable: {
    marginTop: "40px",
    
  },
  idButton: {
    backgroundColor: "transparent",
    padding: 0,
    minWidth: 0,
    font: "600 13.3333px Arial",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline"
      
    }
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
   /* minWidth: 1020, */
  },
  tableWrapper: {
    "& > table > tbody": {
      overflow: "auto",
      maxHeight: "60vh"
    },
    "& > table td": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table td:nth-child(1)": { 
      paddingLeft: "24px",	
    },
    "& > table th": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table th:nth-child(1)": { 
      paddingLeft: "24px",	
    },
    "& > table td:last-child": { 
      paddingRight: "15px",
      maxWidth: "80px", 
    },
    maxHeight: "calc(100vh - 60px - 64px - 56px - 74px)",
    overflow: "auto",
  },
  textInputWrapper: {
    margin: 0,
    paddingBottom: 3,
    width: "80%",
    
  },
  textInput: {
  	fontSize: "13px"
  },
  editLightbox: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  cancelIconWrapper: {
    right: "10px",
    top: "10px",
    position: "absolute",
  },
  cancelIcon: {
    width: "15px",
    height: "15px",
    fontSize: "15px"
  },  
  editCell: {
    minWidth: "30px",
    width: "30px",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    whiteSpace: "nowrap",
    textAlign: "center"
  },
  editIcon: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "21px",
    color: "#848484",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },    
  editButton: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "1px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  hoverUnderline: {
	"&:hover": {
		textDecoration: "underline"
    }
  },
  rightCell: {
    minWidth: "10px",
    width: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",
    whiteSpace: "nowrap"  
  },
 addressCellSwitch: {
    marginLeft: "-25px",
    paddingRight:"0px !important",
    width:"30px" 
  },
 addressCell: {
	   width:"100px", 
	   paddingRight:"20px", 
	   paddingLeft:"10px"
},
 addressCellInputSmall: {
	   width:"100px", 
	   paddingRight:"10px", 
	   paddingLeft:"5px",
	   "&:before": {
		      borderBottom: "none"
}
},
 addressCellInputLarge: {
	   width:"100%", 
	   paddingRight:"10px", 
	   paddingLeft:"5px",
	   "&:before": {
		     borderBottom: "none"
    }
}

});

function getModalStyle() {
  const vertical = 5;
  const horizontal = 5;

  return {
    top: `${vertical}%`,
    left: `${horizontal}%`,
//    bottom: `${vertical}%`,
    overflow: "auto",
    maxHeight: "80%",
    minHeight: "inherit"
  };
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, sortDirection, sortBy, numSelected, rowCount, i18n, classes, isAdmin, isManager, isBlocked } = this.props;

    const rows = [
      { id: 'id', numeric: false, disablePadding: false, label: i18n.t('clientId'), sortable: false },
      { id: 'name', numeric: false, disablePadding: false, label: i18n.t('clientName'), sortable: true },
      { id: 'unp', numeric: true, disablePadding: false, label: i18n.t('unp'), sortable: true },
      { id: 'clientGroup', numeric: false, disablePadding: false, label: i18n.t('clientGroup'), sortable: true, hidden: !getSettingValueAsBoolean('CLIENTS_SHOW_GROUP', false) },
      { id: 'manager', numeric: false, disablePadding: false, label: i18n.t('manager'), sortable: true, hidden: (isAdmin ? false : true) },
      { id: 'ordersCount', numeric: true, disablePadding: false, label: i18n.t('ordersCount'), sortable: true },
      { id: 'usersCount', numeric: true, disablePadding: false, label: i18n.t('usersCount'), sortable: true },
      { id: 'isBlocked', numeric: true, disablePadding: false, label: i18n.t('isBlocked'), sortable: true },
      { id: 'lastActionDate', numeric: true, disablePadding: false, label: i18n.t('lastLoginDate'), sortable: true },
      { id: 'dateAdd', numeric: true, disablePadding: true, label: i18n.t('dateAdd'), sortable: true },
    ];
    

    return (
      <TableHead>
        <TableRow >
          {isAdmin &&
		  <TableCell 
            className={classes.checkboxCell} style={{padding: "0"}} align="center">
			<Checkbox 
			className={classes.checkbox}
			indeterminate={numSelected > 0 && numSelected < rowCount}
			onClick={onSelectAllClick}
			/>
          </TableCell>
          }
          {rows.map(
            row => (
              (row.hidden === undefined || !row.hidden ? [
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={sortBy === row.id || (row.field && sortBy === row.field) ? sortDirection : false}
                className={classes.headerCell}
              >
                {row.label !== '' ?

                	[
                	row.sortable !== false ? [
	                <Tooltip
	                  title={i18n.t('sort')}
	                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
	                  enterDelay={300}
	                >
                    <TableSortLabel
                      active={sortBy === row.id || (row.field && sortBy === row.field)}
                      direction={sortDirection}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                    </Tooltip>
                    ]
                    : row.label
                    
                	]
                	
                	
                : null}
              </TableCell>
              ] : ""
              )
            ),
            this,
          )}
          {(isAdmin || isManager) && 
          <>
          <TableCell 
            className={classes.editCell}
            key='discounts'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.editCell}
            key='delivery'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.editCell}
            key='remove'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.editCell}
            key='edit'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.editCell}
            key='delete'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          </>
          }
          <TableCell 
	          className={classes.rightCell}
	          padding='none'>
	          {null}
	        </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired,
  clientBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "table",
    minWidth: "250px",
    textAlign: "right"
  },
});

let EnhancedTableToolbar = props => {
  const { i18n, numSelected, classes, isAdmin, isManager, isBlock } = props;

  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {i18n.t('clients')}
        </Typography>
      </div>

          <div className={classes.spacer} />

		  <Fade in={props.showSearchField}>
			  <div elevation={8}>
			      {props.showSearchField && <TextField 
			      id="standard-search" 
			      points="0,100 50,00, 100,100" 
			      className={"searchField"} 
			      placeholder={i18n.t('search')} 
			      onChange={props.handleChangeSearch} 
			      type="search" 
			      />}
			  </div>
		  </Fade>

      	  <div className={classes.actions} style={{width: "480px"}}>

          <Tooltip title={i18n.t('search')}>
					<IconButton id="search" style={{ display: "inline-block" }} aria-label="Search" href="#" onClick={props.handleSearchClick}>
						<SearchIcon />
					</IconButton>
		  </Tooltip>
		  
		   { (true) && 
          	<Tooltip title={ i18n.t('download') }>
              <IconButton id="download-clients" style={{display: "inline-block"}} aria-label="Download" href="#">
                <GetApp />
              </IconButton>
            </Tooltip>
            }
		  

      	  	{ (isAdmin || isManager) && 
              <Tooltip title={ i18n.t('add') }>
                <IconButton style={{display: "inline-block"}} aria-label="Add" onClick={event => props.addClick(null)}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
            
            
            { (isAdmin) && 
		        <IconButton aria-label="Delete" title={ i18n.t('appointManager') }>
					  <SvgIcon style={{display: "inline-block"}} aria-label="Appoint manager" onClick={event => props.addManClick(null)}>
					    <path d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z" />
					    <circle cx="9" cy="13" r="1"></circle>
					    <circle cx="15" cy="13" r="1"></circle>
					    <path d="M18 11.03C17.52 8.18 15.04 6 12.05 6c-3.03 0-6.29 2.51-6.03 6.45 2.47-1.01 4.33-3.21 4.86-5.89 1.31 2.63 4 4.44 7.12 4.47z" />
					  </SvgIcon>
				</IconButton>
            }
            
            
            { (isAdmin || isManager) && 
	            <Tooltip title={i18n.t('discount')}>
             <IconButton id="discount" style={{ display: "inline-block", fontWeight: "500" }} color="inherit"
	                               aria-label="Discount" href="#" onClick={event => props.handleEditDiscounts()}>
              %
             </IconButton>
	            </Tooltip>
            }
            { (isAdmin || isManager) && 
	            <Tooltip title={i18n.t('exceptions')}>
				 <IconButton id="exceptions" style={{ display: "inline-block" }} aria-label="Exceptions" href="#" onClick={event => props.handleEditExceptions()}>
					<RemoveCircleOutline />
	             </IconButton>
	            </Tooltip>
            }
            
            <Tooltip title={ i18n.t('filters') }>
              <IconButton style={{display: "inline-block"}} aria-label="Filter list" onClick={props.handleFilterClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            
      	  </div>
      
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function getEditModalStyle() {
  const vertical = 5;
  const horizontal = 20;

  return {
    top: `${vertical}%`,
//    bottom: `${vertical}%`,
    left: `${horizontal}%`,
    flexGrow: 1,
    maxHeight: "80%",
    minHeight: "50%"
  };
}
class DiscountsLightbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		        categorySuggestions: [],
		        vendorSuggestions: [],
		        clientSuggestions: [],
		        selectedClient: null,
        discount:"",           
    }
  };
  componentDidMount = () => {
  }
  handleInputSelected = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
  render() {
    const { i18n, classes, item, open, isAdmin, sortDirection, sortBy, clientDiscounts, client, filteredClientDiscounts, isManager } = this.props;
    const modalStyle = getEditModalStyle();

  const handleInputChange = (event, clientDiscount) => {
     if (event.target.type == "checkbox") {
    clientDiscount.isActive = (event.target.checked ? 1 : 0);}

	    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/rule/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierClientRuleId=" + clientDiscount.id +
               "&principalId=" + user.id +
               "&isActive=" + clientDiscount.isActive + 
               "&discountPercent=" + (event.target.name =='discount' ? event.target.value : (clientDiscount.discountPercent ? clientDiscount.discountPercent : "")) ;

    var that = this;
    var cb = function(data) {
      if (data) {
        that.setState({ state: this.state });
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar); 
  };
  
  const handleDeleteDiscountClick = (clientDiscount) => {
  
    if (!window.confirm("Удалить скидку?")) {
    	return;
    }
  
    	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/rule/delete?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierClientRuleId=" + clientDiscount.id + "&principalId=" + user.id;

    var that = this;
    var cb = function(data) {
      if (data) {
	    var ind = clientDiscounts.indexOf(clientDiscount);
	    if (ind >= 0) {
		    clientDiscounts.splice(ind, 1);
	    }
        
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    
    cb(true);
  
  }
  
  const handleAddDiscountClick = (event) => {
  
  	var category = document.getElementById('category-options-input').value;
  	var vendor = document.getElementById('vendor-options-input').value;
    var discount = document.getElementById('discount-options-input').value;
    var clientName = document.getElementById('client-options-input') ? document.getElementById('client-options-input').value : "";
    
    if (!client && (clientName == null || clientName == 0)) {
	   alert('Пожалуйста, заполните поле "Контрагент"');
  	   return;
    } 
    if ((category == null || category == 0) && (vendor == null || vendor == 0)) {
  		alert("Пожалуйста, заполните категорию или бренд");
  		return;
  	}
  	if (!discount) {
  		alert("Пожалуйста, заполните скидку");
  		return;
  	}
	
	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/rule/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&principalId=" + (user ? user.id : '') + "&supplierId=" + localStorage.getItem('supplierId') + "&supplierClientId=" + (client ? client.id : this.state.selectedClient.id) + "&type=2&isActive=1&category=" + (category != null ? category : '') +
    				 "&vendor=" + (vendor != null ? vendor : '') + "&discountPercent=" + (discount != null ? discount : '');

    var that = this;
    var cb = function(data) {
      if (data) {
       	clientDiscounts.unshift(data);
        that.setState({ state: this.state });
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    document.getElementById('discount-options-input').value = ""; 

    this.setState({discount:null});

  }
	function renderSuggestion(suggestionProps) {
	  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
	  const isHighlighted = highlightedIndex === index;
	  const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

	  return (
	    <MenuItem
	      {...itemProps}
	      key={suggestion.id}
	      selected={isHighlighted}
	      component="div"
	      style={{
	        fontWeight: isSelected ? 500 : 400,
	        fontSize: "14px"
	      }}
	    >
	     <span style={{marginLeft: "0px"}}>{suggestion}</span>
	    </MenuItem>
	  );
	}
    const handleClientChange = event => {
	  
    var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/client/search?key=' + constants.apiKey + "&principalId=" + user.id + "&supplierId=" + localStorage.getItem('supplierId') + '&upd=' + new Date().getTime() + "&s=" + document.getElementById('client-options-input').value;

    var that = this;
    var cb = function(data) {
      that.setState({ clientSuggestions: data.map(item => item = item.name), selectedClient: data[0] }); 
    }
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
   }

   const handleCategoryChange = event => {
	  
	var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/category/search?key=' + constants.apiKey + "&principalId=" + user.id + "&supplierId=" + localStorage.getItem('supplierId') + '&upd=' + new Date().getTime() + "&s=" + document.getElementById('category-options-input').value;

    var that = this;
    var cb = function(data) {
      that.setState({ categorySuggestions: data }); 
    }
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
   }
   
   const handleVendorChange = event => {
	  
	var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/vendor/search?key=' + constants.apiKey + "&principalId=" + user.id + "&supplierId=" + localStorage.getItem('supplierId') + '&upd=' + new Date().getTime() + "&s=" + document.getElementById('vendor-options-input').value;

    var that = this;
    var cb = function(data) {
      that.setState({ vendorSuggestions: data }); 
    }
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
   }
   
    var selectedClients = filteredClientDiscounts != null   ? filteredClientDiscounts : clientDiscounts;

	function getSuggestions(value, { showEmpty = false } = {}, suggestions) {
	  const inputValue = value.trim().toLowerCase();
	  const inputLength = inputValue.length;
	  let count = 0;

	  return inputLength === 0 && !showEmpty
	    ? []
	    : suggestions;
	}
  
	function renderInput(inputProps) {
	  const { InputProps, classes, ref, ...other } = inputProps;
	
	  return (
	    <TextField
	      InputProps={{
	        inputRef: ref,
	        classes: {
	          root: classes.inputRoot,
	          input: classes.inputInput,
	        },
	        ...InputProps,
	      }}
	      {...other}
	    />
	  );
	}

    return (
      <div>
        <Modal
          open={open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox}>
            <Typography variant="h6" id="tableTitle">
              { i18n.t('discounts') + (client ? ' - ' + client.name : '') }
            </Typography>
            <IconButton 
              onClick={event => {this.props.handleClose(event);
                                 if (document.getElementById('discount-options-input')) { document.getElementById('discount-options-input').value = ""; }
                                 this.setState({discount:null});  }}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            
            <div style={{marginTop: "25px"}}>
            	{ (isAdmin) ? [
            		 <>
                	 {!client && <Downshift id="client-options" style={{width: "250px"}} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
                          if (selection !== null) {
                            
                          }
                          this.state.clientSuggestions = [];
                        }}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                if (event.target.value === '') {
                                  clearSelection();
                                } else {
                                  handleClientChange(event);
                                }
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "200px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  label: i18n.t('contractor'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                })}

                                <div {...getMenuProps()}>
                                  {isOpen ? (
                                    <Paper className={classes.paper} square style={{maxHeight: "200px", overflow: "auto", position: "absolute", zIndex: "100", width: "300px"}}>
                                      {getSuggestions(inputValue, { showEmpty: false }, this.state.clientSuggestions).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }}
                </Downshift>}
            	    <Downshift id="category-options" style={{width: "250px"}} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
                          if (selection !== null) {
                            
                          }
                          this.state.categorySuggestions = [];
                        }}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                if (event.target.value === '') {
                                  clearSelection();
                                } else {
                                  handleCategoryChange(event);
                                }
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "200px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  label: i18n.t('category'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                })}

                                <div {...getMenuProps()}>
                                  {isOpen ? (
                                    <Paper className={classes.paper} square style={{maxHeight: "200px", overflow: "auto", position: "absolute", zIndex: "100", width: "300px"}}>
                                      {getSuggestions(inputValue, { showEmpty: false }, this.state.categorySuggestions).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }}
                </Downshift>
                <Downshift id="vendor-options" style={{width: "250px"}} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
                          if (selection !== null) {
                            
                          }
                          this.state.vendorSuggestions = [];
                        }}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                if (event.target.value === '') {
                                  clearSelection();
                                } else {
                                  handleVendorChange(event);
                                }
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "200px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  label: i18n.t('vendor'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                })}

                                <div {...getMenuProps()}>
                                  {isOpen ? (
                                    <Paper className={classes.paper} square style={{maxHeight: "200px", overflow: "auto", position: "absolute", zIndex: "100", width: "280px"}}>
                                      {getSuggestions(inputValue, { showEmpty: false }, this.state.vendorSuggestions).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }}
                </Downshift>
            	<Downshift id="discount-options" initialIsOpen={true} initialInputValue={""}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                 this.handleInputSelected(event);
                              },

                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "120px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  id:"discount-options-input",
                                  classes,
                                  name:"discount",
                                  label: i18n.t('discount'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                  inputProps:{defaultValue: this.state.discount !== null ? this.state.discount : ""},
                                })}

                              </div>
                            );
                          }}
                </Downshift>
        		<Button style={{marginLeft: "10px", verticalAlign: "bottom", marginBottom: "4px"}}
                      onClick={ event => handleAddDiscountClick(event)}
                      color="inherit"
                      disableRipple
                      className={classes.editButton}
                    >
                      {i18n.t('add')}
                    </Button>
                    
                    </>
                ] : <div style={{display: "inline-block"}}> </div> }    
                    
              <div style={{float:"right"}}>
        	     <Fade in={this.props.showDiscountsSearchField}>
			        <div elevation={8} style={{ display: "inline-block"}} >
			           {this.props.showDiscountsSearchField && <TextField 
			               id="discount-search" 
			               points="0,100 50,00, 100,100" 
			               style={{width:"160px"}} 
			               placeholder={i18n.t('search')} 
			               onChange={this.props.handleChangeSearch} 
			               type="search" 
			           />}
			       </div>
		         </Fade>
                <IconButton id="discount-search-button" style={{ display: "inline-block"}} aria-label="Search" href="#" onClick={e => this.props.handleSearchClick(e)}>
						<SearchIcon />
			    </IconButton>
             </div>
            </div>
            
            <div className={classes.tableWrapper} style={{maxHeight: "400px", marginTop: "35px"}}>
            
              <Table className={classNames()} size={'small'}>
                
                <TableBody>
                
                  {selectedClients
                    .map(clientDiscount => {
                      return (
                        <TableRow hover>
                          <TableCell
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                      >
                          	<Switch size="small" checked={clientDiscount.isActive == 1} color="primary" disabled={!isAdmin} onChange={event => handleInputChange(event, clientDiscount)} />
                          </TableCell>
                          {!client  && <TableCell style={{width: "30%"}}>
                            {clientDiscount.supplierClient.name}
                          </TableCell>}
                          <TableCell style={{width: "30%"}}>
                            {clientDiscount.category}
                          </TableCell>
                          <TableCell style={{width: "30%"}}>
                            {clientDiscount.vendor}
                          </TableCell>
                          <TableCell>
                            
                          </TableCell>
                          <TableCell style={{width: "30%"}}>
                           <Downshift  initialIsOpen={true} initialInputValue={""}>{({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onBlur: event => {
                                 handleInputChange(event, clientDiscount);
                              },
                              onFocus: openMenu,
                              onChange: event => {
                                 clientDiscount.discountPercent = event.target.value;
                              },
                            });
                            return (
                              <div >
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  name:'discount',
                                  disabled: !isAdmin,
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus, classes:{root:classes.addressCellInputSmall}},
                                  inputProps: { style: { textAlign: 'right' }},
                                  value: clientDiscount.discountPercent !== null ? clientDiscount.discountPercent : "" ,
                                })}
                              </div>
                            );
                          }}</Downshift>
                          </TableCell>
                          { (isAdmin) &&
                          <TableCell 
                          	  align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                          >
                          	<Button 
                              onClick={ event => handleDeleteDiscountClick(clientDiscount) }
                              color="inherit"
                              disableRipple
                              className={classes.editButton}
                            >
                              <Close className={classes.editIcon}/>
                            </Button>
                          </TableCell>
                          }
               			</TableRow>
               			)}
               		)}
               	</TableBody>
            </Table>   		
            </div>
         </div>   
       </Modal>
     </div>
  )
  };                  
}

class ExceptionsLightbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		        categorySuggestions: [],
		        vendorSuggestions: [],
		        clientSuggestions: [],
		        selectedClient: null
    }
  };


  
  componentDidMount = () => {
  
  	  
    
  }
  
  
  render() {
    const { i18n, classes, item, open, isAdmin, sortDirection, sortBy, clientRules, client, filteredClientRules, isManager } = this.props;
    const modalStyle = getEditModalStyle();

  const handleIsActiveChange = (clientRule, event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    clientRule.isActive = (event.target.checked ? 1 : 0);
/*    clientRules.map(cr => {
    	if (cr.id == clientRule.id) {
    		cr.isActive = (event.target.checked ? 1 : 0);
    	}
    });
*/

	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/rule/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierClientRuleId=" + clientRule.id + "&principalId=" + user.id + "&isActive=" + clientRule.isActive;

    var that = this;
    var cb = function(data) {
      if (data) {
        that.setState({ state: this.state });
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    
    
  };
  
  const handleDeleteRuleClick = (clientRule) => {
  
    if (!window.confirm("Удалить исключение?")) {
    	return;
    }
  
	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/rule/delete?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierClientRuleId=" + clientRule.id + "&principalId=" + user.id;

    var that = this;
    var cb = function(data) {
      if (data) {
	    var ind = clientRules.indexOf(clientRule);
	    if (ind >= 0) {
		    clientRules.splice(ind, 1);
//		    if (that) {
//		        that.setState({ state: this.state });
//	        }
	    }
        
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    
    cb(true);
  
  }
  
  const handleAddRuleClick = (event) => {
  
  	var category = document.getElementById('category-options-input').value;
  	var vendor = document.getElementById('vendor-options-input').value;
    var clientName = document.getElementById('client-options-input') ? document.getElementById('client-options-input').value : "";
    
    if (!client && (clientName == null || clientName == 0)) {
	   alert('Пожалуйста, заполните поле "Контрагент"');
  	   return;
    } 
    if ((category == null || category == 0) && (vendor == null || vendor == 0)) {
  		alert("Пожалуйста, заполните категорию или бренд");
  		return;
  	}
	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/rule/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&principalId=" + (user ? user.id : '') + "&supplierId=" + localStorage.getItem('supplierId') + "&supplierClientId=" + (client ? client.id : this.state.selectedClient.id) + "&type=1&isActive=1&category=" + (category != null ? category : '') +
    				 "&vendor=" + (vendor != null ? vendor : '');

    var that = this;
    var cb = function(data) {
      if (data) {
       	clientRules.unshift(data);
        that.setState({ state: this.state });
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  
  
  
  }
  
  const handleTextFieldChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

	function renderSuggestion(suggestionProps) {
	  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
	  const isHighlighted = highlightedIndex === index;
	  const isSelected = (selectedItem || '').indexOf(suggestion) > -1;
	
	  return (
	    <MenuItem
	      {...itemProps}
	      key={suggestion.id}
	      selected={isHighlighted}
	      component="div"
	      style={{
	        fontWeight: isSelected ? 500 : 400,
	        fontSize: "14px"
	      }}
	    >
	      <span style={{marginLeft: "0px"}}>{suggestion}</span>
	    </MenuItem>
	  );
	}
    const handleClientChange = event => {
	  
    var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/client/search?key=' + constants.apiKey + "&principalId=" + user.id + "&supplierId=" + localStorage.getItem('supplierId') + '&upd=' + new Date().getTime() + "&s=" + document.getElementById('client-options-input').value;

    var that = this;
    var cb = function(data) {
      that.setState({ clientSuggestions: data.map(item => item = item.name), selectedClient: data[0] }); 
    }
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
   }

   const handleCategoryChange = event => {
	  
	var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/category/search?key=' + constants.apiKey + "&principalId=" + user.id + "&supplierId=" + localStorage.getItem('supplierId') + '&upd=' + new Date().getTime() + "&s=" + document.getElementById('category-options-input').value;

    var that = this;
    var cb = function(data) {
      that.setState({ categorySuggestions: data }); 
    }
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
   }
   
   const handleVendorChange = event => {
	  
	var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/vendor/search?key=' + constants.apiKey + "&principalId=" + user.id + "&supplierId=" + localStorage.getItem('supplierId') + '&upd=' + new Date().getTime() + "&s=" + document.getElementById('vendor-options-input').value;

    var that = this;
    var cb = function(data) {
      that.setState({ vendorSuggestions: data }); 
    }
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
   }
   
    var selectedClients = filteredClientRules != null   ? filteredClientRules : clientRules;

	function getSuggestions(value, { showEmpty = false } = {}, suggestions) {
	  const inputValue = value.trim().toLowerCase();
	  const inputLength = inputValue.length;
	  let count = 0;
	
	  return inputLength === 0 && !showEmpty
	    ? []
	    : suggestions;
	}
  
	function renderInput(inputProps) {
	  const { InputProps, classes, ref, ...other } = inputProps;
	
	  return (
	    <TextField
	      InputProps={{
	        inputRef: ref,
	        classes: {
	          root: classes.inputRoot,
	          input: classes.inputInput,
	        },
	        ...InputProps,
	      }}
	      {...other}
	    />
	  );
	}

    return (
      <div>
        <Modal
          open={open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox}>
            <Typography variant="h6" id="tableTitle">
              { i18n.t('exceptions') + (client ? ' - ' + client.name : '') }
            </Typography>
            <IconButton 
              onClick={event => this.props.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            
            <div style={{marginTop: "25px"}}>
            
            		{ (isAdmin) ? [
            		 <>
                	 {!client && 
                	 <Downshift id="client-options" style={{width: "300px"}} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
                          if (selection !== null) {
                            
                          }
                          this.state.clientSuggestions = [];
                        }}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                if (event.target.value === '') {
                                  clearSelection();
                                } else {
                                  handleClientChange(event);
                                }
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "250px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  label: i18n.t('contractor'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                })}

                                <div {...getMenuProps()}>
                                  {isOpen ? (
                                    <Paper className={classes.paper} square style={{maxHeight: "250px", overflow: "auto", position: "absolute", zIndex: "100", width: "300px"}}>
                                      {getSuggestions(inputValue, { showEmpty: false }, this.state.clientSuggestions).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }}
                </Downshift>}
                
                
            	<Downshift id="category-options" style={{width: "300px"}} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
                          if (selection !== null) {
                            
                          }
                          this.state.categorySuggestions = [];
                        }}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                if (event.target.value === '') {
                                  clearSelection();
                                } else {
                                  handleCategoryChange(event);
                                }
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "250px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  label: i18n.t('category'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                })}

                                <div {...getMenuProps()}>
                                  {isOpen ? (
                                    <Paper className={classes.paper} square style={{maxHeight: "250px", overflow: "auto", position: "absolute", zIndex: "100", width: "300px"}}>
                                      {getSuggestions(inputValue, { showEmpty: false }, this.state.categorySuggestions).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }}
                </Downshift>
                
                <Downshift id="vendor-options" style={{width: "300px"}} initialIsOpen={true} initialInputValue={""} onSelect={selection => {
                          if (selection !== null) {
                            
                          }
                          this.state.vendorSuggestions = [];
                        }}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                if (event.target.value === '') {
                                  clearSelection();
                                } else {
                                  handleVendorChange(event);
                                }
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "250px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  label: i18n.t('vendor'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                })}

                                <div {...getMenuProps()}>
                                  {isOpen ? (
                                    <Paper className={classes.paper} square style={{maxHeight: "250px", overflow: "auto", position: "absolute", zIndex: "100", width: "280px"}}>
                                      {getSuggestions(inputValue, { showEmpty: false }, this.state.vendorSuggestions).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }}
                </Downshift>
            	
        		<Button style={{marginLeft: "10px", verticalAlign: "bottom", marginBottom: "4px"}}
                      onClick={ event => handleAddRuleClick(event) }
                      color="inherit"
                      disableRipple
                      className={classes.editButton}
                    >
                      {i18n.t('add')}
                    </Button>

				</>
				] : <div style={{display: "inline-block"}}> </div> }
				
              <div style={{float:"right"}}>
        	     <Fade in={this.props.showExceptionsSearchField}>
			        <div elevation={8} style={{ display: "inline-block"}} >
			           {this.props.showExceptionsSearchField && <TextField 
			               id="exceptions-search" 
			               points="0,100 50,00, 100,100" 
			               style={{width:"160px"}} 
			               placeholder={i18n.t('search')} 
			               onChange={this.props.handleChangeSearch} 
			               type="search" 
			           />}
			       </div>
		         </Fade>
                <IconButton id="exceptions-search-button" style={{ display: "inline-block"}} aria-label="Search" href="#" onClick={e => this.props.handleSearchClick(e)}>
						<SearchIcon />
			    </IconButton>
             </div>
         </div>
            
            <div className={classes.tableWrapper} style={{maxHeight: "400px", marginTop: "35px"}}>
            
              <Table className={classNames()} size={'small'}>
                
                <TableBody>
                
                  {selectedClients
                    .map(clientRule => {
                      return (
                        <TableRow hover>
                          <TableCell
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                      >
                          	<Switch size="small" checked={clientRule.isActive == 1} disabled={!isAdmin} color="primary" onChange={e => handleIsActiveChange(clientRule, e)} />
                          </TableCell>
                          {!client  && <TableCell style={{width: "30%"}}>
                            {clientRule.supplierClient.name}
                          </TableCell>}
                          <TableCell style={{width: "30%"}}>
                            {clientRule.category}
                          </TableCell>
                          <TableCell>
                            {clientRule.vendor}
                          </TableCell>
                          <TableCell>
                          
                            {clientRule.dateUpd ? 
                            	<Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY">
                              		{clientRule.dateUpd}
                            	</Moment>
                              : 
                                (
                                  clientRule.dateAdd ?
                              		<Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY">
                              			{clientRule.dateAdd}
                            		</Moment>
                            	  : ""
                            	)
                            }
                              
                          </TableCell>
                          { (isAdmin) &&
                          <TableCell 
                          	  align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                          >
                          	<Button 
                              onClick={ event => handleDeleteRuleClick(clientRule) }
                              color="inherit"
                              disableRipple
                              className={classes.editButton}
                            >
                              <Close className={classes.editIcon}/>
                            </Button>
                          </TableCell>
                          }
               			</TableRow>
               			)}
               		)}
               	</TableBody>
            </Table>   		
            </div>
         </div>   
       </Modal>
     </div>
  )
  };                  
}

class AddressesLightbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		        selectedClient: null,
                type: null,
                countryId: 248,
                cityId: 269,
                externalId:null,
                address:null,
                minOrderAmount:null,
    }
  };

  componentDidMount = () => { 
	    this.getCountries();
        this.getCities(this.state.countryId);
  }
  
  getCountries = () => {
   
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/countries?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    
    var that = this; 
    var cb = function(data) {  that.setState({ countries: data }) };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  getCities = countryId => {
  
    if (!countryId) {
    	return { cities: {}};
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/cities?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&countryId=" + countryId;

    var that = this; 
    var cb = function(data) { that.setState({ cities: data }) };

    this.props.handleProgressBarOpen();
    return doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleInputSelected = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { i18n, classes, item, open, isAdmin, sortDirection, sortBy, clientAddresses, client, isManager } = this.props;
    const modalStyle = getModalStyle();

  const handleInputChange = (event, clientAddress) => {
	       if (event.target.type == "checkbox") {
    clientAddress.isActive = (event.target.checked ? 1 : 0);
           }
	    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/address/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&principalId=" +  user.id  +
               "&supplierId=" + clientAddress.supplierId + 
               "&supplierClientId=" + clientAddress.supplierClientId +
               "&supplierClientAddressId=" + clientAddress.id +  
               "&cityId=" +  clientAddress.cityId + 
               "&countryId=" +  clientAddress.countryId + 
               "&type=" + (this.state.type == null ? clientAddress.type : this.state.type) + 
               "&isActive=" + clientAddress.isActive + 
               "&externalId=" + (event.target.name =='externalId' ? event.target.value : clientAddress.externalId) +
               "&address=" + (event.target.name =='address' ? event.target.value : clientAddress.address) +
               "&minOrderAmount=" + (event.target.name =='minOrderAmount' ? event.target.value : (clientAddress.minOrderAmount ? clientAddress.minOrderAmount: ""));

    var that = this; 
    var cb = function(data) {
      if (data) {
        that.setState({ state: this.state });
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);

    
  };
  
  const handleDeleteAddressClick = (clientAddress) => {
  
    if (!window.confirm("Удалить адрес?")) {
    	return;
    }
  
	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/address/delete?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierClientAddressId=" + clientAddress.id + "&principalId=" + user.id;

    var that = this;
    var cb = function(data) {
      if (data) {
	    var ind = clientAddresses.indexOf(clientAddress);
	    if (ind >= 0) {
		    clientAddresses.splice(ind, 1);
//		    if (that) {
//		        that.setState({ state: this.state });
//	        }
	    }
        
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    
    cb(true);
  
  }
  
  const handleAddAddressClick = (event) => {

	 if ((this.state.address == null || this.state.address == 0) || 
		     (this.state.cityId == null || this.state.cityId == 0)  || 
		     (this.state.countryId == null || this.state.countryId == 0) ||  
		     (this.state.externalId == null || this.state.externalId == 0) ||  
		     (this.state.type == null || this.state.type == 0)){
      		alert("Пожалуйста, заполните все поля");
  		    return;
  	}
	    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client/address/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body ="&principalId=" +  (user ? user.id : "")  +
              "&supplierId=" + localStorage.getItem('supplierId')+
              "&supplierClientId=" + (client ? client.id: '') + 
              "&supplierClientAddressId=" +
              "&cityId=" + this.state.cityId +
              "&countryId=" + this.state.countryId +
              "&type=" + this.state.type +
              "&isActive=1"  +   
              "&externalId=" + (this.state.externalId ? this.state.externalId : '') +
              "&address=" + this.state.address +
              "&minOrderAmount=" + (this.state.minOrderAmount ? this.state.minOrderAmount : '');

    var that = this;
    var cb = function(data) {
      if (data) {
       	clientAddresses.unshift(data);
        that.setState({ state: this.state });
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    clearInput();
  }
 const clearInput = () =>{
   this.setState({type:null, externalId:null, address:null, countryId: 248, cityId: 269, minOrderAmount: null});
   document.getElementById('address-options-input').value = "";
   document.getElementById('external-id-options-input').value = "";
   document.getElementById('min-order-amount-options-input').value = "";
   this.getCities(248);

  }
  
	  
	function renderInput(inputProps) {
	  const { InputProps, classes, ref, ...other } = inputProps;
	
	  return (
	    <TextField
	      InputProps={{
	        inputRef: ref,
	        classes: {
	          root: classes.inputRoot,
	          input: classes.inputInput,
	        },
	        ...InputProps,
	      }}
	      {...other}
	    />
	  );
	}
    return (
      <div>
        <Modal
          open={open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.lightbox}>
            <Typography variant="h6" id="tableTitle">
              { i18n.t('addressesEdit') + (client? ' - ' + client.name : '') }
            </Typography>
            <IconButton 
              onClick={event => {this.props.handleClose(event); clearInput();}}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            
            
            
            <div style={{marginTop: "25px"}}>

                     <Downshift id="type-options"  initialIsOpen={true} initialInputValue={""}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({

                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "140px", marginLeft: "0px", display: "inline-block"}}>
                                      <FormControl style={{width: "100%"}}>
                                      <InputLabel shrink>
                                             {i18n.t('type')}
                                      </InputLabel>
                                      <Select displayEmpty shrink 
                                              onChange={event => this.handleInputSelected(event)}
                                              name={"type"}
                            				  value={this.state.type}>
                                               
                                             <option  value=''></option>
                                             <option  value='1'>ПВЗ</option>
                                             <option  value='2'>Доставка</option>
                                       </Select>
                                      </FormControl>
                                    
                              
                              </div>
                            );
                          }}
                </Downshift>

            	<Downshift id="external-id-options" initialIsOpen={true} initialInputValue={""}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                 this.handleInputSelected(event);
                              },

                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "120px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  id:"external-id-options-input",
                                  classes,
                                  name:"externalId",
                                  label: i18n.t('id'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                  inputProps:{defaultValue: this.state.externalId !== null ? this.state.externalId : ""},
                                })}

                              </div>
                            );
                          }}
                </Downshift>
                <Downshift id="country-options" initialIsOpen={true} initialInputValue={""}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "150px", marginLeft: "0px", display: "inline-block"}}>
                   <FormControl  style={{width: "100%"}}>                
                      <InputLabel shrink htmlFor="country" >
                        {i18n.t('country')}
                      </InputLabel>
                      <Select
                        onChange={event => {this.handleInputSelected(event); this.getCities(event.target.value);}}
                        value={this.state.countryId}
                        name={"countryId"}
                        input={ <Input 
                                  id="country" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                />}
                      >
                      	<option  value=''></option>
                        { this.state.countries !== null && this.state.countries !== undefined &&
                          this.state.countries.map(country => {
                            return(
                              <option  value={country.id}>
                                {country.nameRu}
                              </option>
                            )
                          })
                        }
                      </Select>
                  </FormControl>
                                    
                              
                              </div>
                            );
                          }}
                </Downshift>
                <Downshift id="city-options" initialIsOpen={true} initialInputValue={""}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "150px", marginLeft: "0px", display: "inline-block"}}>
                   <FormControl  style={{width: "100%"}}>                
                      <InputLabel shrink htmlFor="city" >
                        {i18n.t('city')}
                      </InputLabel>
                      <Select
                        onChange={event => this.handleInputSelected(event)}
                        value={this.state.cityId}
                        name={"cityId"}
                        input={ <Input 
                                  id="city" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                />}
                      >
                      	<option  value=''></option>
                        { this.state.cities !== null && this.state.cities !== undefined &&
                          this.state.cities.map(city => {
                            return(
                              <option  value={city.id}>
                                {city.name}
                              </option>
                            )
                          })
                        }
                      </Select>
                  </FormControl>
                              </div>
                            );
                          }}
                </Downshift>
  
                <Downshift id="address-options" initialIsOpen={true} initialInputValue={""}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                 this.handleInputSelected(event);
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container} style={{width: "400px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  id:"address-options-input",
                                  classes,
                                  name:"address",
                                  label: i18n.t('address'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                  inputProps:{defaultValue: this.state.address !== null ? this.state.address : ""},
                                })}
                              </div>
                            );
                          }}
                </Downshift>
                <Downshift id="min-order-amount-options" initialIsOpen={true} initialInputValue={""}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                 this.handleInputSelected(event);
                              },
                              onFocus: openMenu,
                            });
                            return (
                              <div className={classes.container} style={{width: "120px", marginLeft: "0px", display: "inline-block"}}>
                                {renderInput({
                                  fullWidth: true,
                                  id:"min-order-amount-options-input",
                                  classes,
                                  name:"minOrderAmount",
                                  label: i18n.t('minOrderAmount'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                  inputProps:{defaultValue: this.state.minOrderAmount !== null ? this.state.minOrderAmount : ""},
                                })}
                              </div>
                            );
                          }}
                </Downshift>
            	
        		<Button style={{marginLeft: "10px", verticalAlign: "bottom", marginBottom: "4px"}}
                      onClick={ event => handleAddAddressClick(event) }
                      color="inherit"
                      disableRipple
                      className={classes.editButton}
                    >
                      {i18n.t('add')}
                    </Button>
            </div>
            
            <div className={classes.tableWrapper} style={{maxHeight: "400px", marginTop: "35px"}}>
            
              <Table className={classNames()} size={'small'}>
                
                <TableBody>
                
                  {clientAddresses
                    .map(clientAddress => {
                      return (
                        <TableRow hover>
                        <TableCell
	                          align="left"
	                          padding="none"
	                          className={classes.addressCellSwitch}
	                          style={{paddingLeft: "0px"}}
	                      >
                          	<Switch size="small" checked={clientAddress.isActive == 1} color="primary" onChange={event => handleInputChange(event, clientAddress)} />
                          </TableCell>
                           <TableCell className={classes.addressCell}>
                            {clientAddress.type == 1 ? "ПВЗ" : "Доставка"}
                          </TableCell>

                          <TableCell style={{"width":"100px"}}>
                           <Downshift  initialIsOpen={true} initialInputValue={""}>{({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onBlur: event => {
                                 handleInputChange(event, clientAddress);
                              },
                              onFocus: openMenu,
                              onChange: event => {
                                 clientAddress.externalId = event.target.value;
                              },
                            });
                            return (
                              <div >
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  name:'externalId',
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus, classes:{root:classes.addressCellInputSmall}},
                                  value:clientAddress.externalId,
                                })}
                              </div>
                            );
                          }}</Downshift>
                          </TableCell>
                          <TableCell className={classes.addressCell}>
                            {clientAddress.country.nameRu}
                          </TableCell>
                          <TableCell className={classes.addressCell}>
                            {clientAddress.city.name}
                          </TableCell>

                          <TableCell >
                           <Downshift  initialIsOpen={true} initialInputValue={""}>{({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onBlur: event => {
                                 handleInputChange(event, clientAddress);
                              },
                              onFocus: openMenu,
                              onChange: event => {
                                 clientAddress.address = event.target.value;
                              },
                            });
                            return (
                              <div >
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  name:'address',
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus, classes:{root:classes.addressCellInputLarge} },
                                  value:clientAddress.address,
                                })}
                              </div>
                            );
                          }}</Downshift>
                         </TableCell>

                          <TableCell style={{"width":"100px"}}>
                           <Downshift  initialIsOpen={true} initialInputValue={""}>{({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onBlur: event => {
                                 handleInputChange(event, clientAddress);
                              },
                              onFocus: openMenu,
                              onChange: event => {
                                 clientAddress.minOrderAmount = event.target.value;
                              },
                            });
                            return (
                              <div >
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  name:'minOrderAmount',
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus, classes:{root:classes.addressCellInputSmall}},
                                  value:clientAddress.minOrderAmount,
                                })}
                              </div>
                            );
                          }}</Downshift>
                          </TableCell>
                          <TableCell 
                          	  align="right"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                          >
                          	<Button 
                              onClick={ event => handleDeleteAddressClick(clientAddress) }
                              color="inherit"
                              disableRipple
                              className={classes.editButton}
                            >
                              <Close className={classes.editIcon}/>
                            </Button>
                          </TableCell>
               			</TableRow>
               			)}
               		)}
               	</TableBody>
            </Table>   		
            </div>
         </div>   
       </Modal>
     </div>
  )
  };                  
}

class EditLightbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saveSuccess: false,
      emptyLogin: false,
      emptyPassword: false,
      emptyEmail: false,
      cardsInputOpen: false,
      managers: [],
      supplierId: localStorage.getItem('supplierId'),
      clientGroups: []
    }
  };

  save = () => {

    var name = document.getElementById('edit-lb-name').value;

    if (name === "") {
      if (name === "") {
        this.setFieldState("emptyName");
      }
      return;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    const { item } = this.props;
    var url = constants.apiBaseUrl + '/b2b/supplier/update-client?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = (item !== null ? "&supplierClientId=" + item.id : "") + "&supplierId=" + this.state.supplierId + "&principalId=" + user.id + "&name=" + document.getElementById('edit-lb-name').value + 
    			"&externalId=" + document.getElementById('edit-lb-externalId').value + "&unp=" + document.getElementById('edit-lb-unp').value + "&requisites=" + 
    			document.getElementById('edit-lb-requisites').value + "&supplierManagerUserId=" + ((this.props.isManager || this.props.isAdmin) ? document.getElementById('edit-lb-supplierManagerId').value : supplierUser.id )
			    + "&isBlocked=" + (document.getElementById('edit-lb-isBlocked').checked ? 1 : 0) + "&ignoreOrderMinAmount=" + (document.getElementById('edit-lb-ignoreOrderMinAmount').checked ? 1 : 0);
    			
    if (document.getElementById('edit-lb-clientGroupId')) {
    	body += "&clientGroupId=" + document.getElementById('edit-lb-clientGroupId').value;
    }

    var that = this;
    var cb = function(data) {
      if (data) {
        that.setFieldState("saveSuccess");
        that.props.updateTableData();
        that.handleClose();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  handleManagerSelected = event => {
    console.log(event.target.value )
  }

  componentDidMount = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;

    if (false) {
	    var url = constants.apiBaseUrl + '/b2b/supplier/manager-ids?key=' + constants.apiKey + '&supplierId=' + this.state.supplierId + '&sortBy=name' + "&principalId=" + user.id + '&upd=' + new Date().getTime();
	
	    var that = this;
	
	    var cb = function(data) { 
	        var url = constants.apiBaseUrl + '/b2b/supplier/managers?key=' + constants.apiKey + '&supplierId=' + that.state.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
	        
	        for (var k in data) {
	        	url += '&ids=' + data[k];
	        }
	
		    var that2 = that;
		
		    var cb = function(data) { 
		      that2.setState({ managers: data });
		    };
		
		    that.props.handleProgressBarOpen();
		    doAjax(url, cb, that.props.handleProgressBarClose);
	    };
	
	    this.props.handleProgressBarOpen();
	    doAjax(url, cb, this.props.handleProgressBarClose);
    }
    
    var url = constants.apiBaseUrl + '/b2b/supplier/users/managers?key=' + constants.apiKey + '&supplierId=' + this.state.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
	 
    var that = this;

    var cb = function(data) { 
      that.setState({ managers: data });
    };
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose);

    var url2 = constants.apiBaseUrl + '/b2b/supplier/client-groups?key=' + constants.apiKey + '&supplierId=' + this.state.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    var cb2 = function(data) { 
      that.setState({ clientGroups: data });
    };
    this.props.handleProgressBarOpen();
    doAjax(url2, cb2, this.props.handleProgressBarClose);

    
  }

  handleClose = event => {
    this.setState({ cardsInputOpen: false });
    this.setState({ card: null }); 
    this.props.handleClose();
  }

  removeCard = () => {
    this.setState({ cardsInputOpen: false }); 
    this.props.removeCard();
  }

  setCard = id => {
    this.setState({ cardsInputOpen: false }); 
    this.props.setCard(id);
  }

  render() {
    const { i18n, classes, item, open, card, isAdmin, isManager, isBlocked, ignoreOrderMinAmount } = this.props;
    const modalStyle = getEditModalStyle();

    return (
      <div>
        <Modal
          open={open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox}>
            <Typography variant="h6" id="tableTitle">
              { item === null ? i18n.t('adding') : i18n.t('edition')}
            </Typography>
            <IconButton 
              onClick={event => this.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            <div style={{margin: "auto"}}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('name') + '*'}
                      id="edit-lb-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.name : ""
                      }}
					  error={this.state.emptyName}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('ID') + ''}
                      id="edit-lb-externalId"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.externalId : ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('unp')}
                      id="edit-lb-unp"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.unp : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>

				<GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={i18n.t('requisites')}
                      id="edit-lb-requisites"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.requisites : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
                
                {
                this.state.clientGroups.length > 0 &&
                
                <GridContainer>
                  
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="edit-lb-clientGroupId" className={classes.labelRoot}>
                        {i18n.t('clientGroup')}
                      </InputLabel>
                      <NativeSelect
                        onChange={event => this.handleManagerSelected(event)}
                        input={ 
                                <Input 
                                  id="edit-lb-clientGroupId" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                  inputProps={{
                                    defaultValue: item !== null ? item.supplierClientGroupId : ""
                                  }}
                                />
                              }
                      >
                      	<option />
                      	
                      	
                      	{this.state.clientGroups.map(
            				clientGroup => (clientGroup.isActive == 1 ?
		                        <option value={clientGroup.id}>
		                          {clientGroup.name} 
		                        </option> : ""
                        ))}
                        
                      </NativeSelect>
                    </FormControl>
                  </GridItem>

                </GridContainer>
                
                }
                
                {(isAdmin || isManager) &&
                <GridContainer>
                  
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="edit-lb-manager" className={classes.labelRoot}>
                        {i18n.t('manager')}
                      </InputLabel>
                      <NativeSelect
                        onChange={event => this.handleManagerSelected(event)}
                        input={ 
                                <Input 
                                  id="edit-lb-supplierManagerId" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                  inputProps={{
                                    defaultValue: item !== null ? item.supplierManagerUserId : ""
                                  }}
                                />
                              }
                      >
                      	<option />
                      	
                      	
                      	{this.state.managers.map(
            				manager => (
                        <option value={manager.id}>
                          {manager.user.lastName} {manager.user.firstName} 
                        </option>
                        ))}
                        
                      </NativeSelect>
                    </FormControl>
                  </GridItem>

                </GridContainer>
                }
			  
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2} style={{marginTop: "15px"}}>
		                
		  	                  <FormControlLabel className={classes.filterCheckbox}
		                    control={
		                      <input id="edit-lb-isBlocked" type="checkbox" checked={this.state.isBlocked} style={{transform: "scale(1.5)", margin: "16px"}} 
		                      		 disabled={this.state.isUserAdmin || (this.state.contractorId > 0 || (this.state.contractorId == null && item && item.supplierClientId > 0))}
		                      		 defaultChecked={item && item.supplierClientId == null && item.isBlocked == 1} /> 
		                    }
		                    label={i18n.t('block')}
		                    />
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={4} style={{marginTop: "15px"}}>
		                
		  	                  <FormControlLabel className={classes.filterCheckbox}
		                    control={
		                      <input id="edit-lb-ignoreOrderMinAmount" type="checkbox" checked={this.state.ignoreOrderMinAmount} style={{transform: "scale(1.5)", margin: "16px"}} 
		                      		 disabled={this.state.isUserAdmin || (this.state.contractorId > 0 || (this.state.contractorId == null && item && item.supplierClientId > 0))}
		                      		 defaultChecked={item && item.supplierClientId == null && item.ignoreOrderMinAmount == 1} /> 
		                    }
		                    label={i18n.t('ignoreOrderMinAmount')}
		                    />
                  </GridItem>
                </GridContainer>
	
                <GridContainer style={{  }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{marginTop: "25px"}}>
                      <CustomButton 
                        color="primary"
                        onClick={this.save}
                      >
                        {item === null ? i18n.t('add') : i18n.t('save')}
                      </CustomButton>
                      {this.state.saveSuccess && <Check style={{color: "green", marginLeft: "25px", verticalAlign: "middle"}} />}
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}


class ManagerEditLightbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saveSuccess: false,
      cardsInputOpen: false,
      managers: [],
      supplierId: localStorage.getItem('supplierId'),
      clientGroups: [],
      selectedManager:""
    }
  };

  save = () => {

	if (!this.state.selectedManager) {
		alert('Пожалуйста, выберите менеджера');
		return;
	}
    
	var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    const { item } = this.props;
    var url = constants.apiBaseUrl + '/b2b/supplier/client/update/manager?key=' + constants.apiKey + '&upd=' + new Date().getTime();
    
    var body = "&supplierId=" + this.state.supplierId + "&principalId=" + user.id  + 
    			"&supplierManagerUserId=" + this.state.selectedManager
    			+ "&supplierClientIds=" + this.props.selected;			

    var that = this;
    var cb = function(data) {
      if (data) {
        // that.props.selected = []; // readOnly
        that.props.updateTableData();
        that.handleClose();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
     
  }


  handleManagerSelected = event => {
    this.setState({
	selectedManager: event.target.value
	})
  }

  componentDidMount = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;

    if (false) {
	    var url = constants.apiBaseUrl + '/b2b/supplier/manager-ids?key=' + constants.apiKey + '&supplierId=' + this.state.supplierId + '&sortBy=name' + "&principalId=" + user.id + '&upd=' + new Date().getTime();
	
	    var that = this; 
	
	    var cb = function(data) { 
	        var url = constants.apiBaseUrl + '/b2b/supplier/managers?key=' + constants.apiKey + '&supplierId=' + that.state.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
	        
	        for (var k in data) {
	        	url += '&ids=' + data[k];
	        }
	
		    var that2 = that;
		
		    var cb = function(data) { 
		      that2.setState({ managers: data });
		    };
		
		    that.props.handleProgressBarOpen();
		    doAjax(url, cb, that.props.handleProgressBarClose);
	    };
	
	    this.props.handleProgressBarOpen();
	    doAjax(url, cb, this.props.handleProgressBarClose);
    }
    
    var url = constants.apiBaseUrl + '/b2b/supplier/users/managers?key=' + constants.apiKey + '&supplierId=' + this.state.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
	 
    var that = this;

    var cb = function(data) { 
      that.setState({ managers: data });
    };
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose);

    
  }

  handleClose = event => {
    this.setState({ cardsInputOpen: false });
    this.setState({ card: null }); 
    this.props.handleClose();
  }

  removeCard = () => {
    this.setState({ cardsInputOpen: false }); 
    this.props.removeCard();
  }

  setCard = id => {
    this.setState({ cardsInputOpen: false }); 
    this.props.setCard(id);
  }

  render() {
    const { i18n, classes, item, open, card, isAdmin, isManager, isBlocked, ignoreOrderMinAmount } = this.props;
    const modalStyle = getEditModalStyle();

    return (
      <div>
        <Modal
          open={open}
          onClose={this.props.handleClose}
          style={{height: "80%"}}
        >
          <div style={modalStyle} className={classes.editLightbox} >
            <Typography variant="h6" id="tableTitle">
              { item === null ? i18n.t('managers') : i18n.t('edition')}
            </Typography>
            <IconButton 
              onClick={event => this.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            <div style={{margin: "auto"}}>
               
                
                {(isAdmin || isManager) &&
                <GridContainer>
                  
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="edit-lb-manager" className={classes.labelRoot}>
                        {i18n.t('manager')}
                      </InputLabel>
                      <NativeSelect
                      	value={this.state.selectedManager}
                        onChange={this.handleManagerSelected}
                        input={ 
                                <Input 
                                  id="edit-lb-supplierManagerId" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                  inputProps={{
                                    defaultValue: item !== null ? item.supplierManagerUserId : ""
                                  }}
                                />
                              }
                      >
                      	<option />
                      	
                      	
                      	{this.state.managers.map(
            				manager => (
                        <option value={manager.id}>
                          {manager.user.lastName} {manager.user.firstName} 
                        </option>
                        ))}
                        
                      </NativeSelect>
                    </FormControl>
                  </GridItem>

                </GridContainer>
                }
			  
	
                <GridContainer style={{  }} align="center">
                  <GridItem xs={12} sm={4} md={12}>
                    <div style={{marginTop: "25px"}}>
                      <CustomButton 
                        color="primary"
                        onClick={this.save}
                      >
                        {item === null ? i18n.t('appointManager') : i18n.t('save')}
                      </CustomButton>
                      {this.state.saveSuccess && <Check style={{color: "green", marginLeft: "25px", verticalAlign: "middle"}} />}
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}


class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      clientItems: [],
      clientOpen: false,
      client: null,
      clientIds: [],
      supplierId:localStorage.getItem('supplierId'),
      page: 0,
      rowsPerPage: 50,
      sortDirection: 'desc',
      sortBy: 'dateAdd',
      edit: '',
      editLBOpen: false,
      editLBItem: null,
      editManLBOpen: false,
      editManLBItem: null,
      exceptionsLBOpen: false,
      exceptionsLBItem: null,
      addressesLBOpen: false,
      addressesLBItem: null,
      discountsLBOpen: false,
      discountsLBItem: null,
      card: null,
      clientRules: [],
      selected: [],
      clientDiscounts: [],
      clientManagers: [],
      clientGroupFilterItems: [],
      clientGroupSelectedFilterItems: [],
      managerFilterItems: [],
      managerSelectedFilterItems: [],
      showSearchField: false,
      showExceptionsSearchField: false,
      showDiscountsSearchField: false,
      request: '',
      clientAddresses: [],
      filteredClientRules: null,
      filteredClientDiscounts: null,
    }
  }


  updateFilterItems = () => {
  
    const { user, supplierId, isAdmin, isClientAdmin, edit } = this.props;
  
    var supplierClient = null;
    if (localStorage.getItem('supplierClient')) {
	    supplierClient = JSON.parse(localStorage.getItem('supplierClient'));
    }

//	var user = JSON.parse(localStorage.getItem('user')).value;
	    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/client-ids?key=' + constants.apiKey + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId
    				+ "&principalId=" + user.id
    				//+ (!isAdmin ? "&userId=" + user.id : "")
    				+ (!isAdmin && supplierClient ? "&supplierClientId=" + supplierClient.id : "")
    				+ (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "")
    				+ "&sortBy=" + this.state.sortBy + "&sort=" + this.state.sortDirection;
	
    var that = this;
    var cb = function(data) { 
      that.makeFilterItems(that, data.clientGroupFilterItems, data.managerFilterItems);
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }
  
  
  
  makeFilterItems = (that, clientGroups, managers) => {
	
    if(clientGroups.length === 0) { // || clientGroups.length > 100
      that.setState({ clientGroupFilterItems: [] });
    } else {
      that.setState({ clientGroupFilterItems: clientGroups }, () => {
        var newClientGroupSelectedFilterItems = [];
        var oldClientGroupSelectedFilterItems = that.state.clientGroupSelectedFilterItems;

        that.state.clientGroupSelectedFilterItems.map(function(clientGroup) {
          if (that.state.clientGroupFilterItems.indexOf(clientGroup) === -1) {
            removeParameter("clientGroup", clientGroup);
            newClientGroupSelectedFilterItems = checkboxToggle(clientGroup, oldClientGroupSelectedFilterItems);
            oldClientGroupSelectedFilterItems = newClientGroupSelectedFilterItems;
          } else {
            if(newClientGroupSelectedFilterItems.indexOf(clientGroup) === -1) {
              newClientGroupSelectedFilterItems.push(clientGroup);
            }
          }
        });

        that.setState({ clientGroupSelectedFilterItems: newClientGroupSelectedFilterItems });
      });
    }
    
    if(!this.props.isAdmin || managers.length === 0) { // || managers.length > 100
      that.setState({ managerFilterItems: [] });
    } else {
      that.setState({ managerFilterItems: managers }, () => {
        var newManagerSelectedFilterItems = [];
        var oldManagerSelectedFilterItems = that.state.managerSelectedFilterItems;

        that.state.managerSelectedFilterItems.map(function(manager) {
          if (that.state.managerFilterItems.indexOf(manager) === -1) {
            removeParameter("manager", manager);
            newManagerSelectedFilterItems = checkboxToggle(manager, oldManagerSelectedFilterItems);
            oldManagerSelectedFilterItems = newManagerSelectedFilterItems;
          } else {
            if(newManagerSelectedFilterItems.indexOf(manager) === -1) {
              newManagerSelectedFilterItems.push(manager);
            }
          }
        });

        that.setState({ managerSelectedFilterItems: newManagerSelectedFilterItems });
      });
    }

  }

  init = () => {
    var params = getParams(window.location.href);

    var orderBy = params.orderBy === undefined ? 'category' : params.orderBy;
    var order = params.order === undefined ? 'asc' : params.order;
    var clientGroupSelectedFilterItems = params.clientGroup === undefined ? [] : params.clientGroup;
    var managerSelectedFilterItems = params.manager === undefined ? [] : params.manager;

    this.setState({ 
      orderBy: orderBy,
      order: order,
      clientGroupSelectedFilterItems: clientGroupSelectedFilterItems,
      managerSelectedFilterItems: managerSelectedFilterItems,
    }, () => { this.updateFilterItems(); this.getClientIds(); });

    // this.getTotalPricelistSize();
  }

	componentWillMount() {
      this.init();
	}
	
  handleSelectFilterClientGroup = (event, clientGroup) => {

    if (this.state.clientGroupSelectedFilterItems.indexOf(clientGroup) === -1) {
      addParameter("clientGroup", clientGroup);
    } else {
      removeParameter("clientGroup", clientGroup);
    }

    var newClientGroupSelectedFilterItems = checkboxToggle(clientGroup, this.state.clientGroupSelectedFilterItems);

    this.setState({ 
      clientGroupSelectedFilterItems: newClientGroupSelectedFilterItems,
      selectedFeatureFilters: {}
    }, () => this.getClientIds());
  }	
  
  handleSelectFilterManager = (event, manager) => {

    if (this.state.managerSelectedFilterItems.indexOf(manager) === -1) {
      addParameter("manager", manager);
    } else {
      removeParameter("manager", manager);
    }

    var newManagerSelectedFilterItems = checkboxToggle(manager, this.state.managerSelectedFilterItems);

    this.setState({ 
      managerSelectedFilterItems: newManagerSelectedFilterItems,
      selectedFeatureFilters: {}
    }, () => this.getClientIds());
  }	
	
  handleChangePage = (event, page) => {
    this.setState({ page : page }, () => this.getClients());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () => this.getClients());
  };

  handleRequestSort = (event, property) => {
  
    var s = property;
    
    if (s && s == 'status') {
    	s = 'externalStatusText';
    }
  
    const isDesc = this.state.sortBy === s && this.state.sortDirection === 'desc';
    this.setState({ sortBy: s, sortDirection: (isDesc ? 'asc' : 'desc') }, () => this.getClientIds());
  }

  componentDidMount = () => {
    // this.getClientIds();
  }

  getClientIds = () => {
    const { user, supplierId, isAdmin, edit } = this.props;

    var supplierClient = null;
    if (localStorage.getItem('supplierClient')) {
	    supplierClient = JSON.parse(localStorage.getItem('supplierClient'));
    }
    
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/client-ids';

    var params = 'key=' + constants.apiKey + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId
    				+ "&principalId=" + user.id;
    				
    				//+ (!isAdmin ? "&userId=" + user.id : "")
    				
    				
    var urlParams = (!isAdmin && supplierClient ? "&supplierClientId=" + supplierClient.id : "")
    				+ (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "")
    				+ "&sortBy=" + this.state.sortBy + "&sort=" + this.state.sortDirection;
	
	this.state.clientGroupSelectedFilterItems.map(function(clientGroupSelected) {
      urlParams += "&clientGroup=" + encodeURIComponent(clientGroupSelected);
    });
	this.state.managerSelectedFilterItems.map(function(managerSelected) {
      urlParams += "&manager=" + encodeURIComponent(managerSelected);
    });

	urlParams += '&search=' + encodeURIComponent(this.state.request);

    var that = this;

    var cb = function(data) { 
      that.setState({ clientIds: data.ids }, () => that.getClients());
    };

    this.props.handleProgressBarOpen();
    doAjax(url + '?' + params + urlParams, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
    
    if (document.getElementById("download-clients")) {
    
    	var clientsURL = (url.replace('/client-ids', '/' + JSON.parse(localStorage.getItem('supplierUser')).value.supplier.name + '/clients/xls'));
    
    	try {
    		clientsURL += '?hash=' + encodeURIComponent(btoa(unescape(encodeURIComponent(urlParams))));
    	} catch (e) {
    		console.error('outer', e.message);
    		// pricelistURL += '' + urlParams;
    	}
      	document.getElementById("download-clients").href=clientsURL;
      	document.getElementById("download-clients").target = "_blank";
    }
  }

  getClients = () => {
    const { clientIds, page, rowsPerPage } = this.state;
    
    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/clients?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    
	
    clientIds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(id => {
      url += "&ids=" + id;
    })

    var that = this;

    var cb = function(data) { 
      that.setState({ clients: data });
      that.setState({edit: ""});
    };

    this.props.handleProgressBarOpen();
    if (clientIds.length > 0) {
    	doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
    } else {
    	that.setState({ clients: [] });
    }
    
  }

  handleClientClick = (event, client) => {
  
  	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierClientId=" + client.id;

    var that = this;

    var cb = function(data) { 
      that.setState({ 
        clientItems: data,
        client: client,
        clientOpen: true
      });
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleClientClose = () => {
    this.setState({ clientOpen: false });
  }

  handleEditExceptions = item => {
     this.setState({
//      exceptionsLBOpen: true,
      exceptionsLBItem: item
    }, () => this.getClientRules(item));
 }
  handleEditDiscounts = item => {
     this.setState({
      discountsLBItem: item
    }, () => this.getClientDiscounts(item));
 }
   
  handleOpenAddresses = item => {
     this.setState({
      addressesLBItem: item
    }, () => this.getClientAddresses(item));
 }
  handleExceptionsLBClose = (event) => {
    this.setState({
      exceptionsLBOpen: false,
      exceptionsLBItem: null,
      clientRules: [],
      client: null,
      newRuleCategory: null,
      newRuleVendor: null,
      showExceptionsSearchField: false,
      filteredClientRules: null,
    });
  }
  handleDiscountsLBClose = (event) => {
    this.setState({
      discountsLBOpen: false,
      discountsLBItem: null,
      clientDiscounts: [],
      client: null,
      showDiscountsSearchField: false,
      filteredClientDiscounts: null,
    });
  }
    
    handleAddressesLBClose = (event) => {
    this.setState({
      addressesLBOpen: false,
      addressesLBItem: null,
      clientAddresses: [],
      client: null,
    });
  }
  getClientRules(item) {
  
	var user = JSON.parse(localStorage.getItem('user')).value;
	// if (this.props.item) 
	{
	  	var url = constants.apiBaseUrl + '/b2b/supplier/client/rules?key=' + constants.apiKey; 
         url += item ? '&supplierClientId=' + item.id : '';
         url += "&type=1&principalId=" + user.id + '&upd=' + new Date().getTime();
		 
	    var that = this;
	
	    var cb = function(data) { 
	      that.setState({ clientRules: data, exceptionsLBOpen: true });
	    };
	    this.props.handleProgressBarOpen();
	    
	    doAjax(url, cb, this.props.handleProgressBarClose);
	}  
  
  }
    getClientDiscounts(item) {
  
	     var user = JSON.parse(localStorage.getItem('user')).value;
	      {
	     	var url = constants.apiBaseUrl + '/b2b/supplier/client/rules?key=' + constants.apiKey; 
         url += item ? '&supplierClientId=' + item.id : '';
         url += "&type=2&principalId=" + user.id + '&upd=' + new Date().getTime();
		 
	    var that = this;

	    var cb = function(data) { 
	      that.setState({ clientDiscounts: data, discountsLBOpen: true });
	    };
	    this.props.handleProgressBarOpen();
	    
	    doAjax(url, cb, this.props.handleProgressBarClose);
	}  
  
  }
  
  getClientAddresses(item) {
  
  	var user = JSON.parse(localStorage.getItem('user')).value;
	// if (this.props.item) 
	{
	  	var url = constants.apiBaseUrl + '/b2b/supplier/client/addresses?key=' + constants.apiKey + '&upd=' + new Date().getTime() + '&supplierClientId='  + item.id; 


	    var that = this;
	
	    var cb = function(data) { 
	      that.setState({ clientAddresses: data, addressesLBOpen: true });
	    };
	    this.props.handleProgressBarOpen();
	    
	    doAjax(url, cb, this.props.handleProgressBarClose);
	}  
  }
  handleEditClick = item => {
    this.setState({
      editLBOpen: true,
      editLBItem: item
    }, () => this.getCard(item)); 
  }
  
    handleEditManClick = item => {
    
    if (this.state.selected.length <= 0) {
    	alert('Пожалуйста, отметьте контрагентов');
    	return;
    }
    
    this.setState({
      editManLBOpen: true,
      editManLBItem: item
    }, () => this.getCard(item)); 
  }

  handleDeleteClick = item => {
  
    const conf = window.confirm('Удалить контрагента?'); //{i18n.t('deleteUser?')}
    if (!conf) {
    	return;
    }
    
	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/delete-client?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierId=" + this.state.supplierId + "&principalId=" + user.id + "&supplierClientId=" + item.id;

    var that = this;
    var cb = function(data) {
      if (data) {
        //that.componentDidMount();
        that.getClientIds();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);    
    
  }
  handleEditLBClose = (event) => {
    this.setState({
      editLBOpen: false,
      editLBItem: null,
      card: null
    });
  }
  
    handleEditManLBClose = (event) => {
    this.setState({
      editManLBOpen: false,
      editManLBItem: null,
      card: null
    });
  }
  
  getCard = item => {
    if (this.state.editLBItem === null || this.state.editLBItem.id === undefined) {
      return;
    }

    var url = constants.apiBaseUrl + "/b2b/supplier/client?supplierClientId=" + item.id + "&key=" + constants.apiKey + '&upd=' + new Date().getTime();

    var that = this;
    var cb = function(data) {
      that.setState({
        card: data
      });
    };

    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }
  


  handleFilterClick = () => {
    this.setState({ showFilterSidebar: true });
  }

  handleFiltersClose = () => {
    this.setState({ showFilterSidebar: false });
  }

  handleFilterToggle = () => {
    this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
  };  
  
  handlePriceFilterChangeComplete = () => {

    this.getOrderIds();
  };
  
  handleSearchClick = (e) => {
	
	if(e.currentTarget.id == "discount-search-button") {
			this.setState({ showDiscountsSearchField: !this.state.showDiscountsSearchField });

	} else if (e.currentTarget.id == "exceptions-search-button") {
			this.setState({ showExceptionsSearchField: !this.state.showExceptionsSearchField });

	} else {
	       this.setState({ showSearchField: !this.state.showSearchField });
	}
};
	
  handleChangeSearch = (event) => {
	var searchRequest = event.target.value;
	this.setState({ request: searchRequest }, () => this.getClientIds());
};

	handleClientCheckboxClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
    
  };
  

  handleChangeSearchField = (event) => {
     var filteredClients = [];
     if(event.currentTarget.id == "discount-search") {
	        var clients = this.state.clientDiscounts;
      }
     if (event.currentTarget.id == "exceptions-search") {
	        var clients = this.state.clientRules;
      }
    clients.map(
        item => {for (var key in item) {
			    if (key =="discountPercent" || key == "vendor" || key =="category") {
                if(String(item[key]).toLowerCase().search(event.target.value.toLowerCase()) + 1 != 0) {
                  filteredClients.push(item);
                  return;}
			    } 
		    if (key == "supplierClient") { 
                if (item[key].name.toLowerCase().search(event.target.value.toLowerCase()) + 1 != 0) {
                 filteredClients.push(item);
                 return;}
			     }}
			  })
	    if (event.currentTarget.id == "discount-search") {
		        this.setState({ filteredClientDiscounts: filteredClients })
	    }
	    if (event.currentTarget.id == "exceptions-search") {
		        this.setState({ filteredClientRules: filteredClients })
	    }

};

  selectAllFilterItems = (filterName, items) => {

    var selectedListName = filterName + 'SelectedFilterItems';

    removeParameter(filterName, "[^&]*");

    var that = this;

    if (items.length > 0) {
      items.map(function(item) {
        addParameter(filterName, item);
      });
    }
    
    this.setState({ 
      [selectedListName]: items
    }, () => this.getOrderIds());
  }
  
    handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.state.clientIds.map(clientId => clientId) }));
      return;
    }
    this.setState({ selected: [] });
  };
  
   isSelected = id => this.state.selected.indexOf(id) !== -1;
   

  render() {
    const { onSelectAllClick, classes, i18n, isAdmin, isManager, isClientAdmin, numSelected, rowCount } = this.props;
    const { rowsPerPage, page, clients, sortDirection, sortBy, clientIds, selected } = this.state;
    const modalStyle = getModalStyle();


    if (!isAdmin && !isManager) {
      return <Redirect to='/pricelist'/>
    }

 
    return (
      <div>
      
      <ManagerEditLightbox 
          open={this.state.editManLBOpen} 
          selected={this.state.selected}
          i18n={i18n} 
          classes={classes} 
          item={this.state.editManLBItem} 
          handleClose={this.handleEditManLBClose} 
          updateTableData={this.getClientIds}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          handleShowSnackbar={this.props.handleShowSnackbar}
          removeCard={this.removeCard}
          setCard={this.setCard}
          card={this.state.card}
          isAdmin={this.props.isAdmin}
          isManager={this.props.isManager}
          
        />
      
        <EditLightbox 
          open={this.state.editLBOpen} 
          i18n={i18n} 
          classes={classes} 
          item={this.state.editLBItem} 
          handleClose={this.handleEditLBClose} 
          updateTableData={this.getClientIds}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          handleShowSnackbar={this.props.handleShowSnackbar}
          removeCard={this.removeCard}
          setCard={this.setCard}
          card={this.state.card}
          isAdmin={this.props.isAdmin}
          isManager={this.props.isManager}
        />
        

        <ExceptionsLightbox 
          open={this.state.exceptionsLBOpen} 
          i18n={i18n} 
          classes={classes} 
          client={this.state.exceptionsLBItem} 
          handleClose={this.handleExceptionsLBClose} 
          updateTableData={this.componentWillMount}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          clientRules={this.state.clientRules}
          handleSearchClick={this.handleSearchClick} 
          showExceptionsSearchField={this.state.showExceptionsSearchField}  
          handleChangeSearch={this.handleChangeSearchField}
          filteredClientRules={this.state.filteredClientRules}
		  isAdmin={this.props.isAdmin}
          isManager={this.props.isManager}
        />
         <DiscountsLightbox 
          open={this.state.discountsLBOpen} 
          i18n={i18n} 
          classes={classes} 
          client={this.state.discountsLBItem} 
          handleClose={this.handleDiscountsLBClose} 
          updateTableData={this.componentWillMount}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          clientDiscounts={this.state.clientDiscounts}
          filteredClientDiscounts={this.state.filteredClientDiscounts}
          handleSearchClick={this.handleSearchClick} 
          showDiscountsSearchField={this.state.showDiscountsSearchField}  
          handleChangeSearch={this.handleChangeSearchField}
          isAdmin={this.props.isAdmin}
          isManager={this.props.isManager}
        />
        
        <AddressesLightbox 
          open={this.state.addressesLBOpen} 
          i18n={i18n} 
          classes={classes} 
          client={this.state.addressesLBItem} 
          handleClose={this.handleAddressesLBClose} 
          updateTableData={this.componentWillMount}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          clientAddresses={this.state.clientAddresses}
        />

		<ClickAwayListener onClickAway={this.handleFiltersClose}>
          <FilterSidebar
            handleFilterToggle={this.handleFilterToggle}
            open={this.state.showFilterSidebar}
            i18n={i18n}
            clientGroupFilterItems={this.state.clientGroupFilterItems}
            clientGroupSelectedFilterItems={this.state.clientGroupSelectedFilterItems}
            managerFilterItems={this.state.managerFilterItems}
            managerSelectedFilterItems={this.state.managerSelectedFilterItems}
            handleSelectFilterClientGroup={this.handleSelectFilterClientGroup}
            handleSelectFilterManager={this.handleSelectFilterManager}
            handlePriceFilterChangeComplete={this.handlePriceFilterChangeComplete}
            selectAllFilterItems={this.selectAllFilterItems}
            isAdmin={isAdmin}
            isClientAdmin={isClientAdmin}
           />
        </ClickAwayListener> 
                
        <Paper className={classes.root}>
            <EnhancedTableToolbar 
               i18n={i18n} 
               isAdmin={isAdmin} 
               isManager={isManager} 
               addClick={this.handleEditClick}
               addManClick={this.handleEditManClick}
               handleFilterClick={this.handleFilterClick}  
               handleEditExceptions={this.handleEditExceptions}
               handleEditDiscounts={this.handleEditDiscounts} 
               handleSearchClick={this.handleSearchClick} 
               showSearchField={this.state.showSearchField}  
               handleChangeSearch={this.handleChangeSearch}
               handleAppointManager={this.handleAppointManager}
               />
           
            <div className={classes.tableWrapper}>
              <Table className={classNames(classes.table, "fixed-header-table")} size={'small'}>
                <EnhancedTableHead
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  onRequestSort={this.handleRequestSort}
                  onSelectAllClick={this.handleSelectAllClick}
                  i18n={i18n}
                  classes={classes}
                  isAdmin={isAdmin}
                  isManager={isManager}
                  rowCount={clients.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {clients
                    .map(client => { 
						const isSelected = this.isSelected(client.id);
                      return (
                        <TableRow hover
                        component="td" 
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={client.id}
                        selected={isSelected}
                        >
                        {isAdmin &&
                        <TableCell align="center" className={classes.checkBoxCell} style={{padding: "0"}}>
                          <Checkbox id="edit-lb-check"
                            checked={isSelected}
                            className={classes.checkBox}
                            onClick={event => this.handleClientCheckboxClick(event, client.id)}
                          />
                        </TableCell>
                        }
                          <TableCell>
                            <span className={classNames(classes.hoverUnderline)} style={{cursor: "pointer", "&:hover": {textDecoration: "underline"}}}
                            	  onClick={event => this.handleClientClick(event, client)}>
                            	  {client.externalId ? client.externalId : client.id}
                            </span>
                          </TableCell>
                          <TableCell>
                            {client.name}
                          </TableCell>
                          <TableCell align="right">
                          	{client.unp}
                          </TableCell>
                          
                          {getSettingValueAsBoolean('CLIENTS_SHOW_GROUP', false) &&
                          <TableCell align="left">
                          	{client.supplierClientGroup && client.supplierClientGroup.name}
                          </TableCell>
                          }
                          {isAdmin &&
                          <TableCell align="left">
                          	{client.supplierManagerUser ? client.supplierManagerUser.user.lastName : ""} {client.supplierManagerUser ? client.supplierManagerUser.user.firstName : ""}
                          </TableCell>
                          }
                          <TableCell align="right">
                          	{client.ordersCount}
                          </TableCell>
                          <TableCell align="right">
                          	{client.usersCount}
                          </TableCell>
						                          <TableCell align="right">
                          	{client.isBlocked == 1 && <Check style={{color: "red", marginLeft: "0px", verticalAlign: "middle"}} />}
                          </TableCell>
                          <TableCell align="right">
                          	{client.lastActionDate ?
                          	<Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">
                              {client.lastActionDate}
                            </Moment>
                            : ""}
                          </TableCell>
                          <TableCell align="right" padding="none">
                            <Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">
                              {client.dateAdd}
                            </Moment>
                          </TableCell>
                          
                         { (isAdmin || isManager) &&
                          	<TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                        > 
	                            <Button 
	                              onClick={ event => this.handleEditDiscounts(client) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
                                   %
                                </Button>
	                        </TableCell>
	                        }
                          	
                          	{ (isAdmin || isManager) &&
                          	<TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                        > 
	                            <Button 
	                              onClick={ event => this.handleOpenAddresses(client) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <LocalShippingIcon className={classes.editIcon}/>
	                            </Button>
	                        </TableCell>
	                        }
                          	{ (isAdmin || isManager) &&
                          	<TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                        > 
	                            <Button 
	                              onClick={ event => this.handleEditExceptions(client) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <RemoveCircleOutline className={classes.editIcon}/>
	                            </Button>
	                          
	                        </TableCell>
	                        
	                        }
	                        
	                        { (isAdmin || isManager) &&
                          	
	                        <TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                        > 
	                            <Button 
	                              onClick={ event => this.handleEditClick(client) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <Create className={classes.editIcon}/>
	                            </Button>
	                          
	                        </TableCell>
	                        
	                        }
	                        
	                        { (isAdmin || isManager) &&
	                        
	                        <TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                        > 
	                            <Button 
	                              onClick={ event => this.handleDeleteClick(client) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <Close className={classes.editIcon}/>
	                            </Button>
	                          
	                        </TableCell>
	                       
	                        }
	                        
	                        <TableCell 
		                        align="left"
		                        padding="none"
		                        className={classes.rightCell}
		                      >
		                    </TableCell>
                        </TableRow>
                    )})}
                </TableBody>
              </Table>
            </div>
            <div className={classes.tableFooter}>
              <div className={classes.paginationWrapper}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={clientIds.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  labelRowsPerPage={i18n.t('rows_per_page')}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
                />
              </div>
            </div>
        </Paper>
      </div>
    );
  }
}

Clients.propTypes = {
  classes: PropTypes.object.isRequired,
  edit: PropTypes.string.isRequired,
  handleEditOpen: PropTypes.func.isRequired,
  handleEditClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Clients);
