import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Pricelist from "components/Table/Pricelist.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from '@material-ui/core/Button';

import { NavLink } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost, addParameter, removeParameter, getParams, checkboxToggle, getSettingValue, getSettingValueAsBoolean } from "variables/common.js";

import Moment from 'react-moment';

import hist from "../../history";

const styles = theme => ({
  modelFilter: {
     width: "-webkit-fill-available",
    "& > div": {
      marginTop: "0"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 15px !important",
      float: "none !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      padding: "0!important"
    }
  },
  modelFilterWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0"
    },
    width: "-webkit-fill-available",
    display: "inline-block",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "-20px"
  }
});

function createData(id, model, code, externalId, category, producer, status, quantity, price, priceRecommended, targetPortalItemId, currencyEnum, country, markup, availabilityDate, warrantyText, warehouses, warehousesText, clientGroupPriceRows, clientPriceRows, multiplicity, isReceiptCurrencyDifferent, modelUrl, priceRetail) {
	return { id: id, model, code, externalId, category, producer, status, quantity, price, priceRecommended, targetPortalItemId, currencyEnum, country, markup, availabilityDate, warrantyText, warehouses, warehousesText, clientGroupPriceRows, clientPriceRows, multiplicity, isReceiptCurrencyDifferent, modelUrl, priceRetail };
}

class PricelistContainer extends React.Component {

	constructor(props) {
    	super(props);
    	this.state = {
    		pricelist: [],
    		pricelistIds: [],
    		page: 0,
    		rowsPerPage: 50,
    		edit: '',
    		editedValue: '',
	        suggestions: [],
	        totalPricelistSize: 0,
	        modelFilterValue: '',
	        orderBy: 'category', 
	        order: 'asc',
	
	        categoryFilterItems: [],
	        producerFilterItems: [],
	        warehouseFilterItems: [],
	
	        categorySelectedFilterItems: [],
	        producerSelectedFilterItems: [],
	        warehouseSelectedFilterItems: [],
	        statusSelectedFilterItems: [],
	        minPriceSelected: null,
	        maxPriceSelected: null,
	        minMarkupSelected: null,
	        maxMarkupSelected: null,
	        maxAvailabilityDaysSelected: null,
	        minAvailabilityDaysSelected: null, 
	
	        pricelistIdsFav: [],
	        pricelistFav: [],
	
	        pageFav: 0,
	        rowsPerPageFav: 50,
	
	        featureFilters: {},
	        selectedFeatureFilters: {},
	        
	        isReceiptCurrencyDifferentSelectedFilterItems: [],
	        
	        pricelistUpdateStart: null,
	        pricelistUpdateFinish: null
	    };
 	}

 	makePricelist = data => {
 		var rows = []
 		data.map(function(row) {
  			rows.push(createData(row.id, row.model, row.code, row.externalId, row.category, row.producer, row.status, row.quantity, row.price, row.priceRecommended, row.targetPortalItemId, row.currencyEnum, row.country, row.markup, row.availabilityDate, row.warrantyText, row.warehouses, row.warehousesText, row.clientGroupPriceRows, row.clientPriceRows, row.multiplicity, row.isReceiptCurrencyDifferent, row.modelUrl, row.priceRetail));
		});
		this.setState({ pricelist: rows });
 	};

 	getPricelist = dataIds => {
 	
 		var user = JSON.parse(localStorage.getItem('user')).value;

	  	var url = constants.apiBaseUrl + '/b2b/supplier/pricelist?key=' + constants.apiKey + '&supplierUserId=' + this.props.supplierUserId + '&supplierClientId=' + (this.props.supplierClientId ? this.props.supplierClientId : '')+ "&principalId=" + user.id + '&upd=' + new Date().getTime();
	
	  	dataIds.map(function(id) {
	  		url += "&ids=" + id;
	  	});

		var whFilterType = getSettingValue("PRICELIST_FILTER_WAREHOUSE_TYPE", 1);
		if (whFilterType == 2) {
		    this.state.warehouseSelectedFilterItems.map(function(warehouseSelected) {
		    	url += "&warehouse=" + warehouseSelected;
		    });
	    }
	
	    var that = this;
	
	    var cb = function(data) { that.setState({edit: ''}); that.makePricelist(data);};		
	    this.props.handleProgressBarOpen();
	    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
	    
 	}

  updateFilterItems = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/pricelist/ids?key=' + constants.apiKey + '&action=filter&supplierId=' + this.props.supplierId + '&supplierUserId=' + this.props.supplierUserId +
    			 '&supplierClientId=' + (this.props.supplierClientId ? this.props.supplierClientId : '') + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&orderBy=" + this.state.orderBy +
    			 "&order=" + this.state.order;
    if (this.state.modelFilterValue && this.state.modelFilterValue != '%') {		 
    	url += "&model=" + encodeURIComponent(this.state.modelFromLocalStorage);
    }

    if (this.props.fav) {
      this.props.favorites.map(function(id) {
        url += "&favId=" + id;
      });
      
      
    } else {
    	if (this.state.statusSelectedFilterItems) {
    		this.state.statusSelectedFilterItems.map(function(statusSelected) {
      			url += "&status=" + statusSelected;
    		});
    	}
    }

    var that = this;
    
    var cb = function(data) { 
      that.makeFilterItems(that, data.categoryFilterItems, data.producerFilterItems, data.warehouseFilterItems, data.minPrice, data.maxPrice, data.minAvailabilityDays, data.maxAvailabilityDays, data.isReceiptCurrencyDifferentFilterItems, data.minMarkup, data.maxMarkup);
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  getFilteredPricelistIds = () => {

    console.log("getFilteredPricelistIds");
    
    var user = JSON.parse(localStorage.getItem('user')).value;

 	var url = constants.apiBaseUrl + '/b2b/supplier/pricelist/ids'
 	
 	var params = 'key=' + constants.apiKey + '&action=filteredIds&supplierId=' + this.props.supplierId + '&supplierUserId=' + this.props.supplierUserId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
 	
 	var urlParams = '&supplierClientId=' + (this.props.supplierClientId ? this.props.supplierClientId : '') + "&orderBy=" + 
 					this.state.orderBy + "&order=" + this.state.order;
 					
	if (this.state.modelFilterValue && this.state.modelFilterValue != '%') {
		urlParams += "&model=" + encodeURIComponent(this.state.modelFromLocalStorage);
	}

    this.state.categorySelectedFilterItems.map(function(categorySelected) {
      urlParams += "&category=" + categorySelected;
    });

    this.state.producerSelectedFilterItems.map(function(producerSelected) {
      urlParams += "&producer=" + producerSelected;
    });

    this.state.statusSelectedFilterItems.map(function(statusSelected) {
      urlParams += "&status=" + statusSelected;
    });

    this.state.warehouseSelectedFilterItems.map(function(warehouseSelected) {
      urlParams += "&warehouse=" + warehouseSelected;
    });

    if (this.state.minPriceSelected !== null && (this.state.minPriceSelected !== this.state.minPrice || this.state.maxPriceSelected !== this.state.maxPrice)) {
      urlParams += "&minPrice=" + this.state.minPriceSelected;
    }

    if (this.state.maxPriceSelected !== null && (this.state.minPriceSelected !== this.state.minPrice || this.state.maxPriceSelected !== this.state.maxPrice)) {
      urlParams += "&maxPrice=" + this.state.maxPriceSelected;
    }

    if (this.state.minMarkupSelected !== null && (this.state.minMarkupSelected !== this.state.minMarkup || this.state.maxMarkupSelected !== this.state.maxMarkup)) {
      urlParams += "&minMarkup=" + this.state.minMarkupSelected;
    }

    if (this.state.maxMarkupSelected !== null && (this.state.minMarkupSelected !== this.state.minMarkup || this.state.maxMarkupSelected !== this.state.maxMarkup)) {
      urlParams += "&maxMarkup=" + this.state.maxMarkupSelected;
    }

    if (this.state.minAvailabilityDaysSelected !== null && (this.state.minAvailabilityDaysSelected !== this.state.minAvailabilityDays || this.state.maxAvailabilityDaysSelected !== this.state.maxAvailabilityDays)) {
      urlParams += "&minAvailabilityDays=" + this.state.minAvailabilityDaysSelected;
    }

    if (this.state.maxAvailabilityDaysSelected !== null && (this.state.minAvailabilityDaysSelected !== this.state.minAvailabilityDays || this.state.maxAvailabilityDaysSelected !== this.state.maxAvailabilityDays)) {
      urlParams += "&maxAvailabilityDays=" + this.state.maxAvailabilityDaysSelected;
    }
    
    if (this.state.isReceiptCurrencyDifferentSelectedFilterItems !== null) {
      this.state.isReceiptCurrencyDifferentSelectedFilterItems.map(function(isReceiptCurrencyDifferentSelected) {
        urlParams += "&isReceiptCurrencyDifferent=" + isReceiptCurrencyDifferentSelected;
      });
    }
    
    
    Object.keys(this.state.selectedFeatureFilters).map(featureName =>{
      this.state.selectedFeatureFilters[featureName].map(featureValue =>{
         urlParams += "&" + featureName + "=" + featureValue;
      });
    });

    var that = this;

    var cb = function(data) {
      if (data.featureFilters !== undefined) {
        that.setState({ featureFilters: data.featureFilters });
      } else {
        that.setState({ featureFilters: {} });
      }

      var ids = [];

      if (that.props.fav) {
        var filteredIdsFav = [];
        data.ids.map(id => {
          if (that.props.favorites.indexOf(id) !== -1) {
            ids.push(id);
          }
        });
      } else {
        ids = data.ids;
      }

      that.setState({ pricelistIds: ids }, () => that.getPricelist(that.state.pricelistIds.slice(0, that.state.rowsPerPage)));
      
      // document.getElementById("pricelist-update-date").innerHTML=data.pricelistUpdateFinish;
      that.setState({ pricelistUpdateStart: data.pricelistUpdateStart });
      that.setState({ pricelistUpdateFinish: data.pricelistUpdateFinish });
      
      
    };

    this.props.handleProgressBarOpen();
    doAjax(url + '?' + params + urlParams, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    
    if (this.props.fav) {

        if (document.getElementById("download-pricelist")) {
        
        	var favoriteIdsParam = '';
        	var fav = this.props.favorites;
        	if (this.state.favorites) {
        	//	fav = this.state.favorites;
        	}
        	// this.props.favorites
 			fav.map(function(id) {
        		favoriteIdsParam += "&favId=" + id;
      		});        	
	 	 	var urlParams = '&supplierClientId=' + (this.props.supplierClientId ? this.props.supplierClientId : '') + "&orderBy=" + 
		 					this.state.orderBy + "&order=" + this.state.order + favoriteIdsParam;
	    	
	    	var pricelistURL = (constants.apiBaseUrl + '/b2b/supplier/' + JSON.parse(localStorage.getItem('supplierUser')).value.supplier.name + '/pricelist/xls');
	    	
	    	try {
	    		pricelistURL += '?hash=' + encodeURIComponent(btoa(unescape(encodeURIComponent(urlParams))));
	    	} catch (e) {
	    		console.error('outer', e.message);
	    		// pricelistURL += '' + urlParams;
	    	}
	    
	    	document.getElementById("download-pricelist").href=pricelistURL;
	    	document.getElementById("download-pricelist").target = "_blank";
	    }
    	
    } else {
	    if (document.getElementById("download-pricelist")) {
	    	
	    	var pricelistURL = (url.replace('/pricelist/ids', '/' + JSON.parse(localStorage.getItem('supplierUser')).value.supplier.name + '/pricelist/xls'));
	    	
	    	try {
	    		pricelistURL += '?hash=' + encodeURIComponent(btoa(unescape(encodeURIComponent(urlParams))));
	    	} catch (e) {
	    		console.error('outer', e.message);
	    		// pricelistURL += '' + urlParams;
	    	}
	    
	    	document.getElementById("download-pricelist").href=pricelistURL;
	    	document.getElementById("download-pricelist").target = "_blank";
	    }
    }
 }

  makeFilterItems = (that, categories, producers, warehouses, minPrice, maxPrice, minAvailabilityDays, maxAvailabilityDays, isReceiptCurrencyDifferent, minMarkup, maxMarkup) => {

    that.setState({ minPrice: minPrice <= 0 ? 0 : Math.floor(minPrice)});
    that.setState({ maxPrice: Math.ceil(maxPrice)});

    if(maxPrice !== 0) {

      if (that.state.minPriceSelected !== null && that.state.minPriceSelected < minPrice) { 
        removeParameter("minPrice", that.state.minPriceSelected);
        that.setState({ minPriceSelected: minPrice });
        addParameter("minPrice", minPrice);
      } else if (that.state.minPriceSelected === null) {
        that.setState({ minPriceSelected: that.state.minPrice });
      }

      if (that.state.maxPriceSelected !== null && that.state.maxPriceSelected > maxPrice) { 
        removeParameter("maxPrice", that.state.maxPriceSelected);
        that.setState({ maxPriceSelected: maxPrice });
        addParameter("maxPrice", maxPrice);
      } else if (that.state.maxPriceSelected === null) {
        that.setState({ maxPriceSelected: that.state.maxPrice });
      }
    }
    
    that.setState({ minMarkup: minMarkup <= 0 ? 0 : Math.floor(minMarkup)});
    that.setState({ maxMarkup: Math.ceil(maxMarkup)});

    if(maxMarkup !== 0) {

      if (that.state.minMarkupSelected !== null && that.state.minMarkupSelected < minMarkup) { 
        removeParameter("minMarkup", that.state.minMarkupSelected);
        that.setState({ minMarkupSelected: minMarkup });
        addParameter("minMarkup", minMarkup);
      } else if (that.state.minMarkupSelected === null) {
        that.setState({ minMarkupSelected: that.state.minMarkup });
      }

      if (that.state.maxMarkupSelected !== null && that.state.maxMarkupSelected > maxMarkup) { 
        removeParameter("maxMarkup", that.state.maxMarkupSelected);
        that.setState({ maxMarkupSelected: maxMarkup });
        addParameter("maxMarkup", maxMarkup);
      } else if (that.state.maxMarkupSelected === null) {
        that.setState({ maxMarkupSelected: that.state.maxMarkup });
      }
    }

    if(categories.length === 0) { // || categories.length > 100
      that.setState({ categoryFilterItems: [] });
    } else {
      that.setState({ categoryFilterItems: categories }, () => {
        var newCategorySelectedFilterItems = [];
        var oldCategorySelectedFilterItems = that.state.categorySelectedFilterItems;

        that.state.categorySelectedFilterItems.map(function(category) {
          if (that.state.categoryFilterItems.indexOf(category) === -1) {
            removeParameter("category", category);
            newCategorySelectedFilterItems = checkboxToggle(category, oldCategorySelectedFilterItems);
            oldCategorySelectedFilterItems = newCategorySelectedFilterItems;
          } else {
            if(newCategorySelectedFilterItems.indexOf(category) === -1) {
              newCategorySelectedFilterItems.push(category);
            }
          }
        });

        that.setState({ categorySelectedFilterItems: newCategorySelectedFilterItems });
      });
    }

    if(producers.length === 0) { // || producers.length > 50
      that.setState({ producerFilterItems: [] });
    } else {
      that.setState({ producerFilterItems: producers }, () => {
        var newProducerSelectedFilterItems = [];
        var oldProducerSelectedFilterItems = that.state.producerSelectedFilterItems;

        that.state.producerSelectedFilterItems.map(function(producer) {
          if (that.state.producerFilterItems.indexOf(producer) === -1) {
            that.removeParameter("producer", producer);
            newProducerSelectedFilterItems = checkboxToggle(producer, oldProducerSelectedFilterItems);
            oldProducerSelectedFilterItems = newProducerSelectedFilterItems;
          } else {
            newProducerSelectedFilterItems.push(producer);          
          }
        });

        that.setState({ producerSelectedFilterItems: newProducerSelectedFilterItems });
      });
    }
    
    if(warehouses.length === 0) { // || warehouses.length > 50
      that.setState({ warehouseFilterItems: [] });
    } else {
      that.setState({ warehouseFilterItems: warehouses }, () => {
        var newWarehouseSelectedFilterItems = [];
        var oldWarehouseSelectedFilterItems = that.state.warehouseSelectedFilterItems;

        that.state.warehouseSelectedFilterItems.map(function(warehouse) {
          if (that.state.warehouseFilterItems.indexOf(warehouse) === -1) {
            that.removeParameter("warehouse", warehouse);
            newWarehouseSelectedFilterItems = checkboxToggle(warehouse, oldWarehouseSelectedFilterItems);
            oldWarehouseSelectedFilterItems = newWarehouseSelectedFilterItems;
          } else {
            newWarehouseSelectedFilterItems.push(warehouse);          
          }
        });

        that.setState({ warehouseSelectedFilterItems: newWarehouseSelectedFilterItems });
      });
    }

    that.setState({ minAvailabilityDays: minAvailabilityDays});
    that.setState({ maxAvailabilityDays: maxAvailabilityDays});

    if(maxAvailabilityDays !== 0) {

      if (that.state.minAvailabilityDaysSelected !== null && that.state.minAvailabilityDaysSelected < minAvailabilityDays) { 
        removeParameter("minAvailabilityDays", that.state.minAvailabilityDaysSelected);
        that.setState({ minAvailabilityDaysSelected: minAvailabilityDays });
        addParameter("minAvailabilityDays", minAvailabilityDays);
      } else if (that.state.minAvailabilityDaysSelected === null) {
        that.setState({ minAvailabilityDaysSelected: that.state.minAvailabilityDays });
      }

      if (that.state.maxAvailabilityDaysSelected !== null && that.state.maxAvailabilityDaysSelected > maxAvailabilityDays) { 
        removeParameter("maxAvailabilityDays", that.state.maxAvailabilityDaysSelected);
        that.setState({ maxAvailabilityDaysSelected: maxAvailabilityDays });
        addParameter("maxAvailabilityDays", maxAvailabilityDays);
      } else if (that.state.maxAvailabilityDaysSelected === null) {
        that.setState({ maxAvailabilityDaysSelected: that.state.maxAvailabilityDays });
      }
    }
    
    if (false) { // ?
    if(isReceiptCurrencyDifferent.length === 0) { // || isReceiptCurrencyDifferent.length > 50
      that.setState({ isReceiptCurrencyDifferentFilterItems: [] });
    } else {
      that.setState({ isReceiptCurrencyDifferentFilterItems: isReceiptCurrencyDifferent }, () => {
        var newIsReceiptCurrencyDifferentSelectedFilterItems = [];
        var oldIsReceiptCurrencyDifferentSelectedFilterItems = that.state.isReceiptCurrencyDifferentSelectedFilterItems;

        that.state.isReceiptCurrencyDifferentSelectedFilterItems.map(function(isReceiptCurrencyDifferent) {
          if (that.state.isReceiptCurrencyDifferentFilterItems.indexOf(isReceiptCurrencyDifferent) === -1) {
            removeParameter("isReceiptCurrencyDifferent", isReceiptCurrencyDifferent);
            newIsReceiptCurrencyDifferentSelectedFilterItems = checkboxToggle(isReceiptCurrencyDifferent, oldIsReceiptCurrencyDifferentSelectedFilterItems);
            oldIsReceiptCurrencyDifferentSelectedFilterItems = newIsReceiptCurrencyDifferentSelectedFilterItems;
          } else {
            newIsReceiptCurrencyDifferentSelectedFilterItems.push(isReceiptCurrencyDifferent);          
          }
        });

        that.setState({ isReceiptCurrencyDifferentSelectedFilterItems: newIsReceiptCurrencyDifferentSelectedFilterItems });
      });
    }
    }
  }


  handleChangePage = (event, page) => {
    this.setState({ page : page });
    this.getPricelist(this.state.pricelistIds.slice(page * this.state.rowsPerPage, page * this.state.rowsPerPage + this.state.rowsPerPage));
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.getPricelist(this.state.pricelistIds.slice(this.state.page * event.target.value, this.state.page * event.target.value + event.target.value));
  };

	handleEditOpen = (event, id, fieldName, fieldValue) => {

	    if (fieldName === "status") {
	        this.setState({ editedValue: fieldValue });
	    }
	    if (document.getElementById(fieldName + '-' + id)) {
			this.setState({ edit: fieldName + '-' + id }, () => document.getElementById(fieldName + '-' + id).value = (fieldValue ? fieldValue : ''));
		}
	}

	handleEditClose = event => {

    if (event.key === "Enter" || event.type === "blur" || event.type === "click") {

      var priceRowAssociationId = this.state.edit.split('-')[1];
      var fieldName = this.state.edit.split('-')[0];
      var fieldValue = event.target.value;

  		var url = constants.apiBaseUrl + '/b2b/supplier/pricelist/update?key=' + constants.apiKey + '&upd=' + new Date().getTime();

  		var body = "priceRowAssociationId=" + priceRowAssociationId + "&" + fieldName + "=" + fieldValue;

      this.props.handleProgressBarOpen();
      doAjaxPost(url, body, () => this.getFilteredPricelistIds(), this.props.handleProgressBarClose, this.props.handleShowSnackbar);

      this.setState({edit: ""});
    }
	};

  handleModelFilterEnter = event => {
    if (event.key === "Enter") {

      this.setState({ modelFilterValue: event.target.value }, () => {
        removeParameter("model", this.state.modelFilterValue);
        
        if (this.state.modelFilterValue && this.state.modelFilterValue != '%') {
        	addParameter("model", this.state.modelFilterValue);
        }

        this.updateFilterItems(); 
        this.getFilteredPricelistIds();
      });
    }
  };

  handlePriceFilterChangeComplete = (minPrice, maxPrice) => {

    removeParameter("minPrice", this.state.minPriceSelected);
    addParameter("minPrice", minPrice);
    removeParameter("maxPrice", this.state.maxPriceSelected);
    addParameter("maxPrice", maxPrice);

    this.setState({ minPriceSelected: minPrice, maxPriceSelected: maxPrice },  () => this.getFilteredPricelistIds());
  };
  
  handleMarkupFilterChangeComplete = (minMarkup, maxMarkup) => {

    removeParameter("minMarkup", this.state.minMarkupSelected);
    addParameter("minMarkup", minMarkup);
    removeParameter("maxMarkup", this.state.maxMarkupSelected);
    addParameter("maxMarkup", maxMarkup);

    this.setState({ minMarkupSelected: minMarkup, maxMarkupSelected: maxMarkup },  () => this.getFilteredPricelistIds());
  };

  handleAvailabilityDaysFilterChangeComplete = (minAvailabilityDays, maxAvailabilityDays) => {

    removeParameter("minAvailabilityDays", this.state.minAvailabilityDaysSelected);
    addParameter("minAvailabilityDays", minAvailabilityDays);
    removeParameter("maxAvailabilityDays", this.state.maxAvailabilityDaysSelected);
    addParameter("maxAvailabilityDays", maxAvailabilityDays);

    this.setState({ minAvailabilityDaysSelected: minAvailabilityDays, maxAvailabilityDaysSelected: maxAvailabilityDays },  () => this.getFilteredPricelistIds());
  };

  handleFeatureFilterChangeComplete = (min, max, filterName) => {

    removeParameter("from" + filterName, "[^&]*");
    addParameter("from" + filterName, min);
    removeParameter("to" + filterName, "[^&]*");
    addParameter("to" + filterName, max);

    var selectedFeatureFilters = this.state.selectedFeatureFilters;
    selectedFeatureFilters["from" + filterName] = [min];
    selectedFeatureFilters["to" + filterName] = [max];

    this.setState({ selectedFeatureFilters: selectedFeatureFilters },  () => this.getFilteredPricelistIds());
  };

  sort = (orderBy, order) => {

    removeParameter("orderBy", this.state.orderBy);
    addParameter("orderBy", orderBy);
    removeParameter("order", this.state.order);
    addParameter("order", order);

    this.setState({ 
      orderBy: orderBy,
      order: order 
    }, () => this.getFilteredPricelistIds());
  }

  handleSelectFilterCategory = (event, category) => {

    if (this.state.categorySelectedFilterItems.indexOf(category) === -1) {
      addParameter("category", category);
    } else {
      removeParameter("category", category);
    }

    Object.keys(this.state.selectedFeatureFilters).map(featureFilter => {
      removeParameter(featureFilter, "[^&]*");
    });

    var newCategorySelectedFilterItems = checkboxToggle(category, this.state.categorySelectedFilterItems);

    this.setState({ 
      categorySelectedFilterItems: newCategorySelectedFilterItems,
      selectedFeatureFilters: {}
    }, () => this.getFilteredPricelistIds());
  }

  handleSelectFilterProducer = (event, producer) => {

    if (this.state.producerSelectedFilterItems.indexOf(producer) === -1) {
      addParameter("producer", producer);
    } else {
      removeParameter("producer", producer);
    }
    
    var newProducerSelectedFilterItems = checkboxToggle(producer, this.state.producerSelectedFilterItems);

    this.setState({ producerSelectedFilterItems: newProducerSelectedFilterItems },  () => this.getFilteredPricelistIds());
  }
  
  handleSelectFilterWarehouse = (event, warehouse) => {

    if (this.state.warehouseSelectedFilterItems.indexOf(warehouse) === -1) {
      addParameter("warehouse", warehouse);
    } else {
      removeParameter("warehouse", warehouse);
    }
    
    var newWarehouseSelectedFilterItems = checkboxToggle(warehouse, this.state.warehouseSelectedFilterItems);

    this.setState({ warehouseSelectedFilterItems: newWarehouseSelectedFilterItems },  () => this.getFilteredPricelistIds());
  }

  handleSelectFilterIsReceiptCurrencyDifferent = (event, isReceiptCurrencyDifferent) => {

    if (this.state.isReceiptCurrencyDifferentSelectedFilterItems.indexOf(isReceiptCurrencyDifferent) === -1) {
      addParameter("isReceiptCurrencyDifferent", isReceiptCurrencyDifferent);
    } else {
      removeParameter("isReceiptCurrencyDifferent", isReceiptCurrencyDifferent);
    }
    
    var newIsReceiptCurrencyDifferentSelectedFilterItems = checkboxToggle(isReceiptCurrencyDifferent, this.state.isReceiptCurrencyDifferentSelectedFilterItems);

    this.setState({ isReceiptCurrencyDifferentSelectedFilterItems: newIsReceiptCurrencyDifferentSelectedFilterItems },  () => this.getFilteredPricelistIds());
  }

  handleSelectFilterStatus = (event, status) => {

    if (this.state.statusSelectedFilterItems.indexOf(status) === -1) {
      addParameter("status", status);
    } else {
      removeParameter("status", status);
    }
    
    var newStatusSelectedFilterItems = checkboxToggle(status, this.state.statusSelectedFilterItems);

    this.setState({ statusSelectedFilterItems: newStatusSelectedFilterItems },  () => this.getFilteredPricelistIds());

  }

  handleSelectFeatureFilter = (event, valueId, filterName) => {

    var selectedFeatureFilters = this.state.selectedFeatureFilters;

    if (selectedFeatureFilters[filterName] === undefined) {
      selectedFeatureFilters[filterName] = [];
    }

    var values = selectedFeatureFilters[filterName];

    if (values.indexOf(valueId) === -1) {
      addParameter(filterName, valueId);
    } else {
      removeParameter(filterName, valueId);
    }
    
    var newValues = checkboxToggle(valueId, values);

    selectedFeatureFilters[filterName] = newValues;

    this.setState({ selectedFeatureFilters: selectedFeatureFilters },  () => this.getFilteredPricelistIds());

  }

  getTotalPricelistSize = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/supplier/pricelist/ids?key=' + constants.apiKey + '&action=count&supplierId=' + this.props.supplierId + '&supplierUserId=' + this.props.supplierUserId + '&supplierClientId=' + (this.props.supplierClientId ? this.props.supplierClientId : '') + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    var that = this;

    var cb = function(data) { 
      that.setState({ totalPricelistSize: data.count });
      document.getElementById("pricelist-update-date").innerHTML=data.pricelistUpdateFinish;
      that.setState({ pricelistUpdateStart: data.pricelistUpdateStart });
      that.setState({ pricelistUpdateFinish: data.pricelistUpdateFinish });
      
      
    };

    // this.props.handleProgressBarOpen();
    doAjax(url, cb, null, this.props.handleShowSnackbar);

  }

  updateFavoritesList = () => {
    this.setState({
      categorySelectedFilterItemsFav: [],
      producerSelectedFilterItemsFav: [],
      warehouseSelectedFilterItemsFav: [],
      statusSelectedFilterItemsFav: [],
      minPriceSelectedFav: null,
      maxPriceSelectedFav: null,
      minMarkupSelectedFav: null,
      maxMarkupSelectedFav: null,
    }, () => { 
      this.getFilteredPricelistIds("Fav");
      this.updateFilterItemsFav();
    });
  }

  selectAllFilterItems = (filterName, items) => {

    var selectedListName = filterName + 'SelectedFilterItems';

    removeParameter(filterName, "[^&]*");

    var that = this;

    if (items.length > 0) {
      items.map(function(item) {
        addParameter(filterName, item);
      });
    }
    
    this.setState({ 
      [selectedListName]: items
    }, () => this.getFilteredPricelistIds());
  }

  selectAllFeatureFilterItems = (filterName, items) => {

    removeParameter(filterName, "[^&]*");

    if (items.length > 0) {
      items.map(function(item) {
        addParameter(filterName, item);
      });
    }

    var selectedFeatureFilters = this.state.selectedFeatureFilters;
    selectedFeatureFilters[filterName] = items;

    
    this.setState({ 
     selectedFeatureFilters: selectedFeatureFilters
    }, () => this.getFilteredPricelistIds());
  }

  init = () => {
    var params = getParams(window.location.href);
    var paramsModel = params.model === undefined ? '' : params.model;
    var storageModel = localStorage.getItem('modelFromNavbar') !== '%%' ? localStorage.getItem('modelFromNavbar') :  paramsModel;
    var model =  storageModel === '%' && storageModel === '' ? paramsModel : storageModel;
  //  var model = params.model === undefined ? ( localStorage.getItem('modelFromNavbar') === '%' || localStorage.getItem('modelFromNavbar') === '' ? '' : localStorage.getItem('modelFromNavbar')) : params.model;
    var orderBy = params.orderBy === undefined ? 'category' : params.orderBy;
    var order = params.order === undefined ? 'asc' : params.order;
    var categorySelectedFilterItems = params.category === undefined ? [] : params.category;
    var producerSelectedFilterItems = params.producer === undefined ? [] : params.producer;
    var warehouseSelectedFilterItems = params.warehouse === undefined ? [] : params.warehouse;
    var isReceiptCurrencyDifferentSelectedFilterItems = params.isReceiptCurrencyDifferent === undefined ? [] : params.isReceiptCurrencyDifferent;
    var minPriceSelected = params.minPrice === undefined ? null : params.minPrice;
    var maxPriceSelected = params.maxPrice === undefined ? null : params.maxPrice;
    var minMarkupSelected = params.minMarkup === undefined ? null : params.minMarkup;
    var maxMarkupSelected = params.maxMarkup === undefined ? null : params.maxMarkup;
    var minAvailabilityDaysSelected = params.minAvailabilityDays === undefined ? null : params.minAvailabilityDays;
    var maxAvailabilityDaysSelected = params.maxAvailabilityDays === undefined ? null : params.maxAvailabilityDays;
    var selectedFeatureFilters = params.featureFilters === undefined ? null : params.featureFilters;

    var statusSelectedFilterItems = params.status;

    if (params.status === undefined) {
      if (window.location.href.indexOf('/favorite') > 0) {
      	  statusSelectedFilterItems = [];
      } else {
	      statusSelectedFilterItems = ["1", "2", "6"];
	      addParameter("status", "1");
	      addParameter("status", "2");
	      addParameter("status", "6");
      }
    } 

    this.setState({ 
      modelFromLocalStorage: storageModel,
      paramsModel : paramsModel,
      modelFilterValue: model,
      orderBy: orderBy,
      order: order,
      categorySelectedFilterItems: categorySelectedFilterItems,
      producerSelectedFilterItems: producerSelectedFilterItems,
      warehouseSelectedFilterItems: warehouseSelectedFilterItems,
      statusSelectedFilterItems: statusSelectedFilterItems,
      minPriceSelected: minPriceSelected,
      maxPriceSelected: maxPriceSelected,
      minMarkupSelected: minMarkupSelected,
      maxMarkupSelected: maxMarkupSelected,
      minAvailabilityDaysSelected: minAvailabilityDaysSelected,
      maxAvailabilityDaysSelected: maxAvailabilityDaysSelected,
      selectedFeatureFilters: selectedFeatureFilters,
      isReceiptCurrencyDifferentSelectedFilterItems: isReceiptCurrencyDifferentSelectedFilterItems
    }, () => { this.updateFilterItems(); this.getFilteredPricelistIds(); });

    // this.getTotalPricelistSize();
  }

	componentWillMount() {
      this.init();
	}

  componentWillReceiveProps(nextProps) { 
    if (nextProps.modelFromNavbar !== this.state.paramsModel || nextProps.modelFromNavbar !== localStorage.getItem('modelFromNavbar') ) { // 
      removeParameter("model", "");
//      if (nextProps.modelFromNavbar && nextProps.modelFromNavbar != '%') {
      	addParameter("model", nextProps.modelFromNavbar);
//      } else {
//        addParameter("model", "");
//      }
      this.init();
      localStorage.setItem('modelFromNavbar',nextProps.modelFromNavbar)
    }
  }

  componentDidMount() {
    document.getElementById('model-search').value = this.state.modelFilterValue;
  }

  componentDidUpdate = () => {
    document.getElementById('model-search').value = this.state.modelFilterValue;
  }

	render() {
      const { classes, i18n, cart, comparator, fav, favorites, isAdmin, isManager, isSupervisor } = this.props;
   		return (
          <div>
            { fav &&
              <NavLink
                to={'/pricelist'}
                style={{color: "inherit"}}
              >
                <Button
                  className={classes.backButton}
                >
                  {i18n.t('back')}
                </Button>
              </NavLink>
            }

            <div>
              
              <div className={classes.modelFilterWrapper} style={{display: "none"}}>
              
                <CustomInput
                  id="model-search"
                  style={{display: "none"}}
                  formControlProps={{
                    className: classes.modelFilter
                  }}
                  inputProps={{
                    placeholder: i18n.t('model'),
                    inputProps: {
                      "aria-label": i18n.t('model')
                    },
                    onKeyPress: event => this.handleModelFilterEnter(event),
                  }}
                />
              </div>
             
          		<Pricelist
				    pricelist={this.state.pricelist}
          			pricelistIds={this.state.pricelistIds}
                	totalPricelistSize={this.state.totalPricelistSize}
          			page={this.state.page}
          			rowsPerPage={this.state.rowsPerPage}
          			handleChangePage={this.handleChangePage}
          			handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          			edit={this.state.edit}
          			handleEditOpen={this.handleEditOpen}
          			handleEditClose={this.handleEditClose}
          			editedValue={this.state.editedValue}
          			addToCart={this.props.addToCart}
          			updateQuantity={this.props.updateQuantity}
          			productQuantity={this.props.productQuantity}
	                i18n={i18n}
	                sort={this.sort}
	                categoryFilterItems={this.state.categoryFilterItems}
	                producerFilterItems={this.state.producerFilterItems}
	                warehouseFilterItems={this.state.warehouseFilterItems}
	                handleSelectFilterCategory={this.handleSelectFilterCategory}
	                handleSelectFilterProducer={this.handleSelectFilterProducer}
	                handleSelectFilterWarehouse={this.handleSelectFilterWarehouse}
	                handleSelectFilterStatus={this.handleSelectFilterStatus}
	                handlePriceFilterChange={this.handlePriceFilterChange}
	                handleAvailabilityDaysFilterChangeComplete={this.handleAvailabilityDaysFilterChangeComplete}
	                handlePriceFilterChangeComplete={this.handlePriceFilterChangeComplete}
	                handleMarkupFilterChangeComplete={this.handleMarkupFilterChangeComplete}
	                categorySelectedFilterItems={this.state.categorySelectedFilterItems}
	                producerSelectedFilterItems={this.state.producerSelectedFilterItems}
	                warehouseSelectedFilterItems={this.state.warehouseSelectedFilterItems}
	                statusSelectedFilterItems={this.state.statusSelectedFilterItems}
	                minPriceSelected={this.state.minPriceSelected}
	                maxPriceSelected={this.state.maxPriceSelected}
	                minPrice={this.state.minPrice}
	                maxPrice={this.state.maxPrice}
	                minMarkupSelected={this.state.minMarkupSelected}
	                maxMarkupSelected={this.state.maxMarkupSelected}
	                minMarkup={this.state.minMarkup}
	                maxMarkup={this.state.maxMarkup}
	                minAvailabilityDaysSelected={this.state.minAvailabilityDaysSelected}
	                maxAvailabilityDaysSelected={this.state.maxAvailabilityDaysSelected}
	                minAvailabilityDays={this.state.minAvailabilityDays}
	                maxAvailabilityDays={this.state.maxAvailabilityDays}
	                order={this.state.order}
	                orderBy={this.state.orderBy}
	                selectAllFilterItems={this.selectAllFilterItems}
	                supplierId={this.props.supplierId}
	                redirectToCheckout={this.props.redirectToCheckout}
	                toggleComparableItem={this.props.toggleComparableItem}
	                comparator={comparator}
	                toggleFavorite={this.props.toggleFavorite}
	                favorites={favorites}
	                featureFilters={this.state.featureFilters}
	                selectedFeatureFilters={this.state.selectedFeatureFilters}
	                handleSelectFeatureFilter={this.handleSelectFeatureFilter}
	                selectAllFeatureFilterItems={this.selectAllFeatureFilterItems}
	                handleFeatureFilterChangeComplete={this.handleFeatureFilterChangeComplete}
	                updateFavorites={this.getFilteredPricelistIds}
	                fav={this.props.fav}
	                isAdmin={this.props.isAdmin}
	                isManager={this.props.isManager}
	                isSupervisor={this.props.isSupervisor}
	                handleProgressBarOpen={this.props.handleProgressBarOpen} 
	                handleProgressBarClose={this.props.handleProgressBarClose}
	                handleShowSnackbar={this.props.handleShowSnackbar}
	                handleSelectFilterIsReceiptCurrencyDifferent={this.handleSelectFilterIsReceiptCurrencyDifferent}
	                isReceiptCurrencyDifferentSelectedFilterItems={this.state.isReceiptCurrencyDifferentSelectedFilterItems}
	                pricelistUpdateStart={this.state.pricelistUpdateStart}
	                pricelistUpdateFinish={this.state.pricelistUpdateFinish}
          		/>
            </div>
          </div>
      );
	}
}

export default withStyles(styles)(PricelistContainer);