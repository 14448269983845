import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Check from "@material-ui/icons/Check";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';

import avatar from "assets/img/faces/marc.jpg";

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost } from "variables/common.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardAvatarProfile: {
  	margin: "-20px auto 0",
    padding: "0",
    overflow: "hidden",
    maxWidth: "130px",
    maxHeight: "130px",
    boxShadow: "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%"
  }
};

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPasswordError: false,
      repeatPasswordError: false,
      showPassword: false,
      passwordUpdateSuccess: false,
      userInfoUpdateSuccess: false,
      emptyPrev: false,
      emptyNew: false,
      emptyRepeat: false,
      countries: null,
      cities: null,
      countryId: null,
      cityId: null
    }
  }

  componentDidMount = () => {
   // const { user } = this.props;

    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/user?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&userId=" + this.props.user.id;

    var that = this;
    
    this.getCountries();

    var cb = function(data) {
    
    	that.setState({ user: data });

		document.getElementById('login').value = data.login;
        document.getElementById('email').value = data.email;
	    document.getElementById('first-name').value = (data.firstName ? data.firstName : "");
	    document.getElementById('last-name').value = (data.lastName ? data.lastName : "");
	    document.getElementById('phone-work').value = data.userContacts !== undefined ? data.userContacts.phoneWork : '';
	    document.getElementById('phone-mobile').value = data.userContacts !== undefined ? data.userContacts.phoneMobile : '';
	    document.getElementById('skype').value = data.userContacts !== undefined ? data.userContacts.skype : '';
	    
	
	    if (data.userAddress !== undefined) {
	      if (data.userAddress.country !== undefined) {
	        that.setState({ countryId : data.userAddress.country.id});
	        that.getCities(data.userAddress.country.id);
	      }
	      if (data.userAddress.city !== undefined) {
	        that.setState({ cityId : data.userAddress.city.id});
	      }
	    }
    };

    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    
  }

  getCountries = () => {
   
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/countries?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    
    var that = this; 
    var cb = function(data) {  that.setState({ countries: data }) };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  getCities = countryId => {
  
    if (!countryId) {
    	return { cities: {}};
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/cities?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&countryId=" + countryId;

    var that = this; 
    var cb = function(data) { that.setState({ cities: data }) };

    this.props.handleProgressBarOpen();
    return doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleCountrySelected = event => {
    this.setState({ countryId: event.target.value });
    this.getCities(event.target.value);
  }

  handleCitySelected = (event) => {
    this.setState({ cityId: event.target.value });
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  updateUserInfo = () => {

    const { user } = this.props;
    
//    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/update-user?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    var body = "userId=" + user.id;
    body += "&email=" + document.getElementById('email').value;
    body += "&firstName=" + document.getElementById('first-name').value;
    body += "&lastName=" + document.getElementById('last-name').value;
    body += "&cityId=" + (this.state.cityId && this.state.cityId != 'null' ? this.state.cityId : "");
    body += "&countryId=" + (this.state.countryId && this.state.countryId != 'null' ? this.state.countryId : "");
    body += "&phoneWork=" + encodeURIComponent(document.getElementById('phone-work').value);
    body += "&phoneMobile=" + encodeURIComponent(document.getElementById('phone-mobile').value);
    body += "&skype=" + encodeURIComponent(document.getElementById('skype').value);

    var that = this;
    var cb = function(data) { 
      if (data.id === user.id) {
        that.setFieldState("userInfoUpdateSuccess");
        user.value = data; 
        localStorage.setItem('user', JSON.stringify(user)); 
      }
    };

    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);

  }

  updatePassword = () => {

    const { user } = this.props;

    var prevPass = document.getElementById('prev-password').value;
    var newPass = document.getElementById('new-password').value;
    var repeatPass = document.getElementById('repeat-password').value;

    if (prevPass === "" || newPass === "" || repeatPass === "") {
      if (prevPass === "") {
        this.setFieldState("emptyPrev");
      }

      if (newPass === "") {
        this.setFieldState("emptyNew");
      }

      if (repeatPass=== "") {
        this.setFieldState("emptyRepeat");
      }
      return;
    }

    if (document.getElementById('repeat-password').value !== document.getElementById('new-password').value) {
      this.setFieldState("repeatPasswordError");
      return;
    }

    var url = constants.apiBaseUrl + '/b2b/supplier/update-password?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    var body = "userId=" + user.id;
    body += "&prevPassword=" + encodeURIComponent(document.getElementById('prev-password').value);
    body += "&newPassword=" + encodeURIComponent(document.getElementById('new-password').value);

    var that = this;
    var cb = function(data) { 
      if (data.error !== null && data.error !== undefined && data.error.toLowerCase().includes("previous")) {
        that.setFieldState("prevPasswordError");
      } else if (data === "OK") {
        that.setFieldState("passwordUpdateSuccess");
      }
    };

    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { classes, i18n, user } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{i18n.t('editProfile')}</h4>
              </CardHeader>
              <CardBody>
              	<GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('login')}
                      id="login"
                      formControlProps={{
                        fullWidth: true,
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('email')}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('firstName')}
                      id="first-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('lastName')}
                      id="last-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="country" className={classes.labelRoot}>
                        {i18n.t('country')}
                      </InputLabel>
                      <NativeSelect
                        onChange={event => this.handleCountrySelected(event)}
                        value={this.state.countryId}
                        input={ <Input 
                                  id="country" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                />}
                      >
                      	<option value=''></option>
                        { this.state.countries !== null && this.state.countries !== undefined &&
                          this.state.countries.map(country => {
                            return(
                              <option value={country.id}>
                                {country.nameRu}
                              </option>
                            )
                          })
                        }
                      </NativeSelect>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="city" className={classes.labelRoot}>
                        {i18n.t('city')}
                      </InputLabel>
                      <NativeSelect
                        disabled={this.state.countryId === null}
                        id="city"
                        onChange={event => this.handleCitySelected(event)}
                        value={this.state.cityId}
                        input={ <Input 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                />}
                      >
                      	<option value=''></option>
                        { this.state.cities !== null && this.state.cities !== undefined &&
                          this.state.cities.map(city => {
                            return(
                              <option value={city.id}>
                                {city.name}
                              </option>
                            )
                          })
                        }
                      </NativeSelect>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('phoneWork')}
                      id="phone-work"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('phoneMobile')}
                      id="phone-mobile"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('skype')}
                      id="skype"
                      
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        name: "skype"
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
              </CardBody>
              <CardFooter>
                <Button 
                  color="primary"
                  onClick={this.updateUserInfo}
                >{i18n.t('save')}</Button>
                {this.state.userInfoUpdateSuccess && <Check style={{color: "green"}} />}
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            {false && 
            <Card profile>
              <CardAvatar profile className={classes.cardAvatarProfile}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={avatar} alt="..." />
                </a>
              </CardAvatar>
              <CardBody profile>
                {user.login}
                <h4 className={classes.cardTitle}>{(user.firstName ? user.firstName : "") + " " + (user.lastName ? user.lastName : "")}</h4>
                <p className={classes.description}>
                </p>
              </CardBody>
            </Card>
            }
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{i18n.t('editPassword')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={i18n.t('prevPassword')}
                      id="prev-password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={this.state.prevPasswordError || this.state.emptyPrev}
                      type={this.state.showPassword ? 'text' : 'password'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={i18n.t('newPassword')}
                      id="new-password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      type={this.state.showPassword ? 'text' : 'password'}
                      error={this.state.emptyNew}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={i18n.t('repeatPassword')}
                      id="repeat-password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={this.state.repeatPasswordError || this.state.emptyRepeat}
                      type={this.state.showPassword ? 'text' : 'password'}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button 
                  color="primary"
                  onClick={this.updatePassword}
                >
                  {i18n.t('save')}
                </Button>
                {this.state.passwordUpdateSuccess && <Check style={{color: "green"}} />}
                <IconButton
                  edge="end"
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserProfile);
