import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetApp from '@material-ui/icons/GetApp';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Counter from "components/Cart/Counter";
import FilterSidebar from "components/Sidebar/FilterSidebar.jsx";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Cancel from "@material-ui/icons/Cancel";
import Modal from '@material-ui/core/Modal';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Slider from "react-slick";
import SvgIcon from '@material-ui/core/SvgIcon';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Add from '@material-ui/icons/Add';
import Create from '@material-ui/icons/Create';
import Favorite from '@material-ui/icons/Favorite';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Check from "@material-ui/icons/Check";
import CustomButton from "components/CustomButtons/Button.jsx";
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import Moment from 'react-moment';

import { doAjax, doAjaxPost, getSettingValue, getSettingValueAsBoolean } from "variables/common.js";
import * as constants from "variables/constants.jsx";

import "assets/jss/material-dashboard-react/components/style.scss";
import "assets/css/slick-theme.css";
import "assets/css/slick.css";

import {
  grayColor,
  defaultFont
} from "assets/jss/material-dashboard-react.jsx";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, i18n, classes, showPrices } = this.props;

    const rows = [
      { id: 'image', numeric: false, disablePadding: false, label: '', className: classes.imageHeader },
      { id: 'externalId', numeric: false, disablePadding: true, label: i18n.t('externalId') },
      { id: 'category', numeric: false, disablePadding: true, label: i18n.t('category') },
      { id: 'producer', numeric: false, disablePadding: true, label: i18n.t('producer') },
      { id: 'model', numeric: false, disablePadding: true, label: i18n.t('model') },
      { id: 'code', numeric: false, disablePadding: true, label: i18n.t('code'), hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_CODE', false) },
//      { id: 'country', numeric: false, disablePadding: true, label: i18n.t('country') },
      { id: 'status', numeric: false, disablePadding: true, label: i18n.t('status') },
      { id: 'quantity', numeric: true, disablePadding: true, label: i18n.t('quantity'), className: classes.quantityHeader },
//      { id: 'multiplicity', numeric: true, disablePadding: true, label: i18n.t('multiplicity'), className: classes.multiplicityHeader },
      { id: 'warehouse', numeric: false, disablePadding: true, label: i18n.t('warehouse'), className: classes.warehouseHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_WAREHOUSE', false) },
      { id: 'availabilityDate', numeric: true, disablePadding: true, label: i18n.t('date'), className: classes.dateHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) },
      { id: 'country', numeric: false, disablePadding: true, label: i18n.t('country'), className: classes.countryHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_COUNTRY', false) },
      { id: 'isReceiptCurrencyDifferent', numeric: false, disablePadding: true, label: i18n.t('isReceiptCurrencyDifferent'), className: classes.isReceiptCurrencyDifferentHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_IS_RECEIPT_CURRENCY_DIFFERENT', false) },
      { id: 'warrantyText', numeric: true, disablePadding: true, label: i18n.t('warranty'), className: classes.warrantyHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_WARRANTY', false) },
      { id: 'price', numeric: true, disablePadding: true, label: i18n.t('priceWithVAT'), className: classes.priceHeader },
      { id: 'priceRecommended', numeric: true, disablePadding: true, label: i18n.t('retail'), className: classes.priceHeader },
      { id: 'priceRetail', numeric: true, disablePadding: true, label: getSettingValue('PRICELIST_RETAIL_PRICE_COL_NAME', i18n.t('retail')), className: classes.priceHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_RETAIL_PRICE', false) },
      { id: 'markup', numeric: true, disablePadding: true, label: getSettingValue('PRICELIST_MARKUP_COL_NAME', i18n.t('markup')), className: classes.markupHeader, hidden: !getSettingValueAsBoolean('PRICELIST_SHOW_MARKUP', true) }      
    ];

    return (
      <TableHead>
        <TableRow >
          {false && 
          <TableCell className={classes.checkBoxCell}>
          	
            <Checkbox
              className={classes.checkBox}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          }
          {rows.map(
            row => (
              (row.hidden === undefined || !row.hidden ? [
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classNames(classes.headerCell, row.className)}
              >
                {row.label !== '' ? 
                [
                <Tooltip
                  title={i18n.t('sort')}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {((row.id != 'price' && row.id != 'markup') || this.props.showPrices) && row.label}
                    </TableSortLabel>
                </Tooltip>
                ]
                : null}
              </TableCell>
              ] : ""
              )
            ),
            this,
          )}
          <TableCell 
            className={classes.cartCell}
            key='cart'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.compareCell}
            key='compare'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.compareCell}
            key='favorite'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          {this.props.isAdmin && 
          <TableCell 
            className={classes.compareCell}
            key='edit'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          }
          <TableCell 
	          className={classes.rightCell}
	          padding='none'>
	          {null}
	        </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "table",
//    width: "450px",
    textAlign: "right",
    whiteSpace: "nowrap"
  },
  title: {
    flex: '0 0 auto',
  },
});

function getModalStyle() {
  const vertical = 5;
  const horizontal = 20;

  return {
    top: `${vertical}%`,
    left: `${horizontal}%`,
//    bottom: `${vertical}%`,
//    overflow: "auto",
    maxHeight: "600px",
    minHeight: "inherit"
  };
}

function getEditModalStyle() {
  const vertical = 5;
  const horizontal = 20;

  return {
    top: `${vertical}%`,
//    bottom: `${vertical}%`,
    left: `${horizontal}%`,
    flexGrow: 1,
    maxHeight: "600px",
    minHeight: "inherit"
  };
}

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <img style={{verticalAlign: "middle", maxHeight: "30px", maxWidth: "30px"}} src={"https://api.zoomos.by/img/item/" + suggestion.id + "/main/resize/30x30"}/>
      <a style={{marginLeft: "15px"}} href="#">{suggestion.producer.name + ' ' + suggestion.modelReal + (suggestion.modificationCode !== undefined ? ' ' + suggestion.modificationCode : '')}</a>
    </MenuItem>
  );
}

function getSuggestions(value, { showEmpty = false } = {}, suggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions;
}

class EditLightbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saveSuccess: false,
      emptyProducer: false,
      emptyModel: false,
      cardsInputOpen: false,
      suggestions: [],
      supplierId: localStorage.getItem('supplierId')
    }
  };

  save = () => {

    var producer = document.getElementById('edit-lb-producer').value;
    var model = document.getElementById('edit-lb-model').value;

    if (producer === "" || model === "") {
      if (producer === "") {
        this.setFieldState("emptyProducer");
      }

      if (model === "") {
        this.setFieldState("emptyModel");
      }
      return;
    }

    const { item } = this.props;
    
    var user = JSON.parse(localStorage.getItem('user')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/pricelist/update?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    var body = "supplierId=" + this.state.supplierId + "&category=" + document.getElementById('edit-lb-category').value + "&producer=" + document.getElementById('edit-lb-producer').value + "&model=" + document.getElementById('edit-lb-model').value + "&code=" + document.getElementById('edit-lb-code').value + "&externalId=" + document.getElementById('edit-lb-externalId').value + "&status=" + document.getElementById('edit-lb-status').value + "&quantity=" + document.getElementById('edit-lb-quantity').value + "&price=" + document.getElementById('edit-lb-price').value + (item !== null ? "&priceRowAssociationId=" + item.id : "") ;

    var that = this;
    var cb = function(data) {
      if (data === "OK") {
        that.setFieldState("saveSuccess");
        that.props.updatePricelist();
        that.handleClose();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  handleStatusSelected = event => {
    console.log(event.target.value )
    if (event.target.value === "0") {
      document.getElementById('edit-lb-quantity').value = "0";
    }
    if (event.target.value === "1" && document.getElementById('edit-lb-quantity').value === "0") {
      document.getElementById('edit-lb-quantity').value = "1";
    }
  }

  handleQuantityChanged = event => {
    if (document.getElementById('edit-lb-quantity').value === "0") {
      document.getElementById('edit-lb-status').value = "0";
    }
    if (document.getElementById('edit-lb-quantity').value > 0 && document.getElementById('edit-lb-status').value === "0") {
      document.getElementById('edit-lb-status').value = "1";
    }
  }

  handleCardsOpen = event => {
    const { item } = this.props;
    this.setState({ cardsInputOpen: true });

    if (item !== null) {
    	
      var user = JSON.parse(localStorage.getItem('user')).value;
    
      var url = constants.apiBaseUrl + '/b2b/supplier/cards-suggestions?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&model=" + item.producer + " " + item.model + " " + item.code;

      var that = this;
      var cb = function(data) {
        that.setState({ suggestions: data }); 
      }
      this.props.handleProgressBarOpen();
      doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
    }

  }

  handleCardsChange = event => {
	  
	var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/cards-suggestions?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&model=" + document.getElementById('downshift-options-input').value;

    var that = this;
    var cb = function(data) {
      that.setState({ suggestions: data }); 
    }
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleClose = event => {
    this.setState({ cardsInputOpen: false });
    this.setState({ card: null }); 
    this.props.handleClose();
  }

  removeCard = () => {
    this.setState({ cardsInputOpen: false }); 
    this.props.removeCard();
  }

  setCard = targetPortalItemId => {
    this.setState({ cardsInputOpen: false }); 
    this.props.setCard(targetPortalItemId);
  }

  render() {
    const { i18n, classes, item, open, card } = this.props;
    const modalStyle = getEditModalStyle();

    return (
      <div>
        <Modal
          open={open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox}>
            <Typography variant="h6" id="tableTitle">
              { item === null ? i18n.t('adding') : i18n.t('edition')}
            </Typography>
            <IconButton 
              onClick={event => this.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            <div style={{margin: "auto"}}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('category')}
                      id="edit-lb-category"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.category : ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('producer') + '*'}
                      id="edit-lb-producer"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.producer : ""
                      }}
                      error={this.state.emptyProducer}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('model') + '*'}
                      id="edit-lb-model"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.model : ""
                      }}
                      error={this.state.emptyModel}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('code')}
                      id="edit-lb-code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.code : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('externalId')}
                      id="edit-lb-externalId"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.externalId : ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="edit-lb-status" className={classes.labelRoot}>
                        {i18n.t('status')}
                      </InputLabel>
                      <NativeSelect
                        onChange={event => this.handleStatusSelected(event)}
                        input={ 
                                <Input 
                                  id="edit-lb-status" 
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                  inputProps={{
                                    defaultValue: item !== null ? item.status : ""
                                  }}
                                />
                              }
                      >
                        <option value={1}>
                          {i18n.t('available')}
                        </option>
                        <option value={0}>
                          {i18n.t('unavailable')}
                        </option>
                        <option value={2}>
                          {i18n.t('onOrder')}
                        </option>
                      </NativeSelect>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('price')}
                      id="edit-lb-price"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.price : ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('quantity')}
                      id="edit-lb-quantity"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null && item.quantity !== 'undefined' ? item.quantity : "",
                        onChange : event => this.handleQuantityChanged(event)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{marginTop: "25px"}}>
                      { item !== null && item.targetPortalItemId !== undefined ?
                        <div>
                          <div style={{width: "30px", display: "inline-block"}}>
                            <img style={{verticalAlign: "middle", maxHeight: "30px", maxWidth: "30px"}} src={"https://api.zoomos.by/img/item/" + item.targetPortalItemId + "/main/resize/30x30"}/>
                          </div>
                          <div style={{fontSize: "16px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", marginLeft: "15px", display: "inline-block", verticalAlign: "middle"}}> { card !== null ? <a href="#">{card.producer.name + ' ' + card.modelReal + (card.modificationCode !== undefined ? ' ' + card.modificationCode : '')}</a> : "not found" }</div>
                          <Cancel
                            onClick={this.removeCard} style={{marginLeft: "20px", verticalAlign: "middle", fontSize: "16px", color: "red"}}
                          />
                        </div>
                       :
                      ( this.state.cardsInputOpen ?
                        <Downshift id="downshift-options" initialIsOpen={true} initialInputValue={item !== null ? item.producer + ' ' + item.model + (item.code !== undefined ?  ' ' + item.code : '') : ""} onSelect={selection => {
                          if (selection !== null) {
                            this.setCard(selection.id);
                          }
                        }}>
                          {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem,
                          }) => {
                            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                              onChange: event => {
                                if (event.target.value === '') {
                                  clearSelection();
                                } else {
                                  this.handleCardsChange(event);
                                }
                              },
                              onFocus: openMenu,
                            });

                            return (
                              <div className={classes.container}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  label: i18n.t('card'),
                                  InputLabelProps: getLabelProps({ shrink: true }),
                                  InputProps: { onBlur, onChange, onFocus },
                                  inputProps,
                                })}

                                <div {...getMenuProps()}>
                                  {isOpen ? (
                                    <Paper className={classes.paper} square style={{maxHeight: "250px", overflow: "auto"}}>
                                      {getSuggestions(inputValue, { showEmpty: true }, this.state.suggestions).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              </div>
                            );
                          }}
                        </Downshift>
                        : ( item !== null ? <Link onClick={event => this.handleCardsOpen(event)}>{i18n.t('addItemCard')}</Link> : '')
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{  }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{marginTop: "25px"}}>
                      <CustomButton 
                        color="primary"
                        onClick={this.save}
                      >
                        {item === null ? i18n.t('add') : i18n.t('save')}
                      </CustomButton>
                      {this.state.saveSuccess && <Check style={{color: "green", marginLeft: "25px", verticalAlign: "middle"}} />}
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

class FeaturesLightbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      nav1: null,
      nav2: null,
      openImage: false,
      image: null,
      quantity: 0,
      showPrices: true
    };

    this.setSlider1Ref = element => {
      this.setState({nav1: element});
    };

    this.setSlider2Ref = element => {
      this.setState({nav2: element});
    };
  }

  handleChange = (event, value) => {
    this.setState({
      tab: value
    });
  }

  handleImageOpen = (image) => {
    this.setState({
      image: image,
      openImage: true
    })
  }

  handleImageClose = () => {
    this.setState({
      image: null,
      openImage: false
    })
  }

  render() {
    const { i18n, open, item, offers, classes, itemFromTable, showPrices } = this.props;
    const modalStyle = getModalStyle();

    return (
      <div>
        <Modal
          open={this.state.openImage}
          onClose={this.handleImageClose}
        >
          <div>
            <div style={modalStyle} className={ classNames(classes.lightbox, classes.imageLightbox) }>
            <IconButton 
              onClick={this.handleImageClose} 
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
              <div style={{margin: "auto"}}>
                <img src={this.state.image}/>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={open}
          onClose={this.props.handleClose}
        >
          { item !== null ?
            <div style={modalStyle} className={classes.lightbox}>
              <div>
                <div style={{display: "inline-block", paddingRight: "40px", paddingTop: "10px", paddingLeft: "5px", minWidth: "50px"}}>
                  <img src={"https://api.zoomos.by/img/item/" + item.id + "/main/resize/100x100"}/>
                </div>
                <div style={{display: "inline-block", verticalAlign: "top"}}>
                  <Typography variant="h5" id="modal-title">
                    {item.vendor.name + " " + item.model}
                  </Typography>
                  <Typography variant="h7" id="modal-title">
                    {item.category.name}
                  </Typography>
                  <Typography variant="h6" id="modal-title">
                    { itemFromTable.price > 0 && showPrices ? (itemFromTable.currencyEnum === 'BYN' ? itemFromTable.price + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: itemFromTable.currencyEnum, minimumFractionDigits: 0, maximumFractionDigits: 2}).format(itemFromTable.price)) : "" }
                  </Typography>
                  { itemFromTable.status > 0 && itemFromTable.price > 0 && (itemFromTable.quantity > 0 || (itemFromTable.quantity && (itemFromTable.quantity.indexOf('>') == 0 || itemFromTable.quantity.toLowerCase().indexOf('бол') == 0))) &&
                    <div>
                      <div style={{marginTop: "5px", paddingTop: "2px", display: "inline-block"}}>
                        <Counter
                          className={classes.lbCounter}
                          productQuantity={1}
                          initQuantity={1}
                          maxQuantity={itemFromTable.quantity}
                          updateQuantity={this.props.updateQuantity}
                          resetQuantity={this.props.resetQuantity}
                        />
                      </div>
                      <Button
                        className={classes.buyButton}
                        onClick={event => this.props.addToCart(itemFromTable)}>
                        {i18n.t('buy')}
                      </Button>
                    </div>
                  }
                </div>
                <div style={{display: "inline-block"}} className={classes.cancelIconWrapper}>
                  <IconButton 
                    small
                    onClick={this.props.handleClose} 
                  >
                    <Cancel className={classes.cancelIcon}/>
                  </IconButton>
                </div>
              </div>
              <div>
                <AppBar className={classes.tabsContainer} position="static" color="transparent">
                  <Tabs
                    value={this.state.tab}
                    onChange={ (event, value) => this.handleChange(event, value)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    auto
                    scrollButtons="none"
                  >
                    <Tab label="Характеристики" />
                    <Tab label="Фотографии" />
                    {getSettingValueAsBoolean('PRODUCT_SHOW_OFFERS', true) && <Tab label="Цены" />}
                   </Tabs>
                </AppBar>
                { this.state.tab === 0 &&
                  <div className={classes.featuresTab}>
                    { item.details.featuresBlocks.map(featuresBlock => {
                      return (
                        <div>
                          <div className={classes.featuresBlockName}>{featuresBlock.name}</div>
                          <table className={classes.featureTable}>
                            {featuresBlock.features.map((feature, j) => {
                              return (
                                <tr className={ j + 1 === featuresBlock.features.length && classes.lastFeature }>
                                  <td className={classes.featureCell} >{feature.name}</td> 
                                  <td>{feature.values.map((value, i) => {
                                    return(
                                      <span>
                                      { value }
                                      { feature.values.length !== i + 1 && ',' }
                                      </span>
                                    )
                                  })}</td>
                                </tr>
                              )
                            })}
                          </table>
                        </div>
                      )
                    }) }
                  </div>
                }
                { this.state.tab === 1 &&
                  <div className={classNames(classes.carousel, classes.featuresTab)}>
                    <Slider
                      asNavFor={this.state.nav1}
                      ref={this.setSlider2Ref}
                    > 
                      {item.images.map(image => {
                        return(
                          <div>
                            <Button disableRipple className={classes.carouselImg}><img onClick={event => this.handleImageOpen(image)} style={{margin: "auto"}} src={image + "/resize/220x220"}/></Button>
                          </div>
                        )
                      })}
                    </Slider>
                    { item.images.length > 1 &&
                    <Slider
                      asNavFor={this.state.nav2}
                      ref={this.setSlider1Ref}
                      slidesToShow={item.images.length > 3 ? 3 : item.images.length}
                      swipeToSlide={true}
                      focusOnSelect={true}
                    >
                      {item.images.map(image => {
                        return(
                          <div>
                             <Button disableRipple className={classes.carouselImg}><img style={{margin: "auto", maxWidth: "50px", maxHeight: "50px"}} src={image + "/resize/50x50"}/></Button>
                          </div>
                        )
                      })}
                    </Slider>
                    }
                  </div>
                }
                { this.state.tab === 2 &&
                	<div className={classes.featuresTab}>
                		<div className={classes.featuresBlockName}></div>
                		<table>
                      { offers && offers.map((offer, j) => {
                                return (
                                  <tr>
                                    <td className={classes.featureCell} style={{fontWeight: "bold", fontSize: "14px"}}>{offer.shopName}</td>
                                    <td className={classes.featureCell} style={{textAlign: "right"}}>{itemFromTable.currencyEnum === 'BYN' ? offer.shopPrice + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: itemFromTable.currencyEnum, minimumFractionDigits: 0, maximumFractionDigits: 2}).format(offer.shopPrice)}</td> 
                                  </tr>
                                )
                              })
                        
                      }
                      </table>
                    </div>
                  }
              </div>
            </div>
          : null }
        </Modal>
      </div>
    );
  }
}

FeaturesLightbox.propTypes = {
  classes: PropTypes.object.isRequired
};

let EnhancedTableToolbar = props => {
  const { i18n, numSelected, classes, isAdmin, state, handleChangeShowPrices, fav, pricelistUpdateStart, pricelistUpdateFinish } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} { i18n.t('selected') }
          </Typography>
        ) : (
          <>
          <Typography variant="h6" id="tableTitle" style={{display: "inline-block"}}>
            { props.fav ? i18n.t('favorites') : i18n.t('pricelist') }
          </Typography>
           <>
           <Typography variant="h9" id="pricelist-update-date" style={{display: "inline-block", fontSize: "14px", marginLeft: "5px", color: "#bbb"}}>
           	{ props.pricelistUpdateFinish && !props.fav ? props.pricelistUpdateFinish.substring(0, pricelistUpdateFinish.lastIndexOf(":")).replace(".202", ".2") : "" }
           </Typography>
           </>
          </>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div style={{display: "inline-block"}}>
          	
          <FormControlLabel style={{marginRight: '35px'}} labelPlacement="start"
        	control={<Switch checked={state.showPrices} size="small" color="primary" onChange={handleChangeShowPrices} />}
        	label={i18n.t('showPrices')} />
          
            { (true) && 
          	<Tooltip title={ i18n.t('download') }>
              <IconButton id="download-pricelist" style={{display: "inline-block"}} aria-label="Download" href="#">
                <GetApp />
              </IconButton>
            </Tooltip>
            }
            
            
            
            { isAdmin && 
              <Tooltip title={ i18n.t('add') }>
                <IconButton style={{display: "inline-block"}} aria-label="Add" onClick={event => props.addClick(null)}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={ i18n.t('filters') }>
              <IconButton style={{display: "inline-block"}} aria-label="Filter list" onClick={props.handleFilterClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    "& > table > tbody": {
      overflow: "auto",
      maxHeight: "60vh"
    },
    maxHeight: "calc(100vh - 60px - 64px - 56px - 74px)",
    overflow: "auto",
  },
  textInput: {
    fontSize: '0.8125rem',
    fontWeight: 400,
    margin: 0,
    marginTop: 0,
    paddingBottom: "4px",
    paddingTop: "5px",
    position: 'relative'
  },
  textInputWrapper: {
    margin: 0,
    paddingBottom: 3,
    width: "80%"
    
  },
  pricelistRow: {
    maxHeight: '32px',
    minHeight: '32px',
    height: '32px'
  },
  headerCell: {
    whiteSpace: "nowrap"
  },
  quantityHeader: {
	paddingRight: "5px",  
  },
  dateHeader: {
	paddingRight: "5px",
	textAlign: "right"
  },
  warrantyHeader: {
	paddingLeft: "5px",
	width: "50px"
  },
  warehouseHeader: {
	paddingLeft: "10px",
	width: "50px"
  },
  countryHeader: {
	paddingLeft: "5px",
	width: "50px"
  },
  isReceiptCurrencyDifferentHeader: {
	paddingLeft: "0px",
	width: "30px"
  },
  priceHeader: {
	paddingRight: "5px",  
  },
  markupHeader: {
	width: "1px",
	whiteSpace: "nowrap",
	paddingRight: "5px"
  },
  nonNumericCell: {
    minWidth: '100px',
  },
  hoverUnderline: {
	"&:hover": {
		textDecoration: "underline"
    }
  },
  numericCell: {
    minWidth: '100px',
  },
  dateCell: {
    width: '75px',
    textAlign: "right",
    paddingRight: "5px"
  },
  warrantyCell: {
    width: '50px',
    textAlign: "right"
  },
  countryCell: {
    width: '50px',
    textAlign: "left",
    paddingLeft: "5px"
  },
  isReceiptCurrencyDifferent: {
    width: '30px',
    textAlign: "left",
    paddingLeft: "5px"
  },
  imageCell: {
    width: '1px',
    minHeight: '30px',
    maxHeight: '30px',
    verticalAlign: "middle",
    paddingRight: '20px',
    textAlign: "center"
  },
  checkBoxCell: {
    width: '1px',
    verticalAlign: "middle",
    padding: '0 20px 0px 20px'
  },
  checkBox: {
    height: '28px',
    padding: 0,
  },
  imageHeader: {
    width: '1px',
    verticalAlign: "middle",
    padding: '0 20px 0px 20px'
  },
  paginationWrapper: {
    display: 'inline-block',
    float: 'right',
    position: 'flex'
  },
  totalWrapper: {
    display: 'inline-block',
    height: '56px',
    marginLeft: '15px',
    fontSize: '0.8125rem',
    fontWeight: 400,
    verticalAlign: 'middle',
    textAlign: 'center'
  },
  tableFooter: {
    height: "auto",
    overflowX: 'auto'
  },
  cartCell: {
    minWidth: "1px",
    width: "1px",
    paddingLeft: "20px",
    whiteSpace: "nowrap",
    paddingRight: "5px"
  },
  quantityCell: {
    minWidth: "1px",
//    maxWidth: "1px",
    whiteSpace: "nowrap",
    paddingRight: "5px",
  },
  statusCell: {
    minWidth: "1px",
    maxWidth: "1px",
    whiteSpace: "nowrap"
  },
  priceCell: {
    minWidth: "1px",
//    maxWidth: "1px",
    whiteSpace: "nowrap",
    textAlign: "right",
    paddingRight: "5px",
  },
  compareCell: {
    minWidth: "30px",
    width: "30px",
    paddingLeft: "5px",
    paddingRight: "5px",
    whiteSpace: "nowrap",
    textAlign: "center"
  },
  rightCell: {
    minWidth: "10px",
    width: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",
    whiteSpace: "nowrap"  
  },
  addToCartButton: {
    height: "24px",
    minWidth: "24px",
    margin: 0,
    padding: 0,
    fontSize: "15px",
    color: "white",
    backgroundColor: "#198503",
    "&:hover": {
      backgroundColor: "#27621A",
      color: "white",
    }
  },
  addToCompareButton: {
    height: "21px",
    minWidth: "21px",
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    fontSize: "15px",
    color: "#B3B3B3",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#B3B3B3",
    }
  },
  compareIcon: {
    fontSize: "15px",
    height: "21px",
    minWidth: "21px",
  },
  addToCompareButtonPushed: {
    color: "#DC7633",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#DC7633",
    }
  },
  cartIcon: {
    height: "15px",
    width: "15px",
    padding: 0
  },
  menuItem: {
    padding: "2px",
    fontSize: "0.8125rem",
  },
  selector: {
    padding: "0",
    "& > ul": {
      padding: 0
    }
  },
  imageButton: {
    padding: "0px 10px 0px 10px",
    margin: 0,
    width: '1px',
    minWidth: '1px',
    minHeight: '30px',
    maxHeight: '30px',
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent"
    },
  },
  lightbox: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    minHeight: 750
  },
  editLightbox: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  imageLightbox: {
    textAlign: "center",
    display : "flex",
    alignItems : "center"
  },
  tabsContainer: {
    boxShadow: "none",
    borderBottom: "1px solid #e6e6e6",
    paddingTop: "15px"
  },
  featuresBlockName: {
    paddingTop: "15px",
    paddingBottom: "10px",
    fontWeight: 500,
    lineHeight: "1.5em",
    fontSize: "0.8050rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    textTransform: "uppercase",
  },
  featuresTab: {
  	overflow: "auto",
  	maxHeight: "350px",
  	minHeight: "200px"
  },
  lastFeature: {
    borderBottom: "1px solid #e6e6e6",
  },
  featureCell: {
    borderSpacing: 0,
    margin: 0,
    padding: 0,
    width: "50%"
  },
  leftCell: {
    minWidth: "21px",
    width: "21px",
    paddingLeft: "0",
    paddingRight: "15px",
    whiteSpace: "nowrap"
  },
  nonePaddingCell: {
    minWidth: "21px",
    width: "21px",
    maxWidth: "21px",
    paddingLeft: "0",
    paddingRight: "0",
    whiteSpace: "nowrap"
  },
  featureTable: {
    lineHeight: "1em",
    width: "100%",
    paddingBottom: "15px",
    borderSpacing: 0,
    fontSize: "0.875rem",
    fontWeight: 400,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    lineHeight: "1.46429em"
  },
  carousel: {
    minWidth: "100%",
    textAlign: "center"
  },
  carouselImg: {
    marginBottom: "15px",
    paddingTop: "5px",
   "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0 0 5px rgba(0,0,0,0.4)"
    },
    "&:focus": {
      boxShadow: "0 0 5px rgba(0,0,0,0.6)"
    },
    "&:active": {
      boxShadow: "0 0 5px rgba(0,0,0,0.6)"
    }
  },
  cancelIconWrapper: {
    right: "10px",
    top: "10px",
    position: "absolute",
  },
  cancelIcon: {
    width: "15px",
    height: "15px",
    fontSize: "15px"
  },
  buyButton: {
    fontSize: "12px",
    color: "white",
    fontWeight: 700,
    backgroundColor: "#198503",
    "&:hover": {
      backgroundColor: "#27621A",
      color: "white",
    },
    padding: "2px 22px"
  },
  favoriteIcon: {
    fontSize: "15px",
    height: "21px",
    minWidth: "21px",
    color: "red"
  },
  editIcon: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "21px",
    color: "#848484",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  favoriteButton: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "1px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  favoriteBorderIcon: {
    fontSize: "15px",
    height: "21px",
    minWidth: "21px",
    color: "#B3B3B3"
  },
  formControl: {
    paddingBottom: "9px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  tooltipPrice: {
    backgroundColor: "rgba(255,255, 255, 1)",
    borderRadius:'0',
    boxShadow:'0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)', 
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '0px',
    '&::before': {
       content: '""',
       position:'fixed',
       borderLeft: '10px solid transparent',
       borderRight: '10px solid transparent',
       right:'10px',
	},
},
  tooltipWarehouses: {
        maxWidth:'300px',
        paddingBottom: '4px',
        minWidth:'160px',
        marginLeft: '20px',
	'&::before': {
	},        
},
  tooltipPriceDown: {
    marginTop:'0px',
    '&::before': {
       borderBottom: '10px solid white',
       bottom:'80px',
	},
},
  tooltipPriceUp: {
    marginBottom:'0px',
    '&::before': {   
       borderTop: '10px solid white',
       top:'80px',
   	},
},
  tooltipPriceTable:{
    width:'150px',
    height:'79px',
    overflow:'auto',
    marginRight:'-3px',
   	marginTop:'-3px',
    paddingTop:'4px',
    marginLeft: '-3px'
  }, 
  tooltipWarehousesTable:{
      minWidth:'150px',
      marginLeft:'-3px',
      maxWidth:'245px',
},
  tooltipPriceTableFirstCell:{
   fontSize:'11px',
   textOverflow: 'ellipsis',
   maxWidth:'90px', 
   whiteSpace: 'nowrap',
   overflow: 'hidden'
  },
  tooltipHighlightRow:{
	 "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.07)",
    },
},
});

class EnhancedTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      order: props.order,
      orderBy: props.orderBy,
      selected: [],
      windowHeight:null,
      tooltipX:null,
      selectedProduct: {},
      added: '',
      featuresLBOpen: false,
      featuresLBItem: null,
      featuresLBOffers: [],
      featuresLBItemFromTable: null,
      editLBOpen: false,
      editLBItem: null,
      card: null,
      showPrices: (localStorage.getItem('showPrices') == null ? true : localStorage.getItem('showPrices') == 'true') 
    };
 	   this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy }, () => this.props.sort(this.state.orderBy, this.state.order));
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.props.pricelist.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };
  
  handleChangeShowPrices = event => {
	  this.setState({ showPrices: event.target.checked });
	  localStorage.setItem('showPrices', event.target.checked);
	  
	  var amountWrapper = document.getElementById('amount-wrapper');
	  if (amountWrapper) {
		  if (event.target.checked) {
			  amountWrapper.style.visibility = 'visible';
		  } else {
			  amountWrapper.style.visibility = 'hidden';
		  }
	  }
  }

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleFilterClick = () => {
    this.setState({ showFilterSidebar: true });
  }

  handleFiltersClose = () => {
    this.setState({ showFilterSidebar: false });
  }

  handleFilterToggle = () => {
    this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
  };

  handleImageClick = (event, item) => {

    if (item.targetPortalItemId === null || item.targetPortalItemId === undefined) {
      return;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;

    var that = this;

    this.props.updateQuantity(1);

    var cb = function(data) { 
      
    var cbOffers = function(offersData) {
          that.setState({
              featuresLBOpen: true,
              featuresLBItem: data,
              featuresLBItemOffers: offersData.offers,
              featuresLBItemFromTable: item
          }); 
      }
      
      var shops = ["21vek.by", "5 элемент", "imarket.by", "domotehnika.by", "ЭЛЕКТРОСИЛА"];
      var shopsParam = "";
      shops.map(function(shop) {
    	  shopsParam += "&shop=" + shop;
        });

      that.props.handleProgressBarOpen();
      
//      if (item.externalId.indexOf('/') == -1) { //TODO
          doAjax(constants.apiBaseUrl + "/b2b/supplier/product/competitors?key=" + constants.apiKey + "&externalId=" + encodeURIComponent(item.externalId) + "&supplierId=" + localStorage.getItem('supplierId') + "&principalId=" + user.id + shopsParam, cbOffers, that.props.handleProgressBarClose, that.props.handleShowSnackbar);
//      } else {
//    	  cbOffers([]);
//      }
    	
    };

	this.props.handleProgressBarOpen();
	
    doAjax(constants.apiBaseUrl + "/item/"+item.targetPortalItemId+"?key=" + constants.apiKey + "&principalId=" + user.id, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);

  }

  handleEditClick = item => {
    this.setState({
      editLBOpen: true,
      editLBItem: item
    }, () => this.getCard(item)); 
  }

  handleFeaturesLBClose = (event) => {
    this.setState({
      featuresLBOpen: false,
      featuresLBItem: null,
      featuresLBItemOffers: []
    });
  }

  handleEditLBClose = (event) => {
    this.setState({
      editLBOpen: false,
      editLBItem: null,
      card: null
    });
  }

  setCard = targetPortalItemId => {
	  
	var user = JSON.parse(localStorage.getItem('user')).value;
	  
    var url = constants.apiBaseUrl + '/b2b/supplier/set-card?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierPriceRowAssociationId=" + this.state.editLBItem.id + "&targetPortalItemId=" + targetPortalItemId;
    
    var that = this;

    var cb = function(data) {
      that.setState({
        editLBItem: data
      }, () => that.getCard(data)); 
    };

    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  removeCard = () => {
	  
	var user = JSON.parse(localStorage.getItem('user')).value;  
  
    var url = constants.apiBaseUrl + '/b2b/supplier/remove-card?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierPriceRowAssociationId=" + this.state.editLBItem.id;

    var that = this;
    var cb = function(data) {
      that.setState({
        editLBItem: data
      }); 
    };

    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

   getCard = item => {
    if (this.state.editLBItem === null || this.state.editLBItem.targetPortalItemId === undefined) {
      return;
    }

    var user = JSON.parse(localStorage.getItem('user')).value;
    
    var url = constants.apiBaseUrl + "/b2b/supplier/card?targetPortalItemId=" + item.targetPortalItemId + "&principalId=" + user.id + "&key=" + constants.apiKey + '&upd=' + new Date().getTime();

    var that = this;
    var cb = function(data) {
      that.setState({
        card: data
      });
    };

    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;
   
  toggleHover = (event) => {
    this.setState({hover: !this.state.hover});
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }
 resize() {
    this.setState({windowHeight: window.innerHeight });
 }
	
 handleMouseMove(event) {
	    this.setState({tooltipX: event.screenY });
 }

  render() {
    const { classes, pricelist, pricelistIds, totalPricelistSize, rowsPerPage, page, edit, editedValue, i18n, comparator, favorites, featureFilters, selectedFeatureFilters, fav, isAdmin, isManager, isSupervisor, pricelistUpdateStart, pricelistUpdateFinish} = this.props;
    const { windowHeight, tooltipX, order, orderBy, selected  } = this.state;
    
    if (!pricelistIds) {
//    	return (<div></div>);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, pricelistIds.length - page * rowsPerPage);

    return (
      <div>
        <EditLightbox 
          open={this.state.editLBOpen} 
          i18n={i18n} 
          classes={classes} 
          item={this.state.editLBItem} 
          handleClose={this.handleEditLBClose} 
          updatePricelist={this.props.updateFavorites}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          removeCard={this.removeCard}
          setCard={this.setCard}
          card={this.state.card}
        />
        <FeaturesLightbox 
          open={this.state.featuresLBOpen} 
          i18n={i18n} 
          classes={classes} 
          item={this.state.featuresLBItem}
          offers={this.state.featuresLBItemOffers}
          handleClose={this.handleFeaturesLBClose} 
          itemFromTable={this.state.featuresLBItemFromTable}
          addToCart={this.props.addToCart}
          updateQuantity={this.props.updateQuantity}
          resetQuantity={this.resetQuantity}
          productQuantity={this.props.productQuantity}
          showPrices={this.state.showPrices}
        />
        <ClickAwayListener onClickAway={this.handleFiltersClose}>
          <FilterSidebar
            handleFilterToggle={this.handleFilterToggle}
            open={this.state.showFilterSidebar}
            i18n={i18n}
            categoryFilterItems={this.props.categoryFilterItems}
            producerFilterItems={this.props.producerFilterItems}
            warehouseFilterItems={this.props.warehouseFilterItems}
            handleSelectFilterCategory={this.props.handleSelectFilterCategory}
            handleSelectFilterProducer={this.props.handleSelectFilterProducer}
            handleSelectFilterWarehouse={this.props.handleSelectFilterWarehouse}
            handleSelectFilterStatus={this.props.handleSelectFilterStatus}
            categorySelectedFilterItems={this.props.categorySelectedFilterItems}
            producerSelectedFilterItems={this.props.producerSelectedFilterItems}
            warehouseSelectedFilterItems={this.props.warehouseSelectedFilterItems}
            statusSelectedFilterItems={this.props.statusSelectedFilterItems}
            minPriceSelected={this.props.minPriceSelected}
            maxPriceSelected={this.props.maxPriceSelected}
            minMarkupSelected={this.props.minMarkupSelected}
            maxMarkupSelected={this.props.maxMarkupSelected}
            selectAllFilterItems={this.props.selectAllFilterItems}
            handlePriceFilterChangeComplete={this.props.handlePriceFilterChangeComplete}
            handleMarkupFilterChangeComplete={this.props.handleMarkupFilterChangeComplete}
            handleAvailabilityDaysFilterChangeComplete={this.props.handleAvailabilityDaysFilterChangeComplete}
            minPrice={this.props.minPrice}
            maxPrice={this.props.maxPrice}
            minMarkup={this.props.minMarkup}
            maxMarkup={this.props.maxMarkup}
            featureFilters={featureFilters}
            selectedFeatureFilters={selectedFeatureFilters}
            handleSelectFeatureFilter={this.props.handleSelectFeatureFilter}
            selectAllFeatureFilterItems={this.props.selectAllFeatureFilterItems}
            handleFeatureFilterChangeComplete={this.props.handleFeatureFilterChangeComplete}
            allStatuses={["0", "1", "2", "6"]}
            minAvailabilityDaysSelected={this.props.minAvailabilityDaysSelected}
            maxAvailabilityDaysSelected={this.props.maxAvailabilityDaysSelected}
            minAvailabilityDays={this.props.minAvailabilityDays}
            maxAvailabilityDays={this.props.maxAvailabilityDays}
            isReceiptCurrencyDifferentSelected={this.props.isReceiptCurrencyDifferentSelectedFilterItems}
            isReceiptCurrencyDifferentFilterItems={["0", "1"]}
            handleSelectFilterIsReceiptCurrencyDifferent={this.props.handleSelectFilterIsReceiptCurrencyDifferent}
          />
        </ClickAwayListener>

        <Paper className={classes.root}>

          <EnhancedTableToolbar addClick={this.handleEditClick} numSelected={selected.length} i18n={i18n} handleFilterClick={this.handleFilterClick} state={this.state} handleChangeShowPrices={this.handleChangeShowPrices} fav={this.props.fav} isAdmin={isAdmin} pricelistUpdateStart={this.props.pricelistUpdateStart} pricelistUpdateFinish={this.props.pricelistUpdateFinish}/>
          <div className={classes.tableWrapper}>
            <Table className={classNames(classes.table, "fixed-header-table")} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={pricelist.length}
                i18n={i18n}
                classes={classes}
                showPrices={this.state.showPrices}
              />
              <TableBody>
                {pricelist
                  .map(n => { 
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        component="td" 
                        className={classes.pricelistRow}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        {false &&
                        <TableCell align="center" className={classes.checkBoxCell}>
                          <Checkbox 
                            checked={isSelected} 
                            className={classes.checkBox}
                            onClick={event => this.handleClick(event, n.id)}
                          />
                        </TableCell>
                        }
                        <TableCell 
                          component="td" 
                          scope="row" 
                          
                          align="left"
                          onClick={event => this.handleImageClick(event, n)}
                          className={classes.imageCell}>
                          { n.targetPortalItemId !== 'undefined' && n.targetPortalItemId !== undefined &&  n.targetPortalItemId !== null ?
                            <Link disableRipple className={classes.imageButton} >
                              <img style={{verticalAlign: "middle", maxHeight: "30px", maxWidth: "30px"}} src={"https://api.zoomos.by/img/item/" + n.targetPortalItemId + "/main/resize/30x30"}/>
                            </Link>
                            : ''
                          }     
                        </TableCell>
                        <TableCell 
                          component="td" 
                          scope="row" 
                          padding="none"
                         >
                        	<span onClick={event => { this.handleImageClick(event, n) }}
                            className={classNames(classes.nonNumericCell, classes.hoverUnderline)}
                            style={{cursor: "pointer", "&:hover": {textDecoration: "underline"}}}>
                          { (edit == "externalId-" + n.id) ?
                            <CustomInput
                              id={"externalId-" + n.id}
                              formControlProps={{
                                className: classes.textInputWrapper
                              }}
                              inputProps={{
                                placeholder: "",
                                inputProps: {
                                  className: classes.textInput,
                                },
                                onKeyPress: event => this.props.handleEditClose(event),
                                onBlur: event => this.props.handleEditClose(event),
                              }}
                            />
                          : n.externalId }
                          </span>
                        </TableCell>
                        <TableCell 
                          component="td" 
                          scope="row" 
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "category", n.category) }}
                          className={classes.nonNumericCell}
                        >
                          { (edit == "category-" + n.id) ?
                            <CustomInput
                              id={"category-" + n.id}
                              formControlProps={{
                                className: classes.textInputWrapper
                              }}
                              inputProps={{
                                placeholder: "",
                                inputProps: {
                                  className: classes.textInput,
                                },
                                onKeyPress: event => this.props.handleEditClose(event),
                                onBlur: event => this.props.handleEditClose(event),
                              }}
                            />
                          : n.category }
                        </TableCell>
                        <TableCell 
                          component="td" 
                          scope="row" 
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "producer", n.producer) }}
                          className={classes.nonNumericCell}
                        >
                          { (edit == "producer-" + n.id) ?
                            <CustomInput
                              id={"producer-" + n.id}
                              formControlProps={{
                                className: classes.textInputWrapper
                              }}
                              inputProps={{
                                placeholder: "",
                                inputProps: {
                                  className: classes.textInput,
                                },
                                onKeyPress: event => this.props.handleEditClose(event),
                                onBlur: event => this.props.handleEditClose(event),
                              }}
                            />
                          : n.producer }
                        </TableCell>
                        <TableCell 
                          component="td" 
                          scope="row" 
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "model", n.model) }}
                          className={classes.nonNumericCell}
                        >
                          { (edit == "model-" + n.id) ?
                          <CustomInput
                            id={"model-" + n.id}
                            formControlProps={{
                              className: classes.textInputWrapper
                            }}
                            inputProps={{
                              placeholder: "",
                              inputProps: {
                                className: classes.textInput,
                              },
                              onKeyPress: event => this.props.handleEditClose(event),
                              onBlur: event => this.props.handleEditClose(event),
                            }}
                          />
                          : (n.modelUrl && n.modelUrl.length > 0 ? 
                           <Link href={n.modelUrl} target="_blank" >{n.model}</Link> 
                           : n.model) }
                        </TableCell>
                        
                        { getSettingValueAsBoolean('PRICELIST_SHOW_CODE', false) && 
                        <TableCell 
                          component="td" 
                          scope="row" 
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "code", n.code) }}
                          className={classes.nonNumericCell}
                        >
                          { (edit == "code-" + n.id) ?
                            <CustomInput
                              id={"code-" + n.id}
                              formControlProps={{
                                className: classes.textInputWrapper
                              }}
                              inputProps={{
                                placeholder: "",
                                inputProps: {
                                  className: classes.textInput,
                                },
                                onKeyPress: event => this.props.handleEditClose(event),
                                onBlur: event => this.props.handleEditClose(event),
                              }}
                            />
                          : n.code }
                        </TableCell>
                        }
                        {false &&
                        <TableCell 
                          component="td" 
                          scope="row" 
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "country", n.country) }}
                          className={classes.nonNumericCell}
                        >
                          { (edit == "country-" + n.id) ?
                            <CustomInput
                              id={"country-" + n.id}
                              formControlProps={{
                                className: classes.textInputWrapper
                              }}
                              inputProps={{
                                placeholder: "",
                                inputProps: {
                                  className: classes.textInput,
                                },
                                onKeyPress: event => this.props.handleEditClose(event),
                                onBlur: event => this.props.handleEditClose(event),
                              }}
                            />
                          : n.country }
                        </TableCell>
                        }
                        
                        <TableCell 
                          component="td" 
                          scope="row" 
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "status", n.status.toString()) }}
                          className={ classNames(classes.nonNumericCell, classes.statusCell) }
                        >
                          { (edit == "status-" + n.id) ? 
                            <Select
                              id={"status-" + n.id}
                              value={editedValue}
                              onChange={ event => this.props.handleEditClose(event) }
                              name="status"
                              displayEmpty
                              variant="filled"
                              className={ classNames(classes.textInput, classes.selector) }
                            >
                              <MenuItem className={classes.menuItem} value="0" selected={n.status === 0 && true}>{i18n.t('unavailable')}</MenuItem>
                              <MenuItem className={classes.menuItem} value="1" selected={n.status === 1 && true}>{i18n.t('available')}</MenuItem>
                              <MenuItem className={classes.menuItem} value="2" selected={n.status === 2 && true}>{i18n.t('onOrder')}</MenuItem>
                              <MenuItem className={classes.menuItem} value="6" selected={n.status === 6 && true}>{i18n.t('transit')}</MenuItem>
                            </Select>
                          : i18n.t('status-' + n.status) }
                        </TableCell>
                        <TableCell 
                          align="right"
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "quantity", n.quantity) }}
                          className={ classNames(classes.numericCell, classes.quantityCell) }
                        >
                          { (edit == "quantity-" + n.id) ?
                            <CustomInput
                              id={"quantity-" + n.id}
                              formControlProps={{
                                className: classes.textInputWrapper
                              }}
                              inputProps={{
                                placeholder: "",
                                inputProps: {
                                  className: classes.textInput,
                                },
                                onKeyPress: event => this.props.handleEditClose(event),
                                onBlur: event => this.props.handleEditClose(event),
                              }}
                            />
                          : (n.quantity && n.quantity.replace(/[ \u00A0]/, '') > 10 ? "> 10" : n.quantity) }
                        </TableCell>

                       { /*<TableCell 
                          align="right"
                          padding="none"
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "multiplicity", n.multiplicity) }}
                          className={ classNames(classes.numericCell, classes.quantityCell) }
                        >
                          { (edit == "multiplicity-" + n.id) ?
                            <CustomInput
                              id={"multiplicity-" + n.id}
                              formControlProps={{
                                className: classes.textInputWrapper
                              }}
                              inputProps={{
                                placeholder: "",
                                inputProps: {
                                  className: classes.textInput,
                                },
                                onKeyPress: event => this.props.handleEditClose(event),
                                onBlur: event => this.props.handleEditClose(event),
                              }}
                            />
                          : n.multiplicity }
                        </TableCell>*/}                       
                      
                        {getSettingValueAsBoolean('PRICELIST_SHOW_WAREHOUSE', false) && 
                        <Tooltip interactive 
       			                    title={ n.warehouses && n.warehouses.length > 1 ?
                                       <div key={n.id} className={classes.tooltipPriceTable, classes.tooltipWarehousesTable}>	
                           	              <table style={{width:'100%', borderCollapse:'collapse'}}>
                                               <tr>
                                                   <th style={{textAlign:'left'}}><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{i18n.t('warehouse')}</Typography></th>
                                                   <th style={{textAlign:'right', paddingLeft:'7px'}}><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{i18n.t('quantity')}</Typography></th>
                                                   <th style={{textAlign:'right', paddingLeft:'7px'}}><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{i18n.t('price')}</Typography></th>
                                                   {getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) && <th style={{textAlign:'right', paddingLeft:'7px'}}><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{i18n.t('date')}</Typography></th>}
                                               </tr>  
                          {n.warehouses.map(item => { return <> 
                                               <tr className={classes.tooltipHighlightRow}>
                                                   <td><Typography classes={{body1:classes.tooltipPriceTableFirstCell}} >{item.warehouse}</Typography></td>
                                                   <td style={{textAlign:'right', paddingLeft:'7px'}}><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{ item.quantity ? (item.quantity.replace(/[ \u00A0]/, '') > 10 ? "> 10" : item.quantity) : "" }</Typography></td>
                                                   <td style={{textAlign:'right', paddingLeft:'7px'}}><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{ item.price ? item.price : "" }</Typography></td>
                                                   {getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) && <td style={{textAlign:'right', paddingLeft:'7px'}}><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>
                                                         {item.availabilityDate ? 
                                                                   <Moment parse="DD.MM.YYYY HH:mm:ss" format={getSettingValue("PRICELIST_DATE_FORMAT", "DD.MM.YY")}>
                            	                                      {item.availabilityDate}
	                                                               </Moment>
                                                                   : "" }
                                                       </Typography></td>}
                                               </tr>    
                                            </>
                                     })}
		                              </table></div> 
                                       : "" }
          					     placement="bottom-start"
           	    			     classes={{ tooltip:classNames(classes.tooltipPrice, classes.tooltipWarehouses) }}
                        >
                         <TableCell 
	                        align="left"
	                        padding="none"
                        	className={ classNames(classes.nonNumericCell) }
                        	style={{whiteSpace: 'nowrap', paddingLeft: '10px'}}
	                      >
                        	{ n.warehousesText && n.warehousesText }
                        </TableCell>
                        </Tooltip>
                        }           

                        {getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) && 
                        <TableCell 
	                        align="right"
	                        padding="none"
	                        onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "date", n.date) }}
                        	className={ classNames(classes.dateCell) }
                        	style={{whiteSpace: 'nowrap', paddingLeft: '10px'}}
	                      >
                        { n.availabilityDate && 
                            <Moment parse="DD.MM.YYYY HH:mm:ss" format={getSettingValue("PRICELIST_DATE_FORMAT", "DD.MM.YY")}>
                            	{n.availabilityDate}
	                        </Moment>
                        }
                        </TableCell>
                        }
                        
                        {getSettingValueAsBoolean('PRICELIST_SHOW_COUNTRY', false) && 
                            <TableCell 
    	                        align="left"
    	                        padding="none"
                            	style={{whiteSpace: 'nowrap'}}
                        		className={classes.countryCell}
    	                      >
                            { n.country && n.country }
                            </TableCell>
                        }
                        
                        {getSettingValueAsBoolean('PRICELIST_SHOW_IS_RECEIPT_CURRENCY_DIFFERENT', false) && 
                            <TableCell 
    	                        align="left"
    	                        padding="none"
                            	style={{whiteSpace: 'nowrap'}}
                        		className={classes.isReceiptCurrencyDifferentCell}
    	                      >
                            { n.isReceiptCurrencyDifferent && n.isReceiptCurrencyDifferent == 1 ? "Да" : "Нет" }
                            </TableCell>
                        }
                        
                        {getSettingValueAsBoolean('PRICELIST_SHOW_WARRANTY', false) && 
                            <TableCell 
    	                        align="left"
    	                        padding="none"
                            	style={{whiteSpace: 'nowrap'}}
                        		className={classes.warrantyCell}
    	                      >
                            { n.warrantyText && n.warrantyText }
                            </TableCell>
                        }

		                <Tooltip  interactive 
       			            title={ (isAdmin || isManager || isSupervisor) && (n.clientPriceRows || n.clientGroupPriceRows) ? 
							 
                           <div key={n.id} className={classes.tooltipPriceTable}>	
                           	<table style={{width:'100%', borderCollapse:'collapse'}} >
                          {n.clientGroupPriceRows ? n.clientGroupPriceRows.map((i) => {
                                 return <tr className={classes.tooltipHighlightRow}>
                                    <td><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{i.supplierClientGroup.name}</Typography></td>
		                            <td style={{textAlign:'right'}}><Typography  classes={{body1:classes.tooltipPriceTableFirstCell}}>{i.price}</Typography></td>
			                            </tr>
                                     }):''}
		                             {(n.clientPriceRows && n.clientGroupPriceRows) ? <hr /> :''}
                          {n.clientPriceRows ? n.clientPriceRows.map((i) => {
		                         return <tr className={classes.tooltipHighlightRow}>
			                         <td><Typography classes={{body1:classes.tooltipPriceTableFirstCell}}>{i.supplierClient.name}</Typography></td>
        	                         <td  style={{textAlign:'right'}}><Typography  classes={{body1:classes.tooltipPriceTableFirstCell}}>{i.price}</Typography></td>
     	                             </tr>
	                                  }):''}
				                    </table></div> : '' }
          					placement="bottom-end"
           	    			classes={ { tooltip:classNames(classes.tooltipPrice) } }
                        >                 
                        <TableCell
                          align="right"
                          padding="none"
                          className={ classNames(classes.numericCell, classes.priceCell) }
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "price",  n.price !== undefined ? n.price : "") }}
									                          >
                          { (edit == "price-" + n.id) ?
                              <CustomInput
                                id={"price-" + n.id}
                                formControlProps={{
                                  className: classes.textInputWrapper
                                }}
                                inputProps={{
                                  placeholder: "",
                                  inputProps: {
                                    className: classes.textInput,
                                  },
                                  onKeyPress: event => this.props.handleEditClose(event),
                                  onBlur: event => this.props.handleEditClose(event),
                                }}
                              />
                            : n.price !== undefined && this.state.showPrices ? n.currencyEnum === 'BYN' ? n.price + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: n.currencyEnum, minimumFractionDigits: 0, maximumFractionDigits: 2}).format(n.price) : ""
                          }
                        </TableCell>
                        </Tooltip>
                       
									
                        <TableCell 
                          align="right"
                          padding="none"
                          className={ classNames(classes.numericCell, classes.priceCell) }
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "priceRecommended",  n.priceRecommended !== undefined ? n.priceRecommended : "") }}
                        >
                          { (edit == "priceRecommended-" + n.id) ?
                              <CustomInput
                                id={"priceRecommended-" + n.id}
                                formControlProps={{
                                  className: classes.textInputWrapper
                                }}
                                inputProps={{
                                  placeholder: "",
                                  inputProps: {
                                    className: classes.textInput,
                                  },
                                  onKeyPress: event => this.props.handleEditClose(event),
                                  onBlur: event => this.props.handleEditClose(event),
                                }}
                              />
                            : n.priceRecommended !== undefined ? n.currencyEnum === 'BYN' ? n.priceRecommended + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: n.currencyEnum, minimumFractionDigits: 0, maximumFractionDigits: 2}).format(n.priceRecommended) : ""
                          }
                        </TableCell>
                        
                        {getSettingValueAsBoolean('PRICELIST_SHOW_RETAIL_PRICE', true) && 
                        <TableCell 
                          align="right"
                          padding="none"
                          className={ classNames(classes.numericCell, classes.priceCell) }
                          onClick={event => { isAdmin && !fav && this.props.handleEditOpen(event, n.id, "priceRetail",  n.priceRetail !== undefined ? n.priceRetail : "") }}
                        >
                          { (edit == "priceRetail-" + n.id) ?
                              <CustomInput
                                id={"priceRetail-" + n.id}
                                formControlProps={{
                                  className: classes.textInputWrapper
                                }}
                                inputProps={{
                                  placeholder: "",
                                  inputProps: {
                                    className: classes.textInput,
                                  },
                                  onKeyPress: event => this.props.handleEditClose(event),
                                  onBlur: event => this.props.handleEditClose(event),
                                }}
                              />
                            : n.priceRetail !== undefined ? n.currencyEnum === 'BYN' ? n.priceRetail + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: n.currencyEnum, minimumFractionDigits: 0, maximumFractionDigits: 2}).format(n.priceRetail) : ""
                          }
                        </TableCell>
                        }
                        
                        {getSettingValueAsBoolean('PRICELIST_SHOW_MARKUP', true) && 
                        <TableCell 
	                        align="right"
	                        padding="none"
	                        className={ classNames(classes.numericCell, classes.priceCell) }
	                      >
                        
                        	{this.state.showPrices && n.markup >= 0 && Math.round(n.markup)}
                        
                        </TableCell>
                        }

                        <TableCell 
                          align="center"
                           padding="none"
                          className={classes.cartCell}
                        >
                        { n.price > 0 && n.status > 0 && (n.quantity && n.quantity.replace(/[ \u00A0]/, '') > 0 || (n.quantity && (n.quantity.indexOf('>') == 0 || n.quantity.toLowerCase().indexOf('бол') == 0 || n.quantity.indexOf('+') == n.quantity.length - 1))) ?
                          <div>
                          <Counter
                            productQuantity={this.props.productQuantity}
                            maxQuantity={n.quantity.replace(/[ \u00A0]/, '')}
                            updateQuantity={this.props.updateQuantity}
                            resetQuantity={this.resetQuantity}
                            initQuantity={1}
                            id={n.id}
                          />
                          <div className="product-action">
                            <Button 
                              onClick={ event => this.props.addToCart(n) }
                              className={classes.addToCartButton}
                              color="inherit"
                            >
                              <ShoppingCart className={classes.cartIcon}/>
                            </Button>
                          </div>
                          </div>
                        : null }
                        </TableCell>

                        <TableCell 
                          align="center"
                           padding="none"
                          className={classes.compareCell}
                        >
                          <Button 
                            onClick={ event => this.props.toggleComparableItem(event, n) }
                            className={ classNames(comparator.findIndex(x => x.id == n.targetPortalItemId) !== -1 && classes.addToCompareButtonPushed, classes.addToCompareButton)}
                            color="inherit"
                            disableRipple
                          >
                            <SvgIcon className={classes.compareIcon}>
                               <path d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
                            </SvgIcon>
                          </Button>
                        </TableCell>
                        <TableCell 
                          align="left"
                          padding="none"
                          className={classes.compareCell}
                        >
                          <Button 
                            onClick={ event => { this.props.toggleFavorite(event, n.id);  this.props.updateFavorites() } }
                            color="inherit"
                            disableRipple
                            className={classes.favoriteButton}
                          >
                            { favorites.indexOf(n.id) !== -1 ?
                                <Favorite className={classes.favoriteIcon}/>
                              :
                                <FavoriteBorder className={classes.favoriteBorderIcon}/>
                            }
                          </Button>
                        </TableCell>
                        { isAdmin &&
                        <TableCell 
                          align="left"
                          padding="none"
                          className={classes.compareCell}
                        > 
                            <Button 
                              onClick={ event => this.handleEditClick(n) }
                              color="inherit"
                              disableRipple
                              className={classes.favoriteButton}
                            >
                              <Create className={classes.editIcon}/>
                            </Button>
                          
                        </TableCell>
                        }
                        <TableCell 
	                        align="left"
	                        padding="none"
	                        className={classes.rightCell}
	                      > 
                        	
                        </TableCell>
                      </TableRow>
                    );
                  })
              }
              </TableBody>
            </Table>
          </div>
          <div className={classes.tableFooter}> 
          	{ false &&
            <div className={classes.totalWrapper}> 
              <div style={{marginTop: '15px'}}> {i18n.t('total')} {totalPricelistSize} </div>
            </div>
          	}
          
            
            <div className={classes.paginationWrapper}>
              <TablePagination
                rowsPerPageOptions={[50, 100, 250, 500]}
                component="div"
                count={pricelistIds.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                labelRowsPerPage={i18n.t('rows_per_page')}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  pricelist: PropTypes.array.isRequired,
  pricelistIds: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  edit: PropTypes.string.isRequired,
  handleEditOpen: PropTypes.func.isRequired,
  handleEditClose: PropTypes.func.isRequired,
  editedValue: PropTypes.string.isRequired,
};

export default withStyles(styles)(EnhancedTable);
