import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import PricelistContainer from "components/Table/PricelistContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function TableList(props) {
  const { classes } = props;
  return (
    <PricelistContainer
      classes={classes}
      addToCart={props.addToCart}
      updateQuantity={props.updateQuantity}
      updateItemQuantity={props.updateItemQuantity}
      productQuantity={props.productQuantity}
      i18n={props.i18n}
      handleProgressBarOpen={props.handleProgressBarOpen}
      handleProgressBarClose={props.handleProgressBarClose}
      handleShowSnackbar={props.handleShowSnackbar}
      supplierId={props.supplierId}
      supplierUserId={props.supplierUserId}
      supplierClientId={props.supplierClientId}
      cart={props.cart}
      removeProduct={props.removeProduct}
      totalAmount={props.totalAmount}
      comparator={props.comparator}
      toggleComparableItem={props.toggleComparableItem}
      toggleFavorite={props.toggleFavorite}
      favorites={props.favorites}
      fav={props.fav}
      isAdmin={props.isAdmin}
      isManager={props.isManager}
      isSupervisor={props.isSupervisor}
      modelFromNavbar={props.modelFromNavbar}
    />
  );
}
export default withStyles(styles)(TableList);
