import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";

const styles = theme => ({
	...footerStyle,
    footer: {
    	padding: "5px 0px 0px 0px",
    	lineHeight: "15px"
    },
    right: {
    	padding: "5px 0px 5px 0px",
    	fontSize: "14px",
    	float: "right !important"
    	
    }
    
});

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
          </List>
        </div>
        <p className={classes.right}>
        	© {(new Date().getFullYear())} <a href="https://zoomos.by" target="_blank">ZOOMOS</a> - Мониторинг цен конкурентов, описания товаров, авторасценка
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
