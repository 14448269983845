import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.jsx";

const sidebarStyle = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "2000",
    ...boxShadow,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"

    }
  },
  drawerPaperClose: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1000",
    ...boxShadow,
    width: theme.spacing.unit * 6.5,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing.unit * 6.5,
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing.unit * 6.5,
      position: "fixed",
      height: "100%"
    }
  },
  filterSidebar: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "12px",
    left: "auto",
    zIndex: "2000",
    ...boxShadow,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    width: "330px",
    [theme.breakpoints.up("md")]: {
      width: "330px",
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "330px",
      position: "fixed",
      height: "100%"

    }
  },
  filterSidebarClose: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "0",
    left: "auto",
    zIndex: "1000",
    ...boxShadow,
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: 0,
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
      position: "fixed",
      height: "100%"
    }
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8"
    }
  },
  search: {
    "& > div": {
      marginTop: "0",
      width: "90%",
      marginLeft: "13px"
    },
    display: "inline-block",
    width: "90%",
    paddingBottom: "4px",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  searchInput: {
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  scroll: {
    height: "auto",
    overflow: "auto",
    maxHeight: "400px",
    width: "100%"
  },
  searchWrapper: {
    display: "inline-block",
    marginTop: "15px",
    width: "100%",
    paddingLeft: "0px",
    "& > input": {
      fontWeight: "400",
      fontSize: "13px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    }
  },
  margin: {
    zIndex: "4",
    margin: "0"
  },
  searchIconWrapper: {
    width: "15px",
    fontSize: "19px",
    zIndex: "4",
    marginTop: "7px",
    marginRight: "10px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  searchIcon: {
    width: "18px",
    fontSize: "19px",
    color: "#C0C0C0"
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor
    }
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "15px 0",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
  },
  chevronLeftIcon: {
    lineHeight: "30px",
    float: "right",
    marginRight: "15px",
    color: "white",
    marginTop: "13px",
  },
  filterName: {
    marginLeft: 0,
    color: "gray",
  },
  sliderFilterName: {
    marginLeft: 0,
    color: "gray",
    fontWeight: "500",
    fontSize: "14px",
  },
  sliderWrapper: {
    width: "95%",
    marginTop: "25px",
    marginBottom: "25px",
    marginLeft: "15px"
  },
  intervaFilterWrapper: {
    width: "95%",
    marginTop: "15px",
    marginBottom: "10px",
    display: "flex"
  },
  intervaFilterLeftInput:{
    "&  fieldset": {
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
      borderRight: "none"
    },
    "&:hover": {
      "&  div": {
        borderRight:"1px solid rgba(0, 0, 0, 0.87)"
      },
    },
    "& input.focus-visible":{
      borderRight:"2px solid #3f51b5",
    }
  },
  intervaFilterRightInput:{
    "&  fieldset": {
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "0",
      borderLeft: "none"
    },
    "&  div": {
      borderLeft:"1px solid rgba(0, 0, 0, 0.23)",
    },
    "&:hover": {
      "&  div": {
        borderLeft:"1px solid rgba(0, 0, 0, 0.87)"
      },
    },
    "& input.focus-visible":{
      borderLeft:"2px solid #3f51b5",
    }
  },

  chevronRightIcon: {
    lineHeight: "30px",
    float: "left",
    marginLeft: "15px",
    color: "black",
    marginTop: "13px",
  },

  menuIcon: {
    width: "24px",
    height: "40px",
    lineHeight: "30px",
    float: "left",
    marginLeft: "30px",
    color: "white",
    marginTop: "13px",
  },
  divider: {
    height: theme.spacing.unit * 2,
    borderTop: '1px solid #e6e6e6',
    marginTop: "5px"
  },

  filtersWrapper: {
    marginLeft: '17px',
    marginRight: '17px',
    width: "90%"
  },

  filterTitle: {
    fontWeight: "500",
    fontSize: "14px",
    '&:before': {
      fontFamily: 'Material Icons',
      content: '',
      fontSize: "1.5em",
      position: "absolute",
      left: "-20px",
      top: "-5px"
    }
  },
  filterCheckbox: {
    '& > span': {
      padding: "2px 2px 2px 12px"
    }
  },
  iconWrapper: {
    position: "relative",
    minHeight: "74px",
    width: "100%",
    display: 'inline-block'
  },
  chevronLeftClose: {
    display: 'none',
  },
  itemIconRTL: {
    marginRight: "3px",
    marginLeft: "15px",
    float: "right"
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: whiteColor
  },
  itemTextRTL: {
    textAlign: "right"
  },
  whiteFont: {
    color: whiteColor
  },
  purple: {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
    "&:hover": {
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow
    }
  },
  blue: {
    backgroundColor: infoColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(infoColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ",.2)",
    "&:hover": {
      backgroundColor: infoColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.2)"
    }
  },
  green: {
    backgroundColor: successColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(successColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ",.2)",
    "&:hover": {
      backgroundColor: successColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(successColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ",.2)"
    }
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.2)",
    "&:hover": {
      backgroundColor: warningColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.2)"
    }
  },
  red: {
    backgroundColor: dangerColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(dangerColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ",.2)",
    "&:hover": {
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.2)"
    }
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch"
  },
  filterSidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflowY: "auto",
    width: "100%",
    zIndex: "4",
    overflowScrolling: "touch",
    overflowX: "hidden"
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px"
    }
  }
});

export default sidebarStyle;
