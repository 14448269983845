import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
// import Menu from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Search from "@material-ui/icons/Search";

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import Chip from '@material-ui/core/Chip';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import NoSsr from '@material-ui/core/NoSsr';

import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";

import "assets/css/filter-autocomplete.css";

import { getSettingValueAsBoolean, getSettingValue } from "variables/common.js";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

const styles = theme => ({
	...sidebarStyle(theme),
    
});

function isSelected(item, list) { return list !== undefined ? list.indexOf(item) !== -1 : false };



const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef}
        {...props} 
        className="filter-autocomplete-flex"
        />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        maxWidth: "300px",
        fontSize: "0.875rem",
        lineHeight: "1.46429em",
        padding: "2px 4px",
        
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      style={{borderRadius: "3px"}}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {

  if (props.selectProps.inputValue.length < 2) {
  	return ("");
  }

  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps} 
      style={{
        maxWidth: "290px",
      }}
      >
      {props.children}
    </Paper>
  );
}

class IntegrationReactSelect extends React.Component {

  constructor(props) {
  	super(props);
  }
  
  state = {
    single: null,
    multi: null,
  };

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
    //this.props.onChange(null, value.value);
    
    var items = [];
    
    if (value != null) {
	    value.map(n => {
		  items.push(n.value);
		});
	}
    
    this.props.selectAllFilterItems(this.props.filterName, items);
  };

  componentWillMount = () => {


  };
  
  componentDidMount = () => {

	if (this.props.selectedItems) {
		var items = [];
    
		this.props.selectedItems.map(n => {
		  var value = n.id !== undefined ? n.id : n;
		  var label = n.value !== undefined ? n.value : n;
		  items.push({value: value, label: label});
		});
		
		this.setState({
	      ['multi']: items,
	    });
    }

  };
  
  render() {
    const { classes, theme, suggestions, onChange, selectAllFilterItems, filterName, selectedItems } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
//        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        },
        display: "inline-block"
        
      }),
    };
    
    var options = [];
    
    if (this.props.suggestions !== undefined) {
	    options = this.props.suggestions.map(n => ({
		  value: n.id !== undefined ? n.id : n,
		  label: n.value !== undefined ? n.value : n,
		}));
	}

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label: '',
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={options}
            components={components}
            value={this.state.multi}
            onChange={this.handleChange('multi')}
            placeholder=""
            isMulti
          />
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
};


class Filter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      search: '',
      open: this.props.selectedItems && this.props.selectedItems.length > 0 ? true : (this.props.open === null || this.props.open === undefined ? false : this.props.open === true || this.props.open === 'true')
    };
  } 

  selectAll = (event) => {
    this.setState({ selectAll: !this.state.selectAll }, () => {

      var name = this.props.filterName !== undefined ? this.props.filterName : this.props.filterTitle;
      var items = [];

      this.props.filterItems.map(item => {
        items.push(item !== null && item.id !== undefined ? item.id : item);
      });
      if (this.state.selectAll) {
        this.props.selectAllFilterItems(name, items);
      } else {
        this.props.selectAllFilterItems(name, []);
      }
    });
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  }

  handleSearchChange = (event) => {
     this.setState({ search: event.target.value });
  }

  handleSelectFilterItem = (event, item) => {
    this.setState({ selectAll : false });
    this.props.handleSelectFilterItem(event, item, this.props.filterName);
  }

  render() {
    const { i18n, selectedItems, filterTitle, filterItems, classes, showSelectAll } = this.props;
    const { selectAll, search, open } = this.state;
    
    var defaultFilterValuesLimit = 100;

    return (
      <div>
        <FormControl style={{width: "100%"}} component="fieldset">
          <FormControlLabel className={classes.filterName} control={ <div className={classes.filterTitle} onClick={ event => this.toggleOpen() }> { (i18n.t(filterTitle) !== undefined ? i18n.t(filterTitle) : filterTitle) + " " + (selectedItems !== undefined ? selectedItems.length : 0) + "/" + (filterItems !== undefined ? filterItems.length : 0)}</div> }>
          </FormControlLabel>

          { open ?
            <div>
              { filterItems !== undefined && filterItems.length >= 10 && filterItems.length < defaultFilterValuesLimit ?
                <div className={classes.searchWrapper}>
                  <div className={classes.searchIconWrapper}>
                    <Search className={classes.searchIcon}/>
                  </div>
                  <CustomInput
                    formControlProps={{
                      className: classes.margin + " " + classes.search
                    }}
                    inputProps={{
                      placeholder: i18n.t('search'),
                      inputProps: {
                        "aria-label": i18n.t('search'),
                        value : this.state.search,
                        className: classes.searchInput
                      },
                      onChange: event => this.handleSearchChange(event),
                    }}
                  />
                </div>
              : 
              ""
              
               }
              <FormGroup>
                <div style={{height: "7px"}}></div>
                { filterItems !== undefined && filterItems.length < defaultFilterValuesLimit ? [
                (showSelectAll && filterItems.length >= 10 ?
                <div>
                  <FormControlLabel className={classes.filterCheckbox}
                    control={
                      <Checkbox checked={selectAll} value={i18n.t('selectAll')} onClick={event => this.selectAll(event)}/>
                    }
                    label={i18n.t('selectAll')}
                  />
                  <div style={{height: "7px"}}></div>
                </div>
                : ''),
                <div className={classes.scroll}>
                  {filterItems.map(n => {
                    if (n === null || n === undefined) { return; }
                    var val = n.id !== undefined ? n.val : n;
                    var id = n.id !== undefined ? n.id : n;

                    if(val !== 'undefined' && val !== null && val !== '' && (search === '' || search !== '' && val.toLowerCase().includes(search.toLowerCase()))) {
                    return(
                        <FormControlLabel className={classes.filterCheckbox} style={{width: "95%"}}
                          control={
                            <Checkbox checked={isSelected(id, selectedItems)} value={id} onClick={event => this.handleSelectFilterItem(event, id) }/>
                          }
                          label = { filterTitle === 'status' || filterTitle === 'orderStatus' || filterTitle === 'isReceiptCurrencyDifferent' ? i18n.t(filterTitle + '-' + val) : val }
                        />
                      );
                    }
                  })}
                </div>
                ]
                  :
                  <IntegrationReactSelect
                  	classes={classes}
                  	suggestions={filterItems}
                  	selectedItems={selectedItems}
                  	onChange={this.handleSelectFilterItem }
                  	selectAllFilterItems={this.props.selectAllFilterItems}
                  	filterName={filterTitle}
                   />
                  }
              </FormGroup>
            </div>
          : "" }
        </FormControl>
      </div>
    );
  }
}

Filter.propTypes = {
  classes: PropTypes.object.isRequired
};

class FilterSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      minSelected: this.props.minSelected === null || this.props.minSelected === undefined ? this.props.min : this.props.minSelected,
      maxSelected: this.props.maxSelected === null || this.props.maxSelected === undefined ? this.props.max : this.props.maxSelected,
      open : this.props.minSelected || this.props.maxSelected ? true : (this.props.open === null || this.props.open === undefined ? false : this.props.open === true || this.props.open === 'true')
    };
  } 

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { i18n, filterTitle, classes, min, max, filterName } = this.props;
    const { selectAll, search, open, minSelected, maxSelected } = this.state;

    return (
      <div>
        <FormControl style={{width: "90%"}} component="fieldset">
          <FormControlLabel className={classes.sliderFilterName} control={ <div className={classes.filterTitle} onClick={ event => this.toggleOpen() }> { i18n.t(filterTitle) } </div> }>  </FormControlLabel>
          { 
          	open ?
            <div className={classes.sliderWrapper}>
	            <InputRange
	              maxValue={max}
	              minValue={min}
	              step={1}
	              value={{ min: minSelected, max: maxSelected }}
	              onChange={ value => this.setState({minSelected: value.min, maxSelected: value.max}) }
	              onChangeComplete={ value => this.props.handleSliderFilterChangeComplete(value.min, value.max, filterName) }
	            />
            </div>
            : ""
          }
        </FormControl>
      </div>
    );
  }
}

FilterSlider.propTypes = {
  classes: PropTypes.object.isRequired
};

class FilterInterval extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      minSelected: this.props.minSelected === null || this.props.minSelected === undefined ? "" : this.props.minSelected,
      maxSelected: this.props.maxSelected === null || this.props.maxSelected === undefined ? "" : this.props.maxSelected,
      open : this.props.minSelected || this.props.maxSelected ? true : (this.props.open === null || this.props.open === undefined ? false : this.props.open === true || this.props.open === 'true')
    };
  } 

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { i18n, filterTitle, classes, min, max, filterName } = this.props;
    const { selectAll, search, open, minSelected, maxSelected } = this.state;

    return (
      <div>
        <FormControl style={{width: "100%"}} component="fieldset">
          <FormControlLabel className={classes.sliderFilterName} control={ <div className={classes.filterTitle} onClick={ event => this.toggleOpen() }> { i18n.t(filterTitle) } </div> }>  </FormControlLabel>
          { 
          	open ?
           <div className={classes.intervaFilterWrapper}>
	           <TextField 
                  variant="outlined" 
                  className={classes.intervaFilterLeftInput} 
                  label={i18n.t('from')} 
                  defaultValue = {minSelected} 
                  placeholder={min} 
                  InputLabelProps={{shrink: true}}
	                  onChange={ event => this.setState({minSelected: event.target.value != "" ?  Number(event.target.value) : ""}) }
                  onBlur={ value => this.props.handleSliderFilterChangeComplete( minSelected, maxSelected, filterName) }
	            />
              <TextField 
                  variant="outlined" 
                  className={classes.intervaFilterRightInput} 
                  label={i18n.t('to')}          
	                  defaultValue={maxSelected} 
                  placeholder={max}
                  InputLabelProps={{shrink: true}}
	                  onChange={ event => this.setState({ maxSelected: event.target.value != "" ?  Number(event.target.value) : ""}) }
	                  onBlur={ value => this.props.handleSliderFilterChangeComplete( minSelected, maxSelected, filterName) }
	            />
            </div>
            : ""
          }
        </FormControl>
      </div>
    );
  }
}

FilterInterval.propTypes = {
  classes: PropTypes.object.isRequired
};

const FilterSidebar = ({ ...props }) => {

  const { classes, i18n, categoryFilterItems, producerFilterItems, warehouseFilterItems, categorySelectedFilterItems, producerSelectedFilterItems, warehouseSelectedFilterItems, statusSelectedFilterItems, minPriceSelected, 
  		  maxPriceSelected, minPrice, maxPrice, featureFilters, selectedFeatureFilters, allStatuses, contractorFilterItems, contractorSelectedFilterItems, clientGroupFilterItems, clientGroupSelectedFilterItems, 
  		  managerFilterItems, managerSelectedFilterItems, isAdmin, isClientAdmin, isManager, minAvailabilityDays, maxAvailabilityDays, minAvailabilityDaysSelected, maxAvailabilityDaysSelected, isReceiptCurrencyDifferentSelected, 
  		  isReceiptCurrencyDifferentFilterItems, minMarkup, maxMarkup, minMarkupSelected, maxMarkupSelected  } = props;

  return (
    <div>
        <Drawer
          variant="permanent"
          open={props.open}
          classes={{
            paper: classNames(classes.filterSidebar, !props.open && classes.filterSidebarClose)
          }}
          ModalProps={{
            keepMounted: true
          }}
          >

          <div className={classes.filterSidebarWrapper}>
            <div className={classNames(classes.iconWrapper)}>
              <IconButton 
                onClick={props.handleFilterToggle} 
                className={classes.chevronRightIcon}
                color="inherit"
                aria-label="close drawer"
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            <div className={classes.filtersWrapper}>

              { categoryFilterItems && categoryFilterItems.length !== 0 ?
                <div>
                  <Filter 
                    classes={classes} 
                    i18n={i18n} 
                    handleSelectFilterItem={props.handleSelectFilterCategory} 
                    selectedItems={categorySelectedFilterItems} 
                    selectAllFilterItems={props.selectAllFilterItems} 
                    filterItems={categoryFilterItems} 
                    filterTitle='category'
                    showSelectAll={true}
                    open={false}
                  />

                  <div className={classes.divider}/>
                </div>
              : '' }

              { producerFilterItems && producerFilterItems.length !== 0 ?
                <div>
                  <Filter 
                    classes={classes} 
                    i18n={i18n} 
                    handleSelectFilterItem={props.handleSelectFilterProducer} 
                    selectedItems={producerSelectedFilterItems}
                    selectAllFilterItems={props.selectAllFilterItems}  
                    filterItems={producerFilterItems} 
                    filterTitle='producer'
                    showSelectAll={true}
                    open={false}
                   />

                  <div className={classes.divider}/>
                </div>
              : '' }
              
              { ((isAdmin || isManager) && contractorFilterItems && contractorFilterItems.length !== 0) ?
                <div>
                  <Filter 
                    classes={classes} 
                    i18n={i18n} 
                    handleSelectFilterItem={props.handleSelectFilterContractor} 
                    selectedItems={contractorSelectedFilterItems}
                    selectAllFilterItems={props.selectAllFilterItems}  
                    filterItems={contractorFilterItems} 
                    filterTitle='contractor'
                    showSelectAll={true}
                    open={false}
                   />

                  <div className={classes.divider}/>
                </div>
              : '' }
              
              { (clientGroupFilterItems && clientGroupFilterItems.length !== 0) ?
                <div>
                  <Filter 
                    classes={classes} 
                    i18n={i18n} 
                    handleSelectFilterItem={props.handleSelectFilterClientGroup} 
                    selectedItems={clientGroupSelectedFilterItems}
                    selectAllFilterItems={props.selectAllFilterItems}  
                    filterItems={clientGroupFilterItems} 
                    filterTitle='clientGroup'
                    showSelectAll={true}
                    open={true}
                   />

                  <div className={classes.divider}/>
                </div>
              : '' }
              
              { (managerFilterItems && managerFilterItems.length !== 0) ?
                <div>
                  <Filter 
                    classes={classes} 
                    i18n={i18n} 
                    handleSelectFilterItem={props.handleSelectFilterManager} 
                    selectedItems={managerSelectedFilterItems}
                    selectAllFilterItems={props.selectAllFilterItems}  
                    filterItems={managerFilterItems} 
                    filterTitle='manager'
                    showSelectAll={true}
                    open={true}
                   />

                  <div className={classes.divider}/>
                </div>
              : '' }

			  {allStatuses && 
			  <div>
              <Filter 
                classes={classes} 
                i18n={i18n} 
                handleSelectFilterItem={props.handleSelectFilterStatus} 
                selectedItems={statusSelectedFilterItems}
                selectAllFilterItems={props.selectAllFilterItems}  
                filterItems={props.allStatuses}  //["0", "1", "2", "6"]
                filterTitle={document.location.href.indexOf('/orders') == -1 ? 'status' : 'orderStatus'}
                showSelectAll={false}
                open={true}
               />
               
               <div className={classes.divider}/>
               </div>
              }  

              
              
              { warehouseFilterItems && warehouseFilterItems.length !== 0 ?
                <div>
                  <Filter 
                    classes={classes} 
                    i18n={i18n} 
                    handleSelectFilterItem={props.handleSelectFilterWarehouse} 
                    selectedItems={warehouseSelectedFilterItems}
                    selectAllFilterItems={props.selectAllFilterItems}  
                    filterItems={warehouseFilterItems} 
                    filterTitle='warehouse'
                    showSelectAll={true}
                    open={false}
                   />

                  <div className={classes.divider}/>
                </div>
              : '' }

              { maxPrice !== 1 && maxPrice !== undefined ?
                <div>
                  {/*<FilterSlider
                    classes={classes}
                    filterTitle={document.location.href.indexOf('/orders') == -1 ? 'price' : 'amount'}
                    i18n={i18n}
                    min={minPrice}
                    max={maxPrice}
                    minSelected={minPriceSelected}
                    maxSelected={maxPriceSelected}
                    handleSliderFilterChangeComplete={props.handlePriceFilterChangeComplete}
                    open={true}
                  />*/}
                   <FilterInterval
                    classes={classes}
                    filterTitle={document.location.href.indexOf('/orders') == -1 ? 'price' : 'amount'}
                    i18n={i18n}
                    min={minPrice}
                    max={maxPrice}
                    minSelected={minPriceSelected}
                    maxSelected={maxPriceSelected}
                    handleSliderFilterChangeComplete={props.handlePriceFilterChangeComplete}
                    open={true}
                        />
                   <div className={classes.divider}/>
                   
                </div>
              : '' }

			  {getSettingValueAsBoolean('PRICELIST_SHOW_DATE', true) && maxAvailabilityDays !== undefined && 
                    <>
                    <FilterInterval
                    classes={classes}
                    filterTitle='availabilityDaysCount'
                    i18n={i18n}
                    min={minAvailabilityDays}
                    max={maxAvailabilityDays}
                    minSelected={minAvailabilityDaysSelected}
                    maxSelected={maxAvailabilityDaysSelected}
                    handleSliderFilterChangeComplete={props.handleAvailabilityDaysFilterChangeComplete}
                    open={true}
                        />
                   
                  <div className={classes.divider}/>
              </>}
              
              { getSettingValueAsBoolean('PRICELIST_SHOW_IS_RECEIPT_CURRENCY_DIFFERENT', false) && isReceiptCurrencyDifferentFilterItems && isReceiptCurrencyDifferentFilterItems.length !== 0 ?
                <div>
                  <Filter 
                    classes={classes} 
                    i18n={i18n} 
                    handleSelectFilterItem={props.handleSelectFilterIsReceiptCurrencyDifferent} 
                    selectedItems={isReceiptCurrencyDifferentSelected}
                    selectAllFilterItems={props.selectAllFilterItems}  
                    filterItems={isReceiptCurrencyDifferentFilterItems} 
                    filterTitle='isReceiptCurrencyDifferent'
                    showSelectAll={true}
                    open={true}
                   />

                  <div className={classes.divider}/>
                </div>
              : '' }
              
              { getSettingValueAsBoolean('PRICELIST_SHOW_MARKUP', false) && maxMarkup !== 1 && maxMarkup !== undefined ?
                <div>
                  {/*<FilterSlider
                    classes={classes}
                    filterTitle={getSettingValue('PRICELIST_MARKUP_COL_NAME', i18n.t('markup'))}
                    i18n={i18n}
                    min={minMarkup}
                    max={maxMarkup}
                    minSelected={minMarkupSelected}
                    maxSelected={maxMarkupSelected}
                    handleSliderFilterChangeComplete={props.handleMarkupFilterChangeComplete}
                    open={true}
                  />*/}
                   <FilterInterval
                    classes={classes}
                    filterTitle={getSettingValue('PRICELIST_MARKUP_COL_NAME', i18n.t('markup'))}
                    i18n={i18n}
                    min={minMarkup}
                    max={maxMarkup}
                    minSelected={minMarkupSelected}
                    maxSelected={maxMarkupSelected}
                    handleSliderFilterChangeComplete={props.handleMarkupFilterChangeComplete}
                    open={true}
                        />
                   <div className={classes.divider}/>
                   
                </div>
              : '' }
                   
              { featureFilters && Object.keys(featureFilters).length !== 0 &&

                Object.keys(featureFilters).map((feature, i) => {
                  var featureParts = feature.split(", ");
                  var featureId = featureParts[0];
                  var featureName = featureParts[1];
                  var isSlider = featureParts[2];
                  var orderPriority = featureParts[3];
                  var isPrimary = featureParts[4];
                  var isExpanded = featureParts[5];

                  var featureValues = [];

                  if (isSlider === 'false') {
                    Object.keys(featureFilters[feature]).map((featureValueId, i) => {
                      featureValues.push({ "id" : featureValueId, "val" : featureFilters[feature][featureValueId]});
                    })
                  }

                  return(
                    isSlider === 'true' ?
                      <div>
                        {false &&
                        <FilterSlider
                          classes={classes}
                          filterTitle={featureName}
                          filterName={featureId}
                          i18n={i18n}
                          min={parseFloat(featureFilters[feature][0])}
                          max={parseFloat(featureFilters[feature][1])}
                          minSelected={selectedFeatureFilters["from" + featureId]}
                          maxSelected={selectedFeatureFilters["to" + featureId]}
                          handleSliderFilterChangeComplete={props.handleFeatureFilterChangeComplete}
                          open={isExpanded}
                        />
                        }
                        <FilterInterval
                          classes={classes}
                          filterTitle={featureName}
                          filterName={featureId}
                          i18n={i18n}
                          min={parseFloat(featureFilters[feature][0])}
                          max={parseFloat(featureFilters[feature][1])}
                          minSelected={selectedFeatureFilters["from" + featureId]}
                          maxSelected={selectedFeatureFilters["to" + featureId]}
                          handleSliderFilterChangeComplete={props.handleFeatureFilterChangeComplete}
                          open={isExpanded}
                        />
                        <div className={classes.divider}/>
                      </div>
                    : 
                      <div>
                        <Filter 
                          classes={classes} 
                          i18n={i18n} 
                          handleSelectFilterItem={props.handleSelectFeatureFilter} 
                          selectedItems={selectedFeatureFilters["f" + featureId]}
                          selectAllFilterItems={props.selectAllFeatureFilterItems}  
                          filterItems={featureValues} 
                          filterTitle={featureName}
                          filterName={"f" + featureId}
                          showSelectAll={true}
                          open={isExpanded}
                        />

                        <div className={classes.divider}/>
                      </div>
                  )
                })
              }           
            </div>
          </div>
        </Drawer>
    
    </div>
  );
};

FilterSidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FilterSidebar);
