import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect, RedirectRoute } from "react-router-dom";
import hist from "./history";

// core components
import Admin from "layouts/Admin.jsx";
import LogIn from "layouts/LogIn.jsx";

import "assets/css/material-dashboard-react.css?v=1.6.0";

const AuthRoute = ({component: Component, ...rest}) => {

  if (localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined) {
      return <Route {...rest} component={Component} />
  }
  return <Redirect to="/login" />
}

const injectYandexMetrika = () => {
	return <div><img src="https://mc.yandex.ru/watch/55375996" style="position:absolute; left:-9999px;" alt="" /></div>;
};

ReactDOM.render(
  <Router history={hist}>
    <Switch>
    	<Route path="/login" component={LogIn} />
      <AuthRoute path="/" component={Admin} />
      <Redirect from="/" to="/dashboard" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
