import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Check from "@material-ui/icons/Check";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';

import avatar from "assets/img/faces/marc.jpg";

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost } from "variables/common.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class SupplierSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: []
    }
  }

  componentDidMount = () => {

    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/settings?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierId=" + this.props.supplierId;

    var that = this;

    var cb = function(data) {

      that.setState({ settings: data });

    };
	this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  updateSupplierSettings = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/settings/update?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    var body = "supplierId=" + this.props.supplierId;
    
    var kvpairs = [];
	var inputs = Array.from(document.getElementsByTagName('input'));
	var textAreas = Array.from(document.getElementsByTagName('textarea'));
	var allElements = inputs.concat(textAreas);
	for ( var i = 0; i < allElements.length; i++ ) {
	   var e = allElements[i];
	   if (e.name) {
	   	  kvpairs.push(encodeURIComponent(e.name) + "=" + encodeURIComponent(e.value));
	   }
	}
	body += "&" + kvpairs.join("&");

    var that = this;
    var cb = function(data) { 
      if (data === "OK") {
        that.setFieldState("supplierSettingsUpdateSuccess");
      }
    };

    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose);

  }

  render() {
    const { classes, i18n, isAdmin, isClientAdmin, isSupervisor } = this.props;
    
    if (!isSupervisor && !isAdmin) {
    	return <Redirect to='/pricelist'/>
    }
    
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{i18n.t('settings')}</h4>
              </CardHeader>
              <CardBody style={{maxHeight: "70vh", overflow: "auto"}}>
              	
            { this.state.settings !== null && this.state.settings !== undefined &&
              this.state.settings.map(setting => {
                return(

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t(setting.code)}
                      id={'setting-' + setting.code}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isAdmin,
                        style: {color: "rgb(84, 84, 84)"},
                        name: setting.code,
                        defaultValue: setting.value,
                        multiline: (setting.code == 'WELCOME_TEXT'),
                      }}
                    />
                  </GridItem>
                 
                </GridContainer>
                
                )
              })
            }
    
              </CardBody>
              { (isSupervisor || isAdmin) &&
                <CardFooter>
                  <Button 
                    color="primary"
                    onClick={this.updateSupplierSettings}
                  >{i18n.t('save')}</Button>
                  {this.state.supplierSettingsUpdateSuccess && <Check style={{color: "green"}} />}
                </CardFooter>
              }
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SupplierSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SupplierSettings);
