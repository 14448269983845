import * as constants from "variables/constants.jsx";
export {
  doAjax,
  doAjaxPost,
  addParameter,
  removeParameter,
  getParams,
  checkboxToggle,
  getSettingValue,
  getSettingValueAsBoolean
}

const errorCommonMessage = "Произошла ошибка. Пожалуйста, обратитесь в поддержку.";

var progressBarCount = 0;

function getSettingValueAsBoolean(code, defaultValue) {

	var v = getSettingValue(code, defaultValue);
	
	return v !== undefined && v == 1;
}
function getSettingValue(code, defaultValue) {
	
	var settingsJson = localStorage.getItem('supplierSettings');
	if (settingsJson) {
		var settings = JSON.parse(settingsJson);
		for (var k in settings) {
			if (settings[k].code == code) {
				var v = settings[k].value;
				if (v !== undefined && v.length > 0) {
					return v;
				}
			}
		}
	}
	
	return defaultValue;
}


function doAjaxPost(url, body, cb, progressBarHandler, warningHandler, errorCB) {

	if (progressBarHandler) {
		progressBarCount++;
	}
	
	fetch(url,
	{
	    method: "POST",
	    headers: {  
	      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
	    },  
		body: body
	})
	.then(function(response) {
		try {
			return response.json();
		} catch (e) {
      		console.log(e);
      		return response;
    	}
    })
    .then(function(json) {
    	var err = errorCommonMessage;
		if (json && json.error) {
			if (json.error.indexOf('а') > -1 || json.error.indexOf('и') > -1 || json.error.indexOf('е') > -1) {
				err = json.error;
			}
		} else {
			return json;
		}
		if (warningHandler !== undefined && warningHandler != null) {
			warningHandler(err, 'error', errorCB);
		} else {
			alert(err);
		}
//		if (errorCB !== undefined) {
//			errorCB(err);
//		}
		throw null;
    })
	.then(cb !== null && cb)
	.catch((error) => { console.trace(); if (warningHandler !== undefined && warningHandler != null && error && error.message) {console.error(error.message); warningHandler(error.message, 'error');} })
	.then(() => { if (progressBarHandler) {progressBarCount--;} if (progressBarCount <= 0 && progressBarHandler) {progressBarCount = 0; progressBarHandler();} });
}

function doAjax(url, cb, progressBarHandler, warningHandler) {

	if (progressBarHandler) {
		progressBarCount++;
	}
	
	fetch(url)
    .then(function(response) {
		return response.json();
    })
    .then(function(json) {
    	var err = errorCommonMessage;
		if (json && json.error) {
			if (json.error.indexOf('а') > -1 || json.error.indexOf('и') > -1 || json.error.indexOf('е') > -1) {
				err = json.error;
			}
		} else {
			return json;
		}
		if (warningHandler !== undefined) {
			warningHandler(err, 'error');
		} else {
			alert(err);
		}
		throw null;
    })
  	.then(cb !== null && cb)
	.catch((error) => { console.trace(); if (warningHandler !== undefined && error && error.message) {console.error(error.message); warningHandler(error.message, 'error');} })
	.then(() => { if (progressBarHandler) {progressBarCount--;} if (progressBarCount <= 0 && progressBarHandler) {progressBarCount = 0; progressBarHandler();} });
}

function addParameter(paramName, paramValue) {

	// removeParameter(paramName);
	
    var url = window.location.href;

    if (url.split('?').length < 2) {
    	url += '?';
    }

    if (url.indexOf(paramName + '=' + paramValue + '&') == -1) {
    	if (!url.endsWith('&')) {
    		url += '&';
    	}
    	url += paramName + '=' + encodeURIComponent(paramValue);
    }
    url += '&';
   
    window.history.pushState(null, null, url);
}

function removeParameter(paramName, paramValue) {
	var re = new RegExp(paramName + '=' + paramValue + '&', "g");
	if (paramName.includes('model') || paramName.includes('minPrice') || paramName.includes('maxPrice')) {
	  re = new RegExp(paramName + '=[^&]*&', "g");
	}
    try {	
	  var url = decodeURI(window.location.href).replace(re, '');
  	  window.history.pushState(null, null, url);
    } catch (e) {
      console.log(e)
    }
}

function getParams(url) {

    var params = {};
    params['featureFilters'] = {}

    var vars = url.split('?')[1] === undefined ? [] : url.split('?')[1].split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] === "category" || pair[0] === "producer" || pair[0] === "status" || pair[0] === "warehouse" || pair[0] === "contractor" || pair[0] === "clientGroup" || pair[0] === "manager" || pair[0] === "isReceiptCurrencyDifferent") {
        var values = [];
        if (params[pair[0]] !== undefined) {
          params[pair[0]].map(function(value) {
            values.push(value);
          });
        }
        
        try {
          values.push(decodeURIComponent(pair[1]));
	    } catch (e) {
	      console.log(e)
	    }
        params[pair[0]] = values;
      } else if (pair[0].match(/f\d+/g) || pair[0].match(/from\d+/g) || pair[0].match(/to\d+/g)) {
      	var values = [];
        if (params['featureFilters'][pair[0]] !== undefined) {
          params['featureFilters'][pair[0]].map(function(value) {
            values.push(value);
          });
        }
        values.push(decodeURIComponent(pair[1]));
        params['featureFilters'][pair[0]] = values;
      } else {
    	  try {
    		  params[pair[0]] = decodeURIComponent(pair[1]);
	      } catch (e) {
	    	  console.log(e)
	      }
      }
    }

    return params;
};

function checkboxToggle(item, list) {
    const selectedIndex = list.indexOf(item);
    var newList = [];

    if (selectedIndex === -1) {
      newList = newList.concat(list, item);
    } else if (selectedIndex === 0) {
      newList = newList.concat(list.slice(1));
    } else if (newList === list.length - 1) {
      newList = newList.concat(list.slice(0, -1));
    } else if (selectedIndex > 0) {
      newList = newList.concat(
        list.slice(0, selectedIndex),
        list.slice(selectedIndex + 1)
      );
    }
    return newList;
}