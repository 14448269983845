import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Fade from '@material-ui/core/Fade';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import GetApp from '@material-ui/icons/GetApp';
import TablePagination from '@material-ui/core/TablePagination';
import Add from '@material-ui/icons/Add';

import Cancel from "@material-ui/icons/Cancel";
import Close from "@material-ui/icons/Close";
import Modal from '@material-ui/core/Modal';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
import Link from '@material-ui/core/Link';
import Check from "@material-ui/icons/Check";
import Downshift from 'downshift';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import deburr from 'lodash/deburr';
import Create from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import avatar from "assets/img/faces/marc.jpg";

import Moment from 'react-moment';

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FilterSidebar from "components/Sidebar/FilterSidebar.jsx";
import FilterListIcon from '@material-ui/icons/FilterList';

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost,removeParameter, checkboxToggle, getParams, addParameter } from "variables/common.js";

const styles = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  adminCheckbox: {
  	marginTop: "37px"
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  editLightbox: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  cancelIconWrapper: {
    right: "10px",
    top: "10px",
    position: "absolute",
  },
  cancelIcon: {
    width: "15px",
    height: "15px",
    fontSize: "15px"
  },  
  editCell: {
    minWidth: "30px",
    width: "30px",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    whiteSpace: "nowrap",
    textAlign: "center"
  },
  adminCell: {
  	margin: 0,
// width: "1px",
	padding: 0,
  },
  editButton: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "1px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  editIcon: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "21px",
    color: "#848484",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  rightCell: {
    minWidth: "10px",
    width: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",
    whiteSpace: "nowrap"  
  },
  tableWrapper: {
    "& > table > tbody": {
      overflow: "auto",
      maxHeight: "60vh"
    },
    "& > table td": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table td:nth-child(1)": { 
      paddingLeft: "24px"
    },
    "& > table th": {
      paddingLeft: "10px",
      paddingRight: "15px"
    },
    "& > table th:nth-child(1)": { 
      paddingLeft: "24px",	
    },
    maxHeight: "calc(100vh - 60px - 64px - 56px - 74px)",
    overflow: "auto",
  },
});

function getEditModalStyle() {
  const vertical = 5;
  const horizontal = 20;

  return {
    top: `${vertical}%`,
// bottom: `${vertical}%`,
    left: `${horizontal}%`,
    flexGrow: 1,
    maxHeight: "90vh",
    minHeight: "inherit",
    overflowY: "auto"
  };
}

class EditLightbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saveSuccess: false,
      emptyLogin: false,
      emptyPassword: false,
      emptyEmail: false,
      emptySupplierClient: false,
      cardsInputOpen: false,
      clients: [],
      supplierId: localStorage.getItem('supplierId'),
      isUserAdmin: null,
      isManager: null,
      contractorId: null,
      sendAccessInfo: false,
      isBlocked: null
    };
    this.escFunction = this.escFunction.bind(this);
  };
/*  shouldComponentUpdate(nextProps) {
     if( nextProps.card !== this.props.card ){
	      return true
     }
	 return true;
  } */
  save = () => {

    var login = document.getElementById('edit-lb-login').value;
   // var password = nulldocument.getElementById('edit-lb-password').value;
    var email = document.getElementById('edit-lb-email').value;

    if (login === "" || email === "") { // || password === ""
      if (login === "") {
        this.setFieldState("emptyLogin");
      }
// if (password === "") {
// this.setFieldState("emptyPassword");
// }
      if (email === "") {
        this.setFieldState("emptyEmail");
      }
      return;
    }
    
    if (this.props.isManager) {
    	var supplierClientId = document.getElementById('edit-lb-supplierClientId').value;
    	if (!supplierClientId) {
    		this.setFieldState("emptySupplierClient");
    		return;
    	}
    }

	var user = JSON.parse(localStorage.getItem('user')).value;

    const { item } = this.props;
    var url = constants.apiBaseUrl + '/b2b/supplier/update-user?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = (item !== null ? "&userId=" + item.user.id : "") + "&supplierId=" + this.state.supplierId + "&principalId=" + user.id + "&login=" + document.getElementById('edit-lb-login').value +
    		   "&firstName=" + document.getElementById('edit-lb-firstName').value + 
    		   "&lastName=" + document.getElementById('edit-lb-lastName').value + "&email=" + document.getElementById('edit-lb-email').value + 
    		   "&skype=" + document.getElementById('edit-lb-skype').value + "&phoneWork=" + document.getElementById('edit-lb-phoneWork').value + 
    		   "&supplierClientId=" + (this.props.isAdmin || this.props.isManager ? document.getElementById('edit-lb-supplierClientId').value : this.props.supplierClientId) +
    		   (item !== null && item.id ? "&supplierUserId=" + item.id : "&action=add") +
    		   "&isClientAdmin=" + (document.getElementById('edit-lb-isClientAdmin').checked ? 1 : 0) + 
    		   (document.getElementById('edit-lb-isManager') ? ("&isManager=" + (document.getElementById('edit-lb-isManager').checked ? 1 : 0)) : "") +
    		   (document.getElementById('edit-lb-isAdmin') ? ("&isAdmin=" + (document.getElementById('edit-lb-isAdmin').checked ? 1 : 0)) : "") +
    		   "&sendAccessInfo=" + (this.state.sendAccessInfo ? 1 : 0) + "&generateNewPassword=" + (this.state.generateNewPassword ? 1 : 0)
 		       + "&isBlocked=" + (document.getElementById('edit-lb-isBlocked').checked ? 1 : 0) ;
	// "&password=" + encodeURIComponent(password) +

    var that = this;
    var cb = function(data) {
      if (data) {
        that.setFieldState("saveSuccess");
        that.props.updateTableData();
        that.handleClose();
      }
    }
    var errorCB = function(message) {
    	if (message) {
    		if (message == 'Email уже занят') {
    			if (window.confirm('Пользователь ' + document.getElementById('edit-lb-email').value + ' уже есть в ZOOMOS. Добавить его в B2B-кабинет?')) {
    				var url = constants.apiBaseUrl + '/b2b/supplier/add-user-by-email?key=' + constants.apiKey + '&upd=' + new Date().getTime();
 					var body = "supplierId=" + that.state.supplierId + "&principalId=" + user.id + "&email=" + document.getElementById('edit-lb-email').value + 
 								"&supplierClientId=" + (that.props.isAdmin || that.props.isManager ? document.getElementById('edit-lb-supplierClientId').value : that.props.supplierClientId) +
 								"&isClientAdmin=" + (document.getElementById('edit-lb-isClientAdmin').checked ? 1 : 0);
 					
 					var cb = function(data) {
				      if (data) {
				       // that.setFieldState("saveSuccess");
				        that.props.handleShowSnackbar('Добавлен пользователь ' + data, 'success');
				        that.props.updateTableData();
				        that.handleClose();
				      }
				    }
 					
 					that.props.handleProgressBarOpen();
   					doAjaxPost(url, body, cb, that.props.handleProgressBarClose, that.props.handleShowSnackbar);
    			}
    		}
    	}
    }
    
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, errorCB);
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  handleContractorSelected = event => {
    console.log(event.target.value );
    this.setState({
        contractorId: event.target.value
      });
    if (event.target.value == '') {
    	this.state.isUserAdmin = false;
    } else {
    	this.state.isManager = false;
    	this.state.isAdmin = false;
    }
  }
  
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
    
    if (event.target.checked) {
    	if (name == 'isManager') {
    		this.state.isUserAdmin = false;
    	}
    	if (name == 'isUserAdmin') {
    		this.state.isManager = false;
    		this.state.isAdmin = false;
    	}
    	if (name == 'generateNewPassword') {
    		this.state.sendAccessInfo = true;
    	}
    }
  };

  componentDidMount = () => {
  
  	document.addEventListener("keydown", this.escFunction, false);
  
    var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/client-ids?key=' + constants.apiKey + '&supplierId=' + this.state.supplierId +
    			 (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "") + '&sortBy=name' + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    var that = this;
    var cb = function(data) { 
        var url = constants.apiBaseUrl + '/b2b/supplier/clients?key=' + constants.apiKey + '&supplierId=' + that.state.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
        if (data && data.ids && data.ids.length > 0) {
			var body = '';
	        for (var k in data.ids) {
	        	body += '&ids=' + data.ids[k];
	        }
		    var that2 = that;
		    var cb = function(data) {
		      that2.setState({ clients: data });
		    };
		    that.props.handleProgressBarOpen();
		    doAjaxPost(url, body, cb, that.props.handleProgressBarClose);
	    }
    };
    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose);

  }
  
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }
  
  handleOpen = event => {
  
  	if (this.props.item) {
		this.state.isUserAdmin = (this.props.item.isAdmin == 1);
		this.state.isManager = (this.props.item.isManager == 1);
		this.state.isBlocked = (this.props.item.isBlocked == 1);
		this.state.contractorId = this.props.item.supplierClientId;
  	} 

	this.state.sendAccessInfo = (this.props.item === null);
   
  }

  handleClose = event => {
    this.setState({ cardsInputOpen: false });
    this.setState({ card: null });
    this.state.isUserAdmin = null;
    this.state.isManager = null;
    this.state.isAdmin = null;
    this.state.isBlocked = null;
    this.state.contractorId = null;
    this.props.handleClose();
    this.state.saveSuccess = false;
    this.state.sendAccessInfo = false;
    this.state.generateNewPassword = false;
  }
  
  setSendAccessInfo = event => {
  
  	this.state.sendAccessInfo = true;
    this.setState({ state: this.state });
  }
  
  
  escFunction(event){
    if(event.keyCode === 27) {
      this.handleClose();
    }
  }

  removeCard = () => {
    this.setState({ cardsInputOpen: false }); 
    this.props.removeCard();
  }

  setCard = id => {
    this.setState({ cardsInputOpen: false }); 
    this.props.setCard(id);
  }

  render() {
    const { i18n, classes, item, open, card, isAdmin, isManager, isClientAdmin, isSupervisor, isBlocked } = this.props;
    const modalStyle = getEditModalStyle();

    return (
      <div>
        <Modal
          open={open}
          onOpen={this.props.handleOpen}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox}>
            <Typography variant="h6" id="tableTitle">
              { item === null ? i18n.t('adding') : i18n.t('edition')}
            </Typography>
            <IconButton 
              onClick={event => this.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            <div style={{margin: "auto"}}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('login') + '*'}
                      id="edit-lb-login"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.user.login : "",
                        onChange: this.setSendAccessInfo,
                        inputProps: {
                          "maxlength": "50"
                      	}
                      }}
					  error={this.state.emptyLogin}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  	{/*
						 * <CustomInput labelText={i18n.t('password') + '*'}
						 * id="edit-lb-password" formControlProps={{ fullWidth:
						 * true }} inputProps={{ defaultValue: item !== null ?
						 * item.user.passwordDecrypted : "" }}
						 * error={this.state.emptyPassword} />
						 */}
                    
                    {((isAdmin || isManager || isClientAdmin) && item) &&
		                <FormControlLabel className={classes.filterCheckbox} style={{marginTop: "37px"}}
		                    control={
		                      <input id="edit-lb-generateNewPassword" type="checkbox" checked={this.state.generateNewPassword} style={{transform: "scale(1.5)", margin: "16px"}} onChange={this.handleChange('generateNewPassword')} 
		                      		 disabled={false}
		                      		 defaultChecked={item === null ? true : false}
		                      		  /> 
		                    }
		                    label={i18n.t('generateNewPassword')}
		                  />
		                } 
                    
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('firstName') + ''}
                      id="edit-lb-firstName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.user.firstName : "",
                        inputProps: {
                          "maxlength": "50"
                      	}
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('lastName')}
                      id="edit-lb-lastName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.user.lastName : "",
                        inputProps: {
                          "maxlength": "50"
                      	}

                      }}
					
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('email') + '*'}
                      id="edit-lb-email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.user.email : "",
                        inputProps: {
                          "maxlength": "50"
                      	}
                      }}
                      error={this.state.emptyEmail}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('skype')}
                      id="edit-lb-skype"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null && item.userContacts ? item.userContacts.skype : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('phoneWork')}
                      id="edit-lb-phoneWork"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null && item.userContacts ? item.userContacts.phoneWork : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                {(isAdmin || isManager) &&	  
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true" error={this.state.emptySupplierClient} >
                      <InputLabel shrink htmlFor="edit-lb-contractor" className={classes.labelRoot}>
                        {i18n.t('contractor')}
                      </InputLabel>
                      <NativeSelect
                        onChange={event => this.handleContractorSelected(event)}
                        value={this.state.contractorId}
                        input={ 
                                <Input 
                                  id="edit-lb-supplierClientId"
                                  
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: classes.disabled,
                                    underline: classes.underline
                                  }}
                                  inputProps={{
                                    defaultValue: item !== null && !this.state.isManager ? item.supplierClientId : "",
                                    disabled: this.state.isManager || this.state.isAdmin,
                                    
                                  }}
                                />
                              }
                      >
                      	<option />
                      	
                      	
                      	{this.state.clients.map(
            				client => (
            				(true) && [
		                        <option value={client.id}>
		                          {client.name}
		                        </option>
                      	    ]
                        ))}
                        
                        
                      </NativeSelect>
                    </FormControl>
                  </GridItem>
				}
				
				{(isAdmin || isManager || isClientAdmin) &&
                  <GridItem xs={12} sm={12} md={6}>
		                <FormControlLabel className={classes.adminCheckbox}
		                    control={
		                      <input id="edit-lb-isClientAdmin" type="checkbox" checked={this.state.isUserAdmin} style={{transform: "scale(1.5)", margin: "16px"}} onChange={this.handleChange('isUserAdmin')} 
		                      		disabled={this.state.isManager || (this.state.contractorId == null && !this.props.supplierClientId && (!item || item.supplierClientId == null)) || this.state.contractorId == ''}
		                      		defaultChecked={item && item.isAdmin == 1 && item.supplierClientId > 0} /> 
		                    }
		                    label={isAdmin ? i18n.t('administratorOfContractor') : i18n.t('administrator')}
		                  />
		                {false &&  <FormControlLabel className={classes.filterCheckbox}
		                    control={
		                      <Checkbox checked={this.state.isAdmin == 1} /> 
		                    }
		                    label={i18n.t('administrator')}
		                  />
		                }
                  </GridItem>
                }  
				
                </GridContainer>
                
                {(isAdmin) &&
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2} style={{marginTop: "15px"}}>
		                
		                <FormControlLabel className={classes.filterCheckbox}
		                    control={
		                      <input id="edit-lb-isManager" type="checkbox" checked={this.state.isManager} style={{transform: "scale(1.5)", margin: "16px"}} onChange={this.handleChange('isManager')} 
		                      		 disabled={this.state.isUserAdmin || (this.state.contractorId > 0 || (this.state.contractorId == null && item && item.supplierClientId > 0))}
		                      		 defaultChecked={item && item.supplierClientId == null && item.isManager == 1} /> 
		                    }
		                    label={i18n.t('manager')}
		                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{marginTop: "15px"}}>
		                
		                <FormControlLabel className={classes.filterCheckbox}
		                    control={
		                      <input id="edit-lb-isAdmin" type="checkbox" checked={this.state.isAdmin} style={{transform: "scale(1.5)", margin: "16px"}} onChange={this.handleChange('isAdmin')}
		                      		 disabled={this.state.isUserAdmin || (this.state.contractorId > 0 || (this.state.contractorId == null && item && item.supplierClientId > 0))}
		                      		 defaultChecked={item && item.supplierClientId == null && item.user && item.user.userRoles && item.user.userRoles.map(function(item) {return item['code'];}).indexOf('ROLE_SUPPLIER_ADMIN') > -1} /> 
		                    }
		                    label={i18n.t('administrator')}
		                  />
                  </GridItem>
                </GridContainer>
                }  
                
                <GridContainer>
                {(isAdmin || isManager || isClientAdmin) &&
                  <GridItem xs={12} sm={12} md={12} style={{marginTop: "15px"}}>
		                {(isAdmin || isManager || isClientAdmin) &&
		                <FormControlLabel className={classes.filterCheckbox}
		                    control={
		                      <input id="edit-lb-sendAccessInfo" type="checkbox" checked={this.state.sendAccessInfo} style={{transform: "scale(1.5)", marginLeft: "16px", marginRight: "16px"}} onChange={this.handleChange('sendAccessInfo')} 
		                      		 disabled={false}
		                      		 defaultChecked={item === null ? true : false}
		                      		  /> 
		                    }
		                    label={i18n.t('sendToUserAccessInfo')}
		                  />
		                } 
                  </GridItem>
                }  
                </GridContainer>

		                {(isAdmin || isManager || isClientAdmin) &&
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2} style={{marginTop: "15px"}}>
		  	                  <FormControlLabel className={classes.filterCheckbox}
		                    control={
		                      <input id="edit-lb-isBlocked" type="checkbox" checked={this.state.isBlocked} style={{transform: "scale(1.5)", margin: "16px"}} 
		                      		 defaultChecked={item && item.isBlocked == 1} /> 
		                    }
		                    label={i18n.t('block')}
		                    />
                  </GridItem>
                </GridContainer>
				                }
                
                <GridContainer style={{  }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{marginTop: "25px"}}>
                      <CustomButton 
                        color="primary"
                        onClick={this.save}
                        disabled={item && 
                        			(item.userId == 4484 || item.userId == 4485 || item.userId == 22 || item.userId == 142 || item.userId == 1787 || item.userId == 1325 || item.userId == 4484 || item.userId == 4485 || item.userId == 4747) 
                        			? true : false}
                      >
                        {item === null ? i18n.t('add') : i18n.t('save')}
                      </CustomButton>
                      {this.state.saveSuccess && <Check style={{color: "green", marginLeft: "25px", verticalAlign: "middle"}} />}
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, sortDirection, sortBy, numSelected, rowCount, i18n, classes, isAdmin, supplierClientId, isClientAdmin, isSupervisor, isBlocked } = this.props;

    const rows = [
      { id: 'login', numeric: false, disablePadding: false, label: i18n.t('login'), sortable: false },
      { id: 'name', numeric: false, disablePadding: false, label: i18n.t('name'), sortable: false },
      { id: 'contractor', numeric: false, disablePadding: false, label: i18n.t('contractor'), sortable: false, hidden: (supplierClientId ? true : false) },
      { id: 'administrator', align: 'center', disablePadding: false, label: i18n.t('administrator'), sortable: false },
      { id: 'manager', align: 'center', disablePadding: false, label: i18n.t('manager'), sortable: false, hidden: (!(!supplierClientId && isAdmin) ? true : false) },
      { id: 'isBlocked', align: 'center', numeric: true, disablePadding: false, label: i18n.t('isBlocked'), sortable: false },
      { id: 'supplierUserDateAdd', numeric: true, disablePadding: false, label: i18n.t('supplierUserDateAdd'), sortable: false },
      { id: 'lastLoginDate', numeric: true, disablePadding: ((isAdmin || isClientAdmin) ? true : false), label: i18n.t('lastLoginDate'), sortable: false },
    ];

    return (
      <TableHead>
        <TableRow >
          {rows.map(
            row => (
            	(row.hidden === undefined || !row.hidden ? [
              <TableCell
                key={row.id}
                align={row.align ? row.align : (row.numeric ? 'right' : 'left')}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={sortBy === row.id || (row.field && sortBy === row.field) ? sortDirection : false}
                className={classes.headerCell}
              >
                {row.label !== '' ?
                [
                	<div>
                	{row.sortable !== false ?
	                <Tooltip
	                  title={i18n.t('sort')}
	                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
	                  enterDelay={300}
	                >
                    <TableSortLabel
                      active={sortBy === row.id || (row.field && sortBy === row.field)}
                      direction={sortDirection}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                    </Tooltip>
                    : <div>{row.label}</div>
                    }
                    </div>
                
                ]
                : null}
              </TableCell>
              ] : ""
              )
            ),
            this,
          )}
          {(this.props.isAdmin || this.props.isClientAdmin) &&
          <> 
          <TableCell 
            className={classes.editCell}
            key='edit'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.editCell}
            key='delete'
            align='left'
            padding='none'>
            {null}
          </TableCell>
           </>
          }
          <TableCell 
	          className={classes.rightCell}
	          padding='none'>
	          {null}
	        </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },	
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "table",
	minWidth: "150px",
    textAlign: "right"
  },
});

let EnhancedTableToolbar = props => {
  const { i18n, numSelected, classes, isAdmin, isManager, isClientAdmin, isSupervisor } = props;

  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {i18n.t('users')}
        </Typography>
      </div>
	  <div className={classes.spacer} />

	  <Fade in={props.showSearchField}>
			  <div elevation={8}>
			      {props.showSearchField && <TextField 
			      id="standard-search" 
			      points="0,100 50,00, 100,100" 
			      className={"searchField"} 
			      placeholder={i18n.t('search')} 
			      onChange={props.handleChangeSearch} 
			      type="search" 
			      />}
			  </div>
		</Fade>
      	<div className={classes.actions}>
				<Tooltip title={i18n.t('search')}>
					<IconButton id="search" style={{ display: "inline-block" }} aria-label="Search" href="#" onClick={props.handleSearchClick}>
						<SearchIcon />
					</IconButton>
				</Tooltip>
      	  	{false &&
      	  	<Tooltip title={ i18n.t('download') }>
              <IconButton id="download-users" style={{display: "inline-block"}} aria-label="Download" href="#">
                <GetApp />
              </IconButton>
            </Tooltip>
            }
            { (isAdmin || isClientAdmin || isManager) && 
              <Tooltip title={ i18n.t('add') }>
                <IconButton style={{display: "inline-block"}} aria-label="Add" onClick={event => props.addClick(null)}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
					        <Tooltip title={ i18n.t('filters') }>
              <IconButton style={{display: "inline-block"}} aria-label="Filter list" onClick={props.handleFilterClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
      	  </div>
      </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class SupplierUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierUsers: [],
      sortDirection: 'desc',
      sortBy: 'dateAdd',
      page: 0,
      rowsPerPage: 50,
      editLBOpen: false,
      editLBItem: null,
      card: null,
	  usersIds:[],
	  contractorFilterItems: [],
	  contractorSelectedFilterItems:[],
	  request: '',
	  showSearchField: false,
    }
  }

  updateFilterItems = () => {
  
    const { supplierId, isAdmin, isClientAdmin, edit } = this.props;
  
    var supplierClient = null;
    if (localStorage.getItem('supplierClient')) {
	    supplierClient = JSON.parse(localStorage.getItem('supplierClient'));
    }

    var user = JSON.parse(localStorage.getItem('user')).value;
	var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/user-ids?key=' + constants.apiKey + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId
    				+ "&principalId=" + user.id
    				// + (!isAdmin ? "&userId=" + user.id : "")
    				+ (!isAdmin && supplierClient ? "&supplierClientId=" + supplierClient.id : "")
    				+ (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "")
    				+ "&sortBy=" + this.state.sortBy + "&sort=" + this.state.sortDirection; 

    var that = this;
    var cb = function(data) { 
      that.makeFilterItems(that, data.contractorFilterItems);
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }
  
  makeFilterItems = (that,  contractors) => {
	
	  if((!this.props.isAdmin && !this.props.isManager) || contractors.length === 0) { // ||
																						// managers.length
																						// >
																						// 100
        that.setState({ contractorFilterItems: [] });
      } else {
        that.setState({ contractorFilterItems: contractors }, () => {
        var newContractorSelectedFilterItems = [];
        var oldContractorSelectedFilterItems = that.state.contractorSelectedFilterItems;

        that.state.contractorSelectedFilterItems.map(function(contractor) {
          if (that.state.contractorFilterItems.indexOf(contractor) === -1) {
            removeParameter("contractor", contractor);
            newContractorSelectedFilterItems = checkboxToggle(contractor, oldContractorSelectedFilterItems);
            oldContractorSelectedFilterItems = newContractorSelectedFilterItems;
          } else {
            if(newContractorSelectedFilterItems.indexOf(contractor) === -1) {
              newContractorSelectedFilterItems.push(contractor);
            }
          }
        });

        that.setState({ contractorSelectedFilterItems: newContractorSelectedFilterItems });
      });
    }

  }
  init = () => {
    var params = getParams(window.location.href);

    var orderBy = params.orderBy === undefined ? 'category' : params.orderBy;
    var order = params.order === undefined ? 'asc' : params.order;
	var contractorSelectedFilterItems = params.contractor === undefined ? [] : params.contractor;
    this.setState({ 
      orderBy: orderBy,
      order: order,
     
	contractorSelectedFilterItems:contractorSelectedFilterItems
    }, () => { this.updateFilterItems(); this.getUsersIds(); });

    // this.getTotalPricelistSize();
   }

	componentDidMount() {
      this.init();
	   }

  handleSelectFilterContractor = (event, contractor) => {

    if (this.state.contractorSelectedFilterItems.indexOf(contractor) === -1) {
      addParameter("contractor", contractor);
    } else {
      removeParameter("contractor", contractor);
    }

    var newContractorSelectedFilterItems = checkboxToggle(contractor, this.state.contractorSelectedFilterItems);

    this.setState({ 
      contractorSelectedFilterItems: newContractorSelectedFilterItems,
      selectedFeatureFilters: {}
    }, () => this.getUsersIds());
    
  }	
 	
  handleChangePage = (event, page) => {
	      this.setState({ page : page }, () => this.getUsers());
  };

  handleChangeRowsPerPage = (event) => {
	      this.setState({ rowsPerPage: event.target.value }, () => this.getUsers());
  };
	
  getUsersIds = () => {
    const {  supplierId, isAdmin, edit } = this.props;
    var supplierClient = JSON.parse(localStorage.getItem('supplierClient'));
	var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
    var url = constants.apiBaseUrl + '/b2b/supplier/user-ids?key=' + constants.apiKey + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId
    				+ "&principalId=" + user.id
    				// + (!isAdmin ? "&userId=" + user.id : "")
    				+ (!isAdmin && supplierClient ? "&supplierClientId=" + supplierClient.id : "")
    				+ (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "")
    				+ "&sortBy=" + this.state.sortBy + "&sort=" + this.state.sortDirection; 


				    this.state.contractorSelectedFilterItems.map(function(contractorSelected) {
      url += "&contractor=" + encodeURIComponent(contractorSelected);
    });
	
	url += '&search=' + encodeURIComponent(this.state.request);

    var that = this;

    var cb = function(data) { 
     that.setState({ usersIds: data.ids }, () => that.getUsers());
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
    
    if (document.getElementById("download-clients")) {
      	document.getElementById("download-clients").href=(url.replace('/client-ids', '/' + JSON.parse(localStorage.getItem('supplierUser')).value.supplier.name + '/clients/xls'));
      	document.getElementById("download-clients").target = "_blank";
    }
 }
  

  getUsers = () => {
	const { supplierId, isAdmin, supplierClientId, isClientAdmin, isManager, isSupervisor } = this.props;
    const {  usersIds, page, rowsPerPage } = this.state;
    
    var user = JSON.parse(localStorage.getItem('user')).value;
    var supplierUser = JSON.parse(localStorage.getItem('supplierUser')).value;
    var url = constants.apiBaseUrl + '/b2b/supplier/users?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId +
    		  (!isAdmin && supplierClientId ? "&supplierClientId=" + supplierClientId : "") + (supplierUser.isManager == 1 ? "&supplierManagerUserId=" + supplierUser.id : "");

    usersIds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(id => {
      url += "&ids=" + id;	 
    	})

    var that = this;
    var cb = function(data) { 
      that.setState({ supplierUsers: data });
      that.setState({edit: ""});
    };

    this.props.handleProgressBarOpen();
    if (usersIds.length > 0) {
    	doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
   	 } else {
    		that.setState({ supplierUsers: [] });
    }
  }
	
	
  componentWillMount = () => {
	this.getUsersIds()  
  }
	
  handleDeleteClick = item => {
    const conf = window.confirm('Удалить пользователя?'); // {i18n.t('deleteUser?')}
    if (!conf) {
    	return;
    }
    
	var user = JSON.parse(localStorage.getItem('user')).value;
    var url = constants.apiBaseUrl + '/b2b/supplier/delete-user?key=' + constants.apiKey + '&upd=' + new Date().getTime();
    var body = "&supplierId=" + this.state.supplierId + "&principalId=" + user.id + "&supplierUserId=" + item.id;

    var that = this;
    var cb = function(data) {
      if (data) {
        that.componentDidMount();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);    
    
  }
  
  handleEditClick = item => {
	if (item === null) {
		this.setState({
     		editLBOpen: true
	    });
	}
    this.setState({
      // editLBOpen: true,
      editLBItem: item
    }, () => this.getCard(item)); 
  }

  handleEditLBClose = (event) => {
    this.setState({
      editLBOpen: false,
      editLBItem: null,
      card: null
    });
  }
  
  getCard = item => {
    if (this.state.editLBItem === null || this.state.editLBItem.id === undefined) {
      return;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + "/b2b/supplier/user?userId=" + item.userId + "&key=" + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    var that = this;
    var cb = function(data) {
	  item.userContacts = data.userContacts;
	  item.user.userRoles = data.userRoles;
      that.setState({
        card: data,
		editLBOpen: true
      });
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleClose = event => {
    this.setState({ cardsInputOpen: false });
    this.setState({ card: null }); 
    this.props.handleClose();
  }

  handleSearchClick = () => {
		this.setState({ showSearchField: !this.state.showSearchField });
	}
	
  handleChangeSearch = (event) => {
		var searchRequest = event.target.value;
		this.setState({ request: searchRequest }, () => this.getUsersIds());
	}

  handleFilterClick = () => {
    this.setState({ showFilterSidebar: true });
  }

  handleFiltersClose = () => {
    this.setState({ showFilterSidebar: false });
  }

  handleFilterToggle = () => {
    this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
  };  
  
  handlePriceFilterChangeComplete = () => {
    this.getUsersIds();
  };
  
  selectAllFilterItems = (filterName, items) => {

    var selectedListName = filterName + 'SelectedFilterItems';

    removeParameter(filterName, "[^&]*");

    var that = this;

    if (items.length > 0) {
      items.map(function(item) {
        addParameter(filterName, item);
      });
    }
    
    this.setState({ 
      [selectedListName]: items
    }, () => this.getUsersIds());
  }


  render() {
    const { classes, i18n, isAdmin, isManager, supplierClientId, isClientAdmin, isSupervisor } = this.props;
    const { rowsPerPage, page, supplierUsers, sortDirection, sortBy } = this.state;

    if (!isAdmin && !isClientAdmin && !isManager) {
      return <Redirect to='/pricelist'/>
    }

    return (
      <div>
      
      <EditLightbox 
          open={this.state.editLBOpen} 
          i18n={i18n} 
          classes={classes} 
          item={this.state.editLBItem} 
          handleClose={this.handleEditLBClose} 
          updateTableData={this.componentWillMount}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          handleShowSnackbar={this.props.handleShowSnackbar}
          removeCard={this.removeCard}
          setCard={this.setCard}
          card={this.state.card}
          isAdmin={isAdmin}
          isManager={isManager}
          isClientAdmin={isClientAdmin}
          supplierClientId={supplierClientId}
        />
       <ClickAwayListener onClickAway={this.handleFiltersClose}>
          <FilterSidebar
            handleFilterToggle={this.handleFilterToggle}
            open={this.state.showFilterSidebar}
            i18n={i18n}
            
            contractorFilterItems={this.state.contractorFilterItems}
            contractorSelectedFilterItems={this.state.contractorSelectedFilterItems}
            handleSelectFilterContractor={this.handleSelectFilterContractor}
            
            handlePriceFilterChangeComplete={this.handlePriceFilterChangeComplete}
            selectAllFilterItems={this.selectAllFilterItems}
            isAdmin={isAdmin}
            isManager={isManager}
            isClientAdmin={isClientAdmin}
           />
        </ClickAwayListener> 

            <Paper className={classes.root}>
			<EnhancedTableToolbar addClick={this.handleEditClick} i18n={i18n} isAdmin={isAdmin} isManager={isManager} isClientAdmin={isClientAdmin} handleSearchClick={this.handleSearchClick} showSearchField={this.state.showSearchField} handleChangeSearch={this.handleChangeSearch} handleFilterClick={this.handleFilterClick} />
            <div className={classes.tableWrapper}>
              <Table className={classNames(classes.table, "fixed-header-table")} size={'small'}>
                <EnhancedTableHead
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  onRequestSort={this.handleRequestSort}
                  i18n={i18n}
                  classes={classes}
                  isAdmin={isAdmin}
                  isClientAdmin={isClientAdmin}
                  supplierClientId={supplierClientId}
                />
                  <TableBody>
                    {this.state.supplierUsers
                      .map(supplierUser => {
                        return (
                          <TableRow hover>
                            <TableCell>
                              {supplierUser.user.login}
                            </TableCell>
                            <TableCell>
                              {supplierUser.user.firstName ? supplierUser.user.firstName : ''} {supplierUser.user.lastName ? supplierUser.user.lastName : ''}
                            </TableCell>
                            {!supplierClientId &&
                            <TableCell>
                              {supplierUser.supplierClient ? supplierUser.supplierClient.name : ""}
                            </TableCell>
                            }
                            <TableCell align="center" className={classes.adminCell}>
                              {supplierUser.isAdmin == 1 && <Check style={{color: "green", marginLeft: "0px", verticalAlign: "middle"}} />}
                            </TableCell>
                            {!supplierClientId && isAdmin &&
                            <TableCell align="center" className={classes.adminCell}>
                              {supplierUser.isManager == 1 && <Check style={{color: "green", marginLeft: "0px", verticalAlign: "middle"}} />}
                            </TableCell>
                            }
                            <TableCell align="center" className={classes.adminCell}>
                          	    {supplierUser.isBlocked == 1 && <Check style={{color: "red", marginLeft: "0px", verticalAlign: "middle"}} />}
                            </TableCell>
                            <TableCell align="right">
                              <Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">
                                {supplierUser.dateAdd}
                              </Moment>
                            </TableCell>
                            <TableCell align="right" padding={(isAdmin || isClientAdmin) ? "none" : ""}>
                              { (supplierUser.lastActionDate || supplierUser.lastLoginDate) && 
                                <Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">
                                  {supplierUser.lastActionDate ? supplierUser.lastActionDate : supplierUser.lastLoginDate}
                                </Moment>
                              }
                            </TableCell>
	                        <TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          
	                        > 
                            { ((isAdmin || isClientAdmin || isManager) && (
                            	supplierUser && (isSupervisor || (
                        			!(supplierUser.userId == 4484 || supplierUser.userId == 4485 || supplierUser.userId == 22 || supplierUser.userId == 142 || supplierUser.userId == 1787 || supplierUser.userId == 1325 || supplierUser.userId == 4484 || supplierUser.userId == 4485 || supplierUser.userId == 4747)))
                            )) &&
	                            <Button 
	                              onClick={ event => this.handleEditClick(supplierUser) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <Create className={classes.editIcon}/>
	                            </Button>
	                        }
	                        </TableCell>
	                        <TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                        > 
                            { ((isAdmin || isClientAdmin || isManager) && (
                            	supplierUser && (isSupervisor || (
                        			!(supplierUser.userId == 4484 || supplierUser.userId == 4485 || supplierUser.userId == 22 || supplierUser.userId == 142 || supplierUser.userId == 1787 || supplierUser.userId == 1325 || supplierUser.userId == 4484 || supplierUser.userId == 4485 || supplierUser.userId == 4747)))
                            )) &&
	                            <Button 
	                              onClick={ event => this.handleDeleteClick(supplierUser) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <Close className={classes.editIcon}/>
	                            </Button>
	                          }
                            </TableCell>
                            
                            <TableCell 
		                        align="left"
		                        padding="none"
		                        className={classes.rightCell}
		                      > 
	                        	
	                        </TableCell>
	                      </TableRow>
										)
									})}
                  </TableBody>
                </Table>
              </div>
			<div className={classes.tableFooter}>
              <div className={classes.paginationWrapper}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={this.state.usersIds.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  labelRowsPerPage={i18n.t('rows_per_page')}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
                />
              </div>
            </div>              
        	</Paper>
      </div>
    );
  }
}

SupplierUsers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SupplierUsers);
