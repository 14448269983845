import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

class Counter extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: this.props.initQuantity,
      disabled: false          
    };
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  increment(e) {

    if (this.state.value >= this.props.maxQuantity) {
      return this.state.value;
    } else {
      this.setState(
        prevState => ({
          value: Number(prevState.value) + 1,
        }),
        function() {
          this.props.updateQuantity(this.state.value, this.props.isCart ? this.props.id : null); 

        }
      );
    }
    e.preventDefault();
  }

  decrement(e) {
    e.preventDefault();
    if (this.state.value <= 1) {
      return this.state.value;
    } else {
      this.setState(
        prevState => ({
          value: Number(prevState.value) - 1
        }),
        function() {
          this.props.updateQuantity(this.state.value, this.props.isCart ? this.props.id : null);
        }
      );
    }
  }

  feed(e) {
    this.setState({
      value: this.refs.feedQty.value
    }
    );
  }

  resetQuantity() {
    this.setState({
      value: 1
    },
    function() {
        this.props.updateQuantity(this.state.value, this.props.isCart ? this.props.id : null);
    });
  }
  
  changeQuantity() {
	this.setState({
		value: 0
	});
  }
  
  render() {
    return (
      <div className="stepper-input">
        <a href="#" className="decrement" onClick={this.decrement}>
          ↓
        </a>
        <a href="#" className="increment" onClick={this.increment}>
         ↑
        </a>
        <input
          id={"quantity-" + this.props.id}
          ref="feedQty"
          type="number"
          className="quantity"
        //  disabled={true}
          value={this.state.value}
          onChange={event => { {this.setState({value: event.target.value}); this.props.updateQuantity(event.target.value, this.props.isCart ? this.props.id : null);} } }
          onBlur={event => (this.state.value === '0' || this.state.value === '' || this.state.value < 0 || this.state.value > Number(this.props.maxQuantity.replace(/\D/g,''))) && this.resetQuantity()}
        />
      </div>
    );
  }
}

Counter.propTypes = {
  value: PropTypes.number
};

export default Counter;
