// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import Apps from "@material-ui/icons/Apps";
import Assignment from "@material-ui/icons/Assignment";
import People from "@material-ui/icons/People";
import Work from "@material-ui/icons/Work";
import { ReactComponent as PackageVariantClosed } from "img/package-variant-closed.svg";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import SupplierProfile from "views/SupplierProfile/SupplierProfile.jsx";
import SupplierUsers from "views/SupplierUsers/SupplierUsers.jsx";
import Orders from "views/Orders/Orders.jsx";
import Clients from "views/Clients/Clients.jsx";
import Managers from "views/Managers/Managers.jsx";
import SupplierSettings from "views/Settings/SupplierSettings.jsx";
import Catalog from "views/Catalog/Catalog.jsx";

import TableList from "views/TableList/TableList.jsx";
import Typography from "views/Typography/Typography.jsx";
import Icons from "views/Icons/Icons.jsx";
import Maps from "views/Maps/Maps.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.jsx";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: ""
  },
  {
    path: "/pricelist",
    name: "pricelist",
    icon: "content_paste",
    component: TableList,
    layout: ""
  },
  {
    path: "/catalog",
    name: "catalog",
    icon: Apps,
    component: Catalog,
    layout: "",
  },
  {
    path: "/orders",
    name: "orders",
    icon: PackageVariantClosed,
    component: Orders,
    layout: ""
  },
  {
    path: "/clients",
    name: "clients",
    icon: Work,
    component: Clients,
    layout: ""
  },
//  {
//    path: "/managers",
//    name: "managers",
//    icon: HeadsetMic,
//    component: Managers,
//    layout: ""
//  },
  {
    path: "/users",
    name: "users",
    icon: People,
    layout: ""
  },
  {
    path: "/supplier",
    name: "supplier",
    icon: Assignment,
    component: SupplierProfile,
    layout: ""
  },
  {
    path: "/user",
    name: "my_profile",
    icon: Person,
    component: UserProfile,
    layout: ""
  },
  {
    path: "/comparator",
    name: "comparator",
    layout: "",
  },
  {
    path: "/favorites",
    name: "favorites",
    icon: FavoriteBorder,
    component: TableList,
    layout: "",
  },
  {
    path: "/cart",
    name: "cart",
    layout: "",
  },
  {
    path: "/settings",
    name: "settings",
    icon: Settings,
    component: SupplierSettings,
    layout: "",
  },
  // {
  //   path: "/icons",
  //   name: "icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: ""
  // },
  // {
  //   path: "/maps",
  //   name: "maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: ""
  // },
  // {
  //   path: "/notifications",
  //   name: "notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: ""
  // }
];

export default dashboardRoutes;
