import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Check from "@material-ui/icons/Check";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';

import avatar from "assets/img/faces/marc.jpg";

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost } from "variables/common.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class SupplierProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierInfoUpdateSuccess: false,
      countries: null,
      cities: null,
      countryId: null,
      cityId: null,
      supplier: null
    }
  }

  componentDidMount = () => {

    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierId=" + this.props.supplierId;

    var that = this;

    var cb = function(data) {

      that.setState({ supplier: data });

      document.getElementById('emails').value = data.emails;
      document.getElementById('name').value = data.name;
      document.getElementById('addresses').value = data.addresses;
      document.getElementById('phones').value = data.phones;

      that.getCountries();

      if (data.countryId !== undefined) {
        that.setState({ countryId : data.countryId });
        that.getCities(data.countryId);
      }
      if (data.cityId !== undefined) {
        that.setState({ cityId : data.cityId });
      }
    };

    doAjax(url, cb);
  }

  getCountries = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;
    
    var url = constants.apiBaseUrl + '/b2b/countries?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    
    var that = this; 
    var cb = function(data) {  that.setState({ countries: data }) };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose);
  }

  getCities = countryId => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;
  
    var url = constants.apiBaseUrl + '/b2b/cities?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&countryId=" + countryId;

    var that = this; 
    var cb = function(data) { that.setState({ cities: data }) };

    this.props.handleProgressBarOpen();
    return doAjax(url, cb, this.props.handleProgressBarClose);
  }

  handleCountrySelected = event => {
    this.setState({ countryId: event.target.value });
    this.getCities(event.target.value);
  }

  handleCitySelected = (event) => {
    this.setState({ cityId: event.target.value });
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  updateSupplierInfo = () => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;
    
    var url = constants.apiBaseUrl + '/b2b/supplier/update-supplier?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();
    var body = "supplierId=" + this.props.supplierId;
    body += "&emails=" + document.getElementById('emails').value;
    body += "&name=" + document.getElementById('name').value;
    body += "&cityId=" + this.state.cityId;
    body += "&countryId=" + this.state.countryId;
    body += "&addresses=" + document.getElementById('addresses').value;
    body += "&phones=" + encodeURIComponent(document.getElementById('phones').value);

    var that = this;
    var cb = function(data) { 
      if (data === "OK") {
        that.setFieldState("supplierInfoUpdateSuccess");
      }
    };

    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose);

  }

  render() {
    const { classes, i18n, isAdmin } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{isAdmin ? i18n.t('editProfile') : (this.state.supplier != null ? this.state.supplier.name : '')}</h4>
              </CardHeader>
              <CardBody>
              	
              	
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('supplierName')}
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isAdmin,
                        style: {color: "rgb(84, 84, 84)"}
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="country" className={classes.labelRoot}>
                        {i18n.t('country')}
                      </InputLabel>
                      <NativeSelect
                        disabled={!isAdmin}
                        IconComponent={!isAdmin ? "null" : ArrowDropDownIcon}
                        onChange={event => this.handleCountrySelected(event)}
                        value={this.state.countryId}
                        input={ <Input 
                                  id="country"
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: !isAdmin ? classes.brightDisabled : classes.disabled,
                                    underline: classes.underline,
                                  }}
                                />}
                      >
                        { this.state.countries !== null && this.state.countries !== undefined &&
                          this.state.countries.map(country => {
                            return(
                              <option value={country.id}>
                                {country.nameRu}
                              </option>
                            )
                          })
                        }
                      </NativeSelect>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl} fullWidth="true">
                      <InputLabel shrink htmlFor="city" className={classes.labelRoot}>
                        {i18n.t('city')}
                      </InputLabel>
                      <NativeSelect
                        disabled={this.state.countryId === null || this.state.cities === null || this.state.cities.length === 0 || !isAdmin}
                        IconComponent={!isAdmin ? "null" : ArrowDropDownIcon}
                        onChange={event => this.handleCitySelected(event)}
                        value={this.state.cityId}
                        input={ <Input
                                  id="city"
                                  classes={{
                                    root: classes.marginTop,
                                    disabled: !isAdmin ? classes.brightDisabled : classes.disabled,
                                    underline: classes.underline,
                                  }}
                                />}
                      >
                        { this.state.cities !== null && this.state.cities !== undefined &&
                          this.state.cities.map(city => {
                            return(
                              <option value={city.id}>
                                {city.name}
                              </option>
                            )
                          })
                        }
                      </NativeSelect>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('officeAddress')}
                      id="addresses"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        disabled: !isAdmin,
                        style: {color: "rgb(84, 84, 84)"}
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('emails')}
                      id="emails"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        disabled: !isAdmin,
                        style: {color: "rgb(84, 84, 84)"}
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('phones')}
                      id="phones"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 4,
                        disabled: !isAdmin,
                        style: {color: "rgb(84, 84, 84)"}
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              { isAdmin &&
                <CardFooter>
                  <Button 
                    color="primary"
                    onClick={this.updateSupplierInfo}
                  >{i18n.t('save')}</Button>
                  {this.state.supplierInfoUpdateSuccess && <Check style={{color: "green"}} />}
                </CardFooter>
              }
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SupplierProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SupplierProfile);
