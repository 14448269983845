import {
  drawerWidth,
  transition,
  container,
  grayColor,
  defaultFont,
  primaryColor
} from "assets/jss/material-dashboard-react.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    overflow: "auto",
    position: "relative",
    float: "right",
    maxHeight: "100%",
    width: `calc(100% - ${theme.spacing.unit * 6.5}px)`,
    marginLeft: theme.spacing.unit * 6.5,
    WebkitOverflowScrolling: "touch",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  mainPanelShift: {
    overflow: "auto",
    position: "relative",
    float: "right",
    maxHeight: "100%",
    width: "100%",
    WebkitOverflowScrolling: "touch",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "70px"
  },
  footerWrapper: {
    position: "fixed",
    display: "flex",
  },
  progressBar: {
    flexGrow: 1,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    verticalAlign: "top",
    zIndex: "9000"
  },

  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[6] + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    }
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  formControl: {
    paddingBottom: "9px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  brightDisabled: {
    color: "rgb(84, 84, 84)"
  }
});

export default appStyle;
