import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Menu from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

const styles = theme => ({
	...sidebarStyle(theme),
	sidebarWrapper: {
     	width: "260px",
	    height: "calc(100vh - 0px)",
	    zIndex: 4,
	    position: "relative",
	    overflow: "hidden",
    }
    
});

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname === routeName;
  }

  function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.layout + prop.path === props.location.pathname) {
        name = i18n.t(prop.name);
      }
      return null;
    });
    return name;
  }

  const { classes, color, image, logoText, routes, i18n } = props;

  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.path === "/favorites" || prop.path === "/comparator" || prop.path === "/cart") {
          return;
        }
        if (prop.path === "/users" && (!props.isAdmin && !props.isManager && !props.isClientAdmin)) {
          return;
        }
        if (prop.path === "/clients" && !props.isAdmin && !props.isManager) {
          return;
        }
        if (prop.path === "/managers" && !props.isAdmin) {
          return;
        }
        if (prop.path === "/settings" && !props.isSupervisor && !props.isAdmin) {
          return;
        }
        var activePro = " ";
        var listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path)
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
              <ListItemText
                primary={
                  i18n.t(prop.name)
                }
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      
      <NavLink to="#" activeClassName="active" className={classes.item} style={{ bottom: "0px", position: "absolute" }} onClick={props.handleDrawerToggle} aria-label="open drawer">
      <ListItem>
      	
      	<Icon className={classNames(classes.itemIcon)}>
      		question_answer
      	</Icon>
      	<ListItemText style={{ padding: "0px" }} className={classNames(classes.itemText, {[classes.itemTextRTL]: props.rtlActive})} disableTypography={true} id="manager-info">
      		
      	</ListItemText>
      </ListItem>
      </NavLink>
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <a
        className={classNames(classes.logoLink)}
      >
        <div className={classes.logoImage}>
          <img src="https://export208.zoomos.by/tmpl/v2/img/b2b/b2b-logo-ico2.ico" alt="logo" className={classes.img} />
        </div>
        ZOOMOS B2B - {props.supplier === null ? null : props.supplier.name}
      </a>
    </div>
  );
  
  return (
    <div>
        <Drawer
          variant="permanent"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, !props.open && classes.drawerPaperClose)
          }}
          ModalProps={{
            keepMounted: true
          }}
          >

          <div className={classes.sidebarWrapper}>
            <div className={classNames(classes.iconWrapper)}>
              <IconButton 
                onClick={props.handleDrawerToggle} 
                className={classes.chevronLeftIcon}
                color="inherit"
                aria-label="close drawer"
              >
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div>
              {links}
            </div>
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
    
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
