import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from 'react-router-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';

import i18next from 'i18next';

import {  doAjaxPost } from "variables/common.js";

import * as constants from "variables/constants.jsx";

const styles = theme => ({
	mainWrapper: {
		position: "relative",
    	top: "0",
    	height: "100vh"
	},
	main: {
	  width: 'auto',
	  display: 'block', // Fix IE 11 issue.
	  marginLeft: theme.spacing.unit * 3,
	  marginRight: theme.spacing.unit * 3,
	  [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
	            width: 400,
	            marginLeft: 'auto',
	            marginRight: 'auto',
	          },
	 
	},
	paper: {
	  marginTop: theme.spacing.unit * 8,
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center',
	  padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
	  margin: theme.spacing.unit,
	  backgroundColor: theme.palette.secondary.main,
	},
	form: {
	  width: '100%', // Fix IE 11 issue.
	  marginTop: theme.spacing.unit,
	},
	submit: {
	  marginTop: theme.spacing.unit * 3,
	},
	danger: {
      backgroundColor: '#d32f2f',
    },
    closeButton: {
    	color: "white",
    	fontSize: "15px"
    },
 	progressBar: {
    	width: "100%",
    	flexGrow: 1,
    	height: "50px",
    	position: 'absolute',
		bottom: 0
    }
});

class LogInContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      redirect: false,
      showProgressBar: false,
      remember: false
    };
  }

  handleProgressBarOpen = () => {
    return this.setState({ showProgressBar: true });
  };

  handleProgressBarClose = () => {
    this.setState({ showProgressBar: false });
  };

  handleSubmit = (event, classes, isTest) => {
    event.preventDefault();

    var url = constants.apiBaseUrl + '/b2b/supplier/login?key=' + constants.apiKey + '&upd=' + new Date().getTime();
    
    var lgn = (!isTest ? event.target.login.value : "b2b-test-admin");
	var pwd = (!isTest ? event.target.password.value : "KkjJJ2JNl");

    var body = "&login=" + encodeURIComponent(lgn) + "&password=" + encodeURIComponent(pwd); //"supplierId=" + this.state.supplierId + 

//    localStorage.setItem('supplierId', this.state.supplierId);
    
    var that = this;

    var cb = function(data) {
		var supplierUser = { value: data };
		var user = { value: data.user, timestamp: (that.state.remember ? null : new Date().getTime()) };
		localStorage.setItem('user', JSON.stringify(user));
		localStorage.setItem('supplierId', data.supplier.id);
		localStorage.setItem('supplierUser', JSON.stringify(supplierUser));
		if (supplierUser.value.supplierClient) {
			localStorage.setItem('supplierClient', JSON.stringify(supplierUser.value.supplierClient));
		}
		if (supplierUser.value.supplier.apiKey) {
			constants.apiKey = supplierUser.value.supplier.apiKey.key;
			localStorage.setItem('apiKey', supplierUser.value.supplier.apiKey.key);
		}
		if (supplierUser.value.supplier.supplierSettings) {
			localStorage.setItem('supplierSettings', JSON.stringify(supplierUser.value.supplier.supplierSettings));
		}
		that.setState({ redirect: true });
    };

    this.setState({ showProgressBar: true }, () => doAjaxPost(url, body, cb, this.handleProgressBarClose, this.handleShowSnackbar));
  }

  handleShowSnackbar = (message, variant) => {
    this.props.enqueueSnackbar(message, { variant: (variant ? variant : 'info'), autoHideDuration: 3000,
    action: key => (
	        <IconButton onClick={() => this.props.closeSnackbar(key)}>
	            ✖
	        </IconButton>
	), });
  };

  componentWillMount = () => {

  	if (localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null) {
  		this.setState({ redirect: true });
	}

    this.setLanguage('ru');
  }

  setLanguage = language => {
    i18next.init({
      lng: language,
      resources: require(`./i18n/${language}.json`)
    });
    this.forceUpdate()
  }

  rememberToggle = () => {
  	this.setState({ remember: !this.state.remember });
  }

  render() {
	  const { classes } = this.props;

	  if (this.state.redirect) {
	  	return <Redirect to='/dashboard'/>
	  }

	  return (
	  		<div className={classes.mainWrapper} >
		      <main className={classes.main}>
		        <CssBaseline />
		        <Paper className={classes.paper}>
		          <Avatar className={classes.avatar}>
		            <LockOutlinedIcon />
		          </Avatar>
		          <Typography component="h1" variant="h5">
		            { i18next.t('signIn') }
		          </Typography>
		          <form className={classes.form} onSubmit={event => this.handleSubmit(event, classes)} >
		            <FormControl margin="normal" required fullWidth>
		              <InputLabel htmlFor="login">{ i18next.t('login') }</InputLabel>
		              <Input id="login" name="login" autoComplete="login" autoFocus />
		            </FormControl>
		            <FormControl margin="normal" required fullWidth>
		              <InputLabel htmlFor="password">{ i18next.t('password') }</InputLabel>
		              <Input name="password" type="password" id="password" autoComplete="current-password" />
		            </FormControl>
		            <FormControlLabel
		              control={<Checkbox value="remember" color="primary" checked={this.state.remember} onClick={event => this.rememberToggle(event)}/>}
		              label={ i18next.t('rememberMe') }
		            />
		            <Button
		              type="submit"
		              fullWidth
		              variant="contained"
		              color="primary"
		              className={classes.submit}
		            >
		              { i18next.t('signIn') }
		            </Button>
		          </form>
		          <div style={{width: "100%", marginTop: "20px"}}>
		          		{ window.location.href.indexOf('myb2b') >= 0 || window.location.href.indexOf('localhost') >= 0 ? <a target="_blank" href="https://export.zoomos.by/shop-registration" style={{display: "inline-block"}}>{ i18next.t('Registration') }</a> : ""}
		          		{ window.location.href.indexOf('myb2b') >= 0 || window.location.href.indexOf('localhost') >= 0 ? <a href="javascript:;" onClick={event => this.handleSubmit(event, classes, true)} style={{display: "inline-block", float: "right"}}>{ i18next.t('Test') }</a> : "" }
		          		{ window.location.href.indexOf('triovist.') >= 0 ? <a href="https://service21vek.by/opt/" target="_blank" style={{display: "inline-block", float: "right"}}>{ i18next.t('Registration') }</a> : "" }
				  </div>
		        </Paper>
		       </main>

	        <div className={classes.progressBar}>
	            { this.state.showProgressBar ? <LinearProgress query /> : null}
	          </div>
	          
	          <div><img src="https://mc.yandex.ru/watch/55375996" style={{position: "absolute", left: "-9999px"}} alt="" /></div>
	          
	          </div>
	          
		    );
	}
}

LogInContainer.propTypes = {
	classes: PropTypes.object.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
};

const LogInWithSnackbar = withSnackbar(LogInContainer);

function LogIn(props) {
	const { classes, ...rest } = props;
	return (
		<SnackbarProvider 
			maxSnack={3}
			anchorOrigin={{
		        vertical: 'top',
		        horizontal: 'right',
	    	}}
	    	classes={{
        		variantWarning: classes.warning,
        	}}
	    >
			<LogInWithSnackbar classes={classes} { ...rest }/>
		</SnackbarProvider>
	);
}

export default withStyles(styles)(LogIn);
