import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont
} from "assets/jss/material-dashboard-react.jsx";

const cartStyle = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    boxShadow: "none"
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing.unit * 2,
  },
  table: {
    width: "100%",
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkBoxCell: {
    verticalAlign: "middle",
    padding: '0 20px 0px 20px'
  },
  checkBox: {
    height: '28px',
    padding: 0,
  },
  smallCell: {
    width: "1px",
  },
  headerCell: {
    whiteSpace: "nowrap"
  },
  removeButton: {
    width: "30px",
    minWidth: "30px",
    height: "30px",
    margin: "0 20px 0px 30px",
    verticalAlign: "middle",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  summaryWrapper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "12px",
    left: "auto",
    width: "330px",
    height: "100%",
    zIndex: "1000",
    backgroundColor: "#F2F3F4"
  },
  cartTable: {
    width: `calc(100% - 330px)`,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    width: "100%"
  },
  counterCell: {
    paddingLeft: "10px",
    "& > div > input": {
      marginRight: 0
    }
  },
  summaryTable: {
    margin: "100px 20px 0 20px",
    fontSize: "0.95rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  summaryTableHeaderRow: {
    fontSize: "1.1rem",
    fontWeight: 400,
  },
  summaryTableRow: {
   borderBottom: "1px solid black"
  },
  checkoutButton: {
    margin: "20px 20px 0 20px",
    backgroundColor: "#198503",
    color: "white",
    "&:hover": {
      backgroundColor: "#27621A",
    }
  },
  disabled: {
    opacity: 0.3,
    cursor: "not-allowed"
  },
  lightbox: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    textAlign: "center"
  },
  returnButton: {
    backgroundColor: grayColor[10],
    "&:hover": {
      backgroundColor: grayColor[11],
    },
    fontSize: "0.8rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    marginTop: "15px"
  },
  checkoutSuccessLabel: {
    fontSize: "0.95rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  modelHeader:{
	  paddingLeft:"10px",
	  whiteSpace: "nowrap",
  },
  cartQuantityHeader:{
	  paddingRight:"11px",
	  whiteSpace: "nowrap"
  }
});

export default cartStyle;