import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont
} from "assets/jss/material-dashboard-react.jsx";

const comparatorStyle = theme => ({
  root: {
    display: "inline-block",
    marginTop: theme.spacing.unit * 3,
    boxShadow: "none",
    overflow: "auto",
    boxShadow: "0 0 3px rgba(0,0,0,0.3)",
  },
  notLastColumn: {
    borderRight: "1px solid #D3D3D3",
  },
  notLastRow: {
    borderBottom: "1px dotted #D3D3D3", 
  },
  cell: {
    padding: "2px 10px 2px 10px"
  },
  priceCell: {
    fontSize: "18px",
    paddingBottom: "20px"
  },
  featureNameCell: {
    fontWeight: "400",
    fontSize: "13px",
    minWidth: "280px"
  },
  modelCell: {
    fontWeight: "450",
    fontSize: "17px",
    width: "250px",
    minWidth: "250px",
    verticalAlign: "top"
  },
  imageCell: {
    width: "250px",
    maxWidth: "250px",
    padding: "2px 20px 2px 20px"
  },
  compareTable: {
    borderCollapse: "collapse",
    fontWeight: "350",
    fontSize: "13px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  removeButton: {
    height: "20px",
    minWidth: "20px",
    right: 0,
    verticalAlign: "middle",
    padding: 0,
    "&:hover, ": {
      backgroundColor: "transparent",
    }
  },
  commonFeaturesToggleWrapper: {
    display: "inline-block",
    marginTop: theme.spacing.unit * 1.5,
    boxShadow: "0 0 3px rgba(0,0,0,0.3)",
    padding: "5px 10px 5px 10px"
  },
  backButton: {
    display: "block",
  },
  compareTableWrapper: {
    display: "block",
  },
  buyButton: {
    fontSize: "12px",
    color: "white",
    fontWeight: 700,
    backgroundColor: "#198503",
    "&:hover": {
      backgroundColor: "#27621A",
      color: "white",
    },
    padding: "2px 22px",
  },
  cartCell: {
    height: "60px",
    textAlign: "center"
  }
});

export default comparatorStyle;