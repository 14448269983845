import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";

import { NavLink } from "react-router-dom";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Language from "@material-ui/icons/Language";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from '@material-ui/core/SvgIcon';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import CartScrollBar from "components/Cart/CartScrollBar";
import Counter from "components/Cart/Counter";
import EmptyCart from "components/Cart/EmptyCart";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { doAjax, doAjaxPost, addParameter, removeParameter, getParams, checkboxToggle } from "variables/common.js";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { Redirect } from 'react-router-dom'

import hist from "../../history";

const styles = theme => ({
	...headerLinksStyle(theme),
	clientWrapper: {
		paddingLeft: "15px",
		top: "2px",
	    maxWidth: "300px",
	    height: "51px",
	    display: "inline-block",
	    zIndex: "4",
	    textAlign: "left",
	    marginLeft: "5px",
	    marginRight: "5px",
	    verticalAlign: "middle"
	},
	amountWrapper: {
		paddingLeft: "0px",
		top: "2px",
	    maxWidth: "100px",
	    height: "51px",
	    display: "inline-block",
	    zIndex: "4",
	    textAlign: "left",
	    marginLeft: "5px",
	    marginRight: "5px",
	    verticalAlign: "middle"
	}
})

class HeaderLinks extends React.Component {
  state = {
    openLang: false,
    showCart: false,
    redirectToLogin: false,
    model: (localStorage.getItem('modelFromNavbar') !== null && localStorage.getItem('modelFromNavbar') !== "%" ? localStorage.getItem('modelFromNavbar') : '') ,
  };

  onClickLang = (event, lang) => {
      this.props.setLanguage(lang);
      this.handleLangClose(event);
   }

  handleToggle = () => {
    if(this.state.open === false) {
      this.setState({ showCart: false });
      this.setState({ openLang: false });
    }
    this.setState(state => ({ open: !state.open }));
  };

  handleLangToggle = () => {
    if(this.state.openLang === false) {
      this.setState({ showCart: false });
      this.setState({ open: false });
    }
    this.setState(state => ({ openLang: !state.openLang }));
  };

  handleLangClose = event => {
    if (this.anchorElLang.contains(event.target)) {
      return;
    }

    this.setState({ openLang: false });
  };

  handleCartClose = event => {
    this.setState({ showCart: false });
  };

  handleCartToggle = event => {
    this.setState(state => ({ showCart: !state.showCart }));
  };

  handleSingOut = event => {
    localStorage.removeItem('user');
    this.setState({ redirectToLogin: true });
  };

  handleCheckoutOpen = () => {
    this.setState({ showCart: false });
    this.props.handleCheckoutOpen();
  }

  hanleRedirectToPriceList = event => {
    if(event.type === "click" || event.key === "Enter") {
      if (document.getElementById("searchNav").value.length > 0) {
      	if (document.getElementById("searchNav").value == localStorage.getItem('modelFromNavbar')) {
        this.props.setModelFromNavbar(document.getElementById("searchNav").value);
        localStorage.setItem('modelFromNavbar', '%%');	
        } else {
	        this.props.setModelFromNavbar(document.getElementById("searchNav").value);
        localStorage.setItem('modelFromNavbar', document.getElementById("searchNav").value);
        }
      } else {
        this.props.setModelFromNavbar('');
        localStorage.setItem('modelFromNavbar', '');
      }
      this.setState({model : document.getElementById("searchNav").value})
      hist.push('/pricelist');
    }
  }

  componentWillMount() {
	var params = getParams(window.location.href);
	if (params && params.model !== undefined) {
//		document.getElementById("searchNav").value = params.model; // null
        this.props.setModelFromNavbar(params.model);
        localStorage.setItem('modelFromNavbar', params.model);
        this.setState({model: params.model})
    } else{
        this.props.setModelFromNavbar('');
        localStorage.setItem('modelFromNavbar', '');
        this.setState({model: ''})
    } 
  }
   

  componentDidMount = () => {
   
      var amountWrapper = document.getElementById('amount-wrapper');
	  if (amountWrapper) {
	  	  var showPrices = (localStorage.getItem('showPrices') == null ? true : localStorage.getItem('showPrices') == 'true');
		  if (showPrices) {
			  amountWrapper.style.visibility = 'visible';
		  } else {
			  amountWrapper.style.visibility = 'hidden';
		  }
	  }
	  var params = getParams(window.location.href);
	  if (params && params.model && params.model != '%') {
	  	document.getElementById("searchNav").value = params.model;
  this.props.setModelFromNavbar(params.model);
	  }
  }

  render() {
    const { classes, cart, totalItems, totalAmount, i18n, totalItemsToCompare, totalFavorites } = this.props;
    const { open, showCart, redirectToLogin, openLang, redirectToPricelist } = this.state;

    if (redirectToLogin) {
      //localStorage.clear();
      
      localStorage.removeItem('supplierId');
      localStorage.removeItem('user');
      localStorage.removeItem('supplierUser');
      localStorage.removeItem('supplierClient');
      localStorage.removeItem('modelFromNavbar');
      return <Redirect to='/login' />
    }

    let cartItems = [];
    if (cart) {
    cartItems = cart.map(product => {
      return (
        <li className="cart-item" key={product.model}>
          <div style={{height: "40x", width: "40px", textAlign: "center"}}>
          	{product.targetPortalItemId ?
            <img style={{maxHeight: "40px", maxWidth: "40px", verticalAlign: "middle", margin: "auto"}} src={"https://api.zoomos.by/img/item/" + product.targetPortalItemId + "/main/resize/40x40"} />
            : <div style={{width: "40px"}}/>
            }
          </div>
          <div className="product-info">
            <p className="product-producer">{product.producer}</p>
            <p className="product-name">{product.model}</p>
            <p className="product-price">{product.currencyEnum === 'BYN' ? product.price + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: product.currencyEnum }).format(product.price)}</p>
          </div>
          <div className="product-total">
            <p className="quantity">
              {product.cartQuantity} {product.cartQuantity > 1 ? i18n.t('nos') : i18n.t('no')}{" "}
            </p>
            <p className="amount">{product.currencyEnum === 'BYN' ? Math.round(product.cartQuantity * product.price * 100) / 100 + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: product.currencyEnum }).format(product.cartQuantity * product.price)}</p>
          </div>
          <a
            className="product-remove"
            href="#"
            onClick={this.props.removeProduct.bind(this, product.id)}
          >
            ×
          </a>
        </li>
      );
    });
    }
    
    let view;
    if (cartItems.length <= 0) {
      view = null;
    } else {
      view = (
        <CSSTransitionGroup
          transitionName="fadeIn"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
          component="ul"
          className="cart-items"
        >
          {cartItems}
        </CSSTransitionGroup>
      );
    }

    return (
      <div>
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search
            }}
            id="searchNav"
            inputProps={{
              placeholder: i18n.t('search'),
              inputProps: {
                "aria-label": i18n.t('search')
              },
              defaultValue: this.state.model !== "%" ? this.state.model : "",
              onKeyPress: event => this.hanleRedirectToPriceList(event)
            }}
          />
          <Button onClick={event => this.hanleRedirectToPriceList(event)} color="white" aria-label="edit" justIcon round>
            <Search/>
          </Button>
        </div>



		{/*
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorElLang = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openLang ? "grow-lang" : null}
            aria-haspopup="true"
            onClick={this.handleLangToggle}
            className={classes.buttonLink}
          >
            <Language className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClickLang} className={classes.linkText}>
                Language
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={openLang}
            anchorEl={this.anchorElLang}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openLang }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="grow-lang"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleLangClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={ event => this.onClickLang(event, 'en') }
                        className={classes.dropdownItem}
                      >
                        English
                      </MenuItem>
                      <MenuItem
                        onClick={ event => this.onClickLang(event, 'ru') }
                        className={classes.dropdownItem}
                      >
                        Русский
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        */}

		<a
          href={'https://help.zoomos.by/b2b/api/client/doc?from=b2b&_=' + new Date().getTime()}
          target="_blank"
          style={{color: "inherit"}}
        >
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="API"
            className={classes.buttonLink}
            style={{fontSize: "15px"}}
          >
            API
          </Button>
        </a>
		
		{/*
        <NavLink
          to={'/dashboard'}
          style={{color: "inherit"}}
        >
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Dashboard"
            className={classes.buttonLink}
          >
            <Dashboard className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Dashboard</p>
            </Hidden>
          </Button>
        </NavLink>
        */}        
        
        {/*
        <NavLink
          to={'/user'}
          style={{color: "inherit"}}
        >
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Person"
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
        </NavLink>
        */}

        <NavLink
          to={'/comparator'}
          style={{color: "inherit"}}
        >
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Compare"
            className={classes.buttonLink}
            disabled={totalItemsToCompare === 0}
          >
            <SvgIcon className={classes.icons}>
               <path d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
            </SvgIcon>
            { totalItemsToCompare > 0 ? <span className={classes.notifications}>{ totalItemsToCompare }</span> : ''}
          </Button>
        </NavLink>

        <NavLink
          to={'/favorites'}
          style={{color: "inherit"}}
        >
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Compare"
            className={classes.buttonLink}
            onClick={this.props.handleFavoritesOpen}
            disabled={totalFavorites === 0}
          >
            <FavoriteBorder className={classes.icons}/>
            { totalFavorites > 0 ? <span className={classes.notifications}>{totalFavorites}</span> : ''}
          </Button>
        </NavLink>

        <ClickAwayListener onClickAway={this.handleCartClose}>
        <div className="cart">
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="ShoppingCart"
            className={classes.buttonLink}
            onClick={this.handleCartToggle}
            disabled={cart && cart.length === 0}
          >
            <ShoppingCart className={classes.icons}/>
            { totalItems > 0 ? <span className={classes.notifications}>{totalItems}</span> : ''}
          </Button>
            {cart && cart.length > 0 &&
              <div
                className={
                  showCart ? "cart-preview active" : "cart-preview"
                }
                ref="cartPreview"
              >
                <CartScrollBar>{view}</CartScrollBar>
                <div style={{width: "100%", textAlign: "center"}}>
                  <NavLink 
                    to={'/cart'}
                    style={{color: "inherit"}}
                  >
                    <Button
                        disabled={cart && cart.length === 0}
                        className={classes.checkoutButton}
                        onClick={this.handleCheckoutOpen}
                    >
                       { i18n.t('checkout') }
                    </Button>
                  </NavLink>
                </div>
              </div>
            }
          </div>
          </ClickAwayListener>

          <div className={classes.amountWrapper} id="amount-wrapper">
            <span className={classes.amount}>{ cart && cart.length > 0 && (cart[0].currencyEnum === 'BYN' ? totalAmount + ' ' + i18n.t('BYN') : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: cart[0].currencyEnum }).format(totalAmount))}</span>
          </div>
          
          <div className={classes.clientWrapper}>
          	<span className={classes.amount}>
          		{localStorage.getItem('supplierUser') ? JSON.parse(localStorage.getItem('supplierUser')).value.user.firstName : ''}
          		{localStorage.getItem('supplierClient') ? ' (' + JSON.parse(localStorage.getItem('supplierClient')).name + ')' : ''}
          	</span>
          </div>

          <IconButton
            justicon={(window.innerWidth > 959).toString()}
            simple={(!(window.innerWidth > 959)).toString()}
            aria-label="Exit"
            className={classes.exitIcon}
            onClick={this.handleSingOut}
          >
            <ExitToApp/>
          </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(HeaderLinks);
