import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Add from '@material-ui/icons/Add';
import Create from '@material-ui/icons/Create';
import Close from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import CustomButton from "components/CustomButtons/Button.jsx";
import Check from "@material-ui/icons/Check";

import TablePagination from '@material-ui/core/TablePagination';

import Cancel from "@material-ui/icons/Cancel";
import GetApp from '@material-ui/icons/GetApp';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import avatar from "assets/img/faces/marc.jpg";

import Moment from 'react-moment';
import CustomInput from "components/CustomInput/CustomInput.jsx";

import * as constants from "variables/constants.jsx";
import { doAjax, doAjaxPost } from "variables/common.js";

const styles = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  lightbox: {
    position: 'absolute',
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  cancelIconWrapper: {
    right: "10px",
    top: "10px",
    position: "absolute",
  },
  cancelIcon: {
    width: "15px",
    height: "15px",
    fontSize: "15px"
  },
  modalTable: {
    marginTop: "40px"
  },
  idButton: {
    backgroundColor: "transparent",
    padding: 0,
    minWidth: 0,
    font: "600 13.3333px Arial",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline"
      
    }
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    "& > table > tbody": {
      overflow: "auto",
      maxHeight: "60vh"
    },
    maxHeight: "73vh",
    overflow: "auto",
  },
  textInputWrapper: {
    margin: 0,
    paddingBottom: 3,
    width: "80%",
    
  },
  textInput: {
  	fontSize: "13px"
  },
  editLightbox: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  cancelIconWrapper: {
    right: "10px",
    top: "10px",
    position: "absolute",
  },
  cancelIcon: {
    width: "15px",
    height: "15px",
    fontSize: "15px"
  },  
  editCell: {
    minWidth: "30px",
    width: "30px",
    paddingLeft: "10px",
    paddingRight: "5px",
    whiteSpace: "nowrap",
    textAlign: "center"
  },
  editIcon: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "21px",
    color: "#848484",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },    
  editButton: {
    margin: 0,
    verticalAlign: "middle",
    padding: 0,
    height: "21px",
    minWidth: "1px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  rightCell: {
    minWidth: "10px",
    width: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",
    whiteSpace: "nowrap"  
  },

});

function getModalStyle() {
  const vertical = 5;
  const horizontal = 25;

  return {
    top: `${vertical}%`,
    left: `${horizontal}%`,
//    bottom: `${vertical}%`,
    overflow: "auto",
    maxHeight: "600px",
    minHeight: "inherit"
  };
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, sortDirection, sortBy, numSelected, rowCount, i18n, classes, isAdmin } = this.props;

    const rows = [
      { id: 'id', numeric: false, disablePadding: false, label: i18n.t('ID'), sortable: false },
      { id: 'name', numeric: false, disablePadding: false, label: i18n.t('name'), sortable: true },
      { id: 'clientsCount', numeric: true, disablePadding: false, label: i18n.t('clientsCount'), sortable: false },
      { id: 'dateAdd', numeric: true, disablePadding: true, label: i18n.t('dateAdd'), sortable: true },
    ];

    return (
      <TableHead>
        <TableRow >
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={sortBy === row.id || (row.field && sortBy === row.field) ? sortDirection : false}
                className={classes.headerCell}
              >
                {row.label !== '' ?
                [
                	<div>
                	{row.sortable !== false ?
	                <Tooltip
	                  title={i18n.t('sort')}
	                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
	                  enterDelay={300}
	                >
                    <TableSortLabel
                      active={sortBy === row.id || (row.field && sortBy === row.field)}
                      direction={sortDirection}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                    </Tooltip>
                    : <div>{row.label}</div>
                    }
                    </div>
                
                ]
                : null}
              </TableCell>
            ),
            this,
          )}
          {this.props.isAdmin && 
          <>
          <TableCell 
            className={classes.editCell}
            key='edit'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          <TableCell 
            className={classes.editCell}
            key='delete'
            align='left'
            padding='none'>
            {null}
          </TableCell>
          </>
          }
          <TableCell 
	          className={classes.rightCell}
	          padding='none'>
	          {null}
	        </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  manager: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "table",
    width: "400px",
    textAlign: "right"
  },
});

let EnhancedTableToolbar = props => {
  const { i18n, numSelected, classes, isAdmin } = props;

  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {i18n.t('managers')}
        </Typography>
      </div>

          <div className={classes.spacer} />
      	  <div className={classes.actions}>
      	  	{ isAdmin && 
              <Tooltip title={ i18n.t('add') }>
                <IconButton style={{display: "inline-block"}} aria-label="Add" onClick={event => props.addClick(null)}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
      	  </div>
      
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function getEditModalStyle() {
  const vertical = 5;
  const horizontal = 20;

  return {
    top: `${vertical}%`,
//    bottom: `${vertical}%`,
    left: `${horizontal}%`,
    flexGrow: 1,
    maxHeight: "600px",
    minHeight: "inherit"
  };
}

class EditLightbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saveSuccess: false,
      emptyLogin: false,
      emptyPassword: false,
      emptyEmail: false,
      cardsInputOpen: false,
      managers: [],
      supplierId: localStorage.getItem('supplierId')
    }
  };

  save = () => {

    var name = document.getElementById('edit-lb-name').value;

    if (name === "") {
      if (name === "") {
        this.setFieldState("emptyName");
      }
      return;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;

    const { item } = this.props;
    var url = constants.apiBaseUrl + '/b2b/supplier/update-manager?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = (item !== null ? "&supplierManagerId=" + item.id : "") + "&supplierId=" + this.state.supplierId + "&principalId=" + user.id + "&name=" + document.getElementById('edit-lb-name').value + 
    			"&externalId=" + document.getElementById('edit-lb-externalId').value + "&phone=" + document.getElementById('edit-lb-phone').value + "&email=" + 
    			document.getElementById('edit-lb-email').value;

    var that = this;
    var cb = function(data) {
      if (data) {
        that.setFieldState("saveSuccess");
        that.props.updateTableData();
        that.handleClose();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  setFieldState = stateName => {
   this.setState({ [stateName]: true });
    setTimeout(() => {
      this.setState({
        [stateName]: false
      });
    }, 2000);
  }

  handleManagerSelected = event => {
    console.log(event.target.value )
  }

  componentDidMount = () => {

	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/manager-ids?key=' + constants.apiKey + '&supplierId=' + this.state.supplierId + '&sortBy=name' + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    var that = this;

    var cb = function(data) { 
        var url = constants.apiBaseUrl + '/b2b/supplier/managers?key=' + constants.apiKey + '&supplierId=' + that.state.supplierId + "&principalId=" + user.id + '&upd=' + new Date().getTime();
        
        for (var k in data) {
        	url += '&ids=' + data[k];
        }

	    var that2 = that;
	
	    var cb = function(data) { 
	      that2.setState({ managers: data });
	    };
	
	    that.props.handleProgressBarOpen();
	    doAjax(url, cb, that.props.handleProgressBarClose);
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose);
  }

  handleClose = event => {
    this.setState({ cardsInputOpen: false });
    this.setState({ card: null }); 
    this.props.handleClose();
  }

  removeCard = () => {
    this.setState({ cardsInputOpen: false }); 
    this.props.removeCard();
  }

  setCard = id => {
    this.setState({ cardsInputOpen: false }); 
    this.props.setCard(id);
  }

  render() {
    const { i18n, classes, item, open, card } = this.props;
    const modalStyle = getEditModalStyle();

    return (
      <div>
        <Modal
          open={open}
          onClose={this.props.handleClose}
        >
          <div style={modalStyle} className={classes.editLightbox}>
            <Typography variant="h6" id="tableTitle">
              { item === null ? i18n.t('adding') : i18n.t('edition')}
            </Typography>
            <IconButton 
              onClick={event => this.handleClose(event)}
              className={classes.cancelIconWrapper}
              small
            >
              <Cancel
                className={classes.cancelIcon}
              />
            </IconButton>
            <div style={{margin: "auto"}}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('name') + '*'}
                      id="edit-lb-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.name : ""
                      }}
					  error={this.state.emptyName}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('ID') + ''}
                      id="edit-lb-externalId"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.externalId : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>  
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('phone')}
                      id="edit-lb-phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.phone : ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={i18n.t('email')}
                      id="edit-lb-email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: item !== null ? item.email : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
                <GridContainer style={{  }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{marginTop: "25px"}}>
                      <CustomButton 
                        color="primary"
                        onClick={this.save}
                      >
                        {item === null ? i18n.t('add') : i18n.t('save')}
                      </CustomButton>
                      {this.state.saveSuccess && <Check style={{color: "green", marginLeft: "25px", verticalAlign: "middle"}} />}
                    </div>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}


class Managers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      managers: [],
      managerItems: [],
      managerOpen: false,
      manager: null,
      managerIds: [],
      page: 0,
      rowsPerPage: 50,
      sortDirection: 'desc',
      sortBy: 'dateAdd',
      edit: '',
      editLBOpen: false,
      editLBItem: null,
      card: null,
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page : page }, () => this.getManagers());
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () => this.getManagers());
  };

  handleRequestSort = (event, property) => {
  
    var s = property;
    
    if (s && s == 'status') {
    	s = 'externalStatusText';
    }
  
    const isDesc = this.state.sortBy === s && this.state.sortDirection === 'desc';
    this.setState({ sortBy: s, sortDirection: (isDesc ? 'asc' : 'desc') }, () => this.getManagerIds());
  }

  componentDidMount = () => {
    this.getManagerIds();
  }

  getManagerIds = () => {
    const { user, supplierId, isAdmin, edit } = this.props;

    var supplierManager = null;
    if (localStorage.getItem('supplierManager')) {
	    supplierManager = JSON.parse(localStorage.getItem('supplierManager'));
    }

    var url = constants.apiBaseUrl + '/b2b/supplier/manager-ids?key=' + constants.apiKey + '&upd=' + new Date().getTime() + "&supplierId=" + supplierId 
    				//+ (!isAdmin ? "&userId=" + user.id : "")
    				+ (!isAdmin && supplierManager ? "&supplierManagerId=" + supplierManager.id : "")
    				+ "&sortBy=" + this.state.sortBy + "&principalId=" + user.id + "&sort=" + this.state.sortDirection;

    var that = this;

    var cb = function(data) { 
      that.setState({ managerIds: data }, () => that.getManagers());
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
    
    if (document.getElementById("download-managers")) {
      	document.getElementById("download-managers").href=(url.replace('/manager-ids', '/' + JSON.parse(localStorage.getItem('supplierUser')).value.supplier.name + '/managers/xls'));
      	document.getElementById("download-managers").target = "_blank";
    }
  }

  getManagers = () => {
    const { managerIds, page, rowsPerPage } = this.state;
    
    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/managers?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    console.log(this.state.managerIds)
    managerIds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(id => {
      url += "&ids=" + id;
    })

    var that = this;

    var cb = function(data) { 
      that.setState({ managers: data });
      that.setState({edit: ""});
    };

    this.props.handleProgressBarOpen();
    if (managerIds.length > 0) {
    	doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar, this.props.handleShowSnackbar);
    } else {
    	that.setState({ managers: [] });
    }
    
  }

  handleManagerClick = (event, manager) => {
  
    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/manager?key=' + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime() + "&supplierManagerId=" + manager.id;

    var that = this;

    var cb = function(data) { 
      that.setState({ 
        managerItems: data,
        manager: manager,
        managerOpen: true
      });
    };

    this.props.handleProgressBarOpen();
    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  handleManagerClose = () => {
    this.setState({ managerOpen: false });
  }
  

  handleEditClick = item => {
    this.setState({
      editLBOpen: true,
      editLBItem: item
    }, () => this.getCard(item)); 
  }

  handleDeleteClick = item => {
  
    const conf = window.confirm('Удалить менеджера?'); //{i18n.t('deleteUser?')}
    if (!conf) {
    	return;
    }
    
	var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + '/b2b/supplier/delete-manager?key=' + constants.apiKey + '&upd=' + new Date().getTime();

    var body = "&supplierId=" + this.state.supplierId + "&principalId=" + user.id + "&supplierManagerId=" + item.id;

    var that = this;
    var cb = function(data) {
      if (data) {
        that.componentDidMount();
      }
    }
    this.props.handleProgressBarOpen();
    doAjaxPost(url, body, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);    
    
  }
  handleEditLBClose = (event) => {
    this.setState({
      editLBOpen: false,
      editLBItem: null,
      card: null
    });
  }
  
  getCard = item => {
    if (this.state.editLBItem === null || this.state.editLBItem.id === undefined) {
      return;
    }
    
    var user = JSON.parse(localStorage.getItem('user')).value;

    var url = constants.apiBaseUrl + "/b2b/supplier/manager?supplierManagerId=" + item.id + "&key=" + constants.apiKey + "&principalId=" + user.id + '&upd=' + new Date().getTime();

    var that = this;
    var cb = function(data) {
      that.setState({
        card: data
      });
    };

    doAjax(url, cb, this.props.handleProgressBarClose, this.props.handleShowSnackbar);
  }

  render() {
    const { classes, i18n, isAdmin } = this.props;
    const { rowsPerPage, page, managers, sortDirection, sortBy, managerIds } = this.state;
    const modalStyle = getModalStyle();

    if (!isAdmin) {
      return <Redirect to='/pricelist'/>
    }

    return (
      <div>
      
        <EditLightbox 
          open={this.state.editLBOpen} 
          i18n={i18n} 
          classes={classes} 
          item={this.state.editLBItem} 
          handleClose={this.handleEditLBClose} 
          updateTableData={this.componentDidMount}
          handleProgressBarOpen={this.props.handleProgressBarOpen} 
          handleProgressBarClose={this.props.handleProgressBarClose}
          removeCard={this.removeCard}
          setCard={this.setCard}
          card={this.state.card}
        />
        
        <Paper className={classes.root}>
            <EnhancedTableToolbar i18n={i18n} isAdmin={isAdmin} addClick={this.handleEditClick} />
            <div className={classes.tableWrapper}>
              <Table className={classNames(classes.table, "fixed-header-table")} size={'small'}>
                <EnhancedTableHead
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  onRequestSort={this.handleRequestSort}
                  i18n={i18n}
                  classes={classes}
                  isAdmin={isAdmin}
                />
                <TableBody>
                  {managers
                    .map(manager => {
                      return (
                        <TableRow hover>
                          <TableCell>
                            <Button disableRipple className={classes.idButton} onClick={event => this.handleManagerClick(event, manager)}>{manager.externalId ? manager.externalId : manager.id}</Button>
                          </TableCell>
                          <TableCell>
                            {manager.name}
                          </TableCell>
                          <TableCell align="right">
                          	{manager.clientsCount}
                          </TableCell>
                          <TableCell align="right" padding="none">
                            <Moment parse="DD.MM.YYYY HH:mm:ss" format="DD.MM.YYYY HH:mm">
                              {manager.dateAdd}
                            </Moment>
                          </TableCell>
                          
                          { isAdmin &&
                          	<>
	                        <TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                        > 
	                            <Button 
	                              onClick={ event => this.handleEditClick(manager) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <Create className={classes.editIcon}/>
	                            </Button>
	                          
	                        </TableCell>
	                        
	                        <TableCell 
	                          align="left"
	                          padding="none"
	                          className={classes.editCell}
	                          style={{paddingLeft: "0px"}}
	                        > 
	                            <Button 
	                              onClick={ event => this.handleDeleteClick(manager) }
	                              color="inherit"
	                              disableRipple
	                              className={classes.editButton}
	                            >
	                              <Close className={classes.editIcon}/>
	                            </Button>
	                          
	                        </TableCell>
	                        </>
	                      }
	                        
	                        <TableCell 
		                        align="left"
		                        padding="none"
		                        className={classes.rightCell}
		                      >
		                    </TableCell>
                        </TableRow>
                    )})}
                </TableBody>
              </Table>
            </div>
            <div className={classes.tableFooter}>
              <div className={classes.paginationWrapper}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={managerIds.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  labelRowsPerPage={i18n.t('rows_per_page')}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
                />
              </div>
            </div>
        </Paper>
      </div>
    );
  }
}

Managers.propTypes = {
  classes: PropTypes.object.isRequired,
  edit: PropTypes.string.isRequired,
  handleEditOpen: PropTypes.func.isRequired,
  handleEditClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Managers);
